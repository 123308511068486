export const baseUrl = process.env.REACT_APP_API_ENDPOINT;

const apiUrls = {
  base: baseUrl,
  auth: {
    signIn: `${baseUrl}/auth/login`,
    register: `${baseUrl}/api/auth/local/register`,
    sendEmailConfirmation: `${baseUrl}/api/auth/send-email-confirmation`,
    forgetPassword: `${baseUrl}/api/auth/forgot-password`,
    resetPassword: `${baseUrl}/api/auth/reset-password`,
  },
  groups: {
    get: `${baseUrl}/api/groups`,
  },
  contacts: {
    get: `${baseUrl}/contacts`,
    create: `${baseUrl}/contacts`,
    update: `${baseUrl}/contacts`,
    search: `${baseUrl}/contactsearch`,
    import: `https://xbd3-yxc7-ibfu.n7c.xano.io/api:U7u6l_oJ/importcontact`,
  },
  generateMessage: `${baseUrl}/generatecustommessage`,
  // generateMessage: `${baseUrl}/generatemessage`,
  tasks: {
    get: `${baseUrl}/tasks`,
    create: `${baseUrl}/tasks`,
    update: `${baseUrl}/tasks`,
  },
  taskqueue: {
    getTaskQueues: `${baseUrl}/taskqueue`,
    update: `${baseUrl}/taskqueue`,
  },
  galaxies: {
    create: `${baseUrl}/galaxies`,
    update: `${baseUrl}/galaxies`,
    getMy: `${baseUrl}/mygalaxies`,
  },
  //https://xbd3-yxc7-ibfu.n7c.xano.io/api:PfsuqTWw/messagetemplates
  messageTemplates: {
    get: `${baseUrl}/messagetemplates`,
    create: `${baseUrl}/messagetemplates`,
    update: `${baseUrl}/messagetemplates`,
  },
  projects: {
    get: `${baseUrl}/projects`,
    update: `${baseUrl}/myproject`,
  },
  config: {
    findOneWithSpecificName: `${baseUrl}/api/config/findOneWithSpecificName`,
  },
  myUser: {
    get: `${baseUrl}/api/users/me`,
    getMyCurrentActiveSubscription: `${baseUrl}/api/subscriptions/getMyCurrentActiveSubscription`,
    updateMe: `${baseUrl}/api/users-permissions/updateMe`,
  },
};

export default apiUrls;
