import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { RootState } from "../state/store";
import { jwtSelector } from "../state/selectors/authSelector";
import { useEffect, useState } from "react";
import { isTokenExpired } from "../util/auth";

export const PrivatePage = ({ children }: { children: React.ReactNode }) => {
  const [isExpired, setIsTokenExpired] = useState(false);
  const { authToken } = useSelector((state: RootState) => {
    return {
      authToken: jwtSelector(state),
    };
  });

  const location = useLocation();

  const [temp, setTemp] = useState(true);

  // useEffect(() => {
  //   if (
  //     authToken
  //     //&& isTokenExpired(authToken))
  //   ) {
  //     setIsTokenExpired(true);
  //   }

  //   setTemp(false);
  // }, [authToken]);

  useEffect(() => {
    if (temp === true) {
      setTemp(false);
    }
  }, [temp]);

  return authToken === "" ? (
    //|| isExpired === true
    <Navigate to="/login" />
  ) : location.pathname === "/" ? (
    <>{children}</>
  ) : (
    <>
      {children}
      {temp ? <Navigate to={`${location.pathname}`} /> : <></>}
    </>
  );
  // return authToken=== "" || isExpired === true ? <Navigate to="/login" /> : <>{children}</>;
};
