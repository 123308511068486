import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import avatarPlaceholder from "../../../assets/avatarPlaceholder.png";
import { AppDispatch } from "../../../common/state/store";
import { useDispatch } from "react-redux";
import { updateTaskQueues } from "../../../features/contacts/state/contactsSlice";
interface Node {
  id: string;
  name: string;
  relevance: number;
}

interface Link {
  source: Node;
  target: Node;
}

interface ForceGraphProps {
  data: { nodes: Node[]; links: Link[] };
  onNodeClick: (contactId: number) => void;
  selectedGalaxyId: number;
}

const ForceGraph: React.FC<ForceGraphProps> = ({
  data,
  onNodeClick,
  selectedGalaxyId,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const svgRef = useRef(null);

  const minNodeSize = 17; // Minimum node size
  const maxNodeSize = 30; // Maximum node size
  const minTextSize = 6;
  const maxTextSize = 8;

  // You may adjust the base length and scaling to better suit the visual appearance
  const baseLinkLength = 50;
  const linkLengthScalingFactor = 3;
  const startRadius = 20;

  // Increase spacing for central nodes
  const spacingMultiplier = 2; // Adjust this multiplier to increase the distance

  // Define the initial zoom behavior
  const initialZoom = 0.5; // Set this to a value less than 1 to start zoomed out

  // Updated getNodeBorderColor function
  const getNodeBorderColor = (duedate: any) => {
    if (!duedate) return "grey"; // No due date
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset time to midnight for comparison
    const dueDate = new Date(duedate);
    if (dueDate < today) return "red"; // Overdue
    if (dueDate.toDateString() === today.toDateString()) return "green"; // Due today
    if (
      dueDate <
      new Date(today.getFullYear(), today.getMonth(), today.getDate() + 14)
    )
      return "blue"; // Upcoming weeks
    return "darkviolet"; // Upcoming month
  };

  // Pre-compute node sizes
  function computeNodeSizes() {
    groups.forEach((group) => {
      let groupNodes = group.otherNodes; // Array of nodes

      groupNodes.sort((a: any, b: any) => {
        let aDate = a.isContact ? a.nexttaskduedate : a.duedate;
        let bDate = b.isContact ? b.nexttaskduedate : b.duedate;

        // Handle cases where dates are not available
        if (!aDate && !bDate) return 0;
        if (!aDate) return -1;
        if (!bDate) return 1;

        // Convert to timestamps for comparison
        let aTime = new Date(aDate).getTime();
        let bTime = new Date(bDate).getTime();
        return bTime - aTime; // Sort so that recent dates are first
      });

      const sizeRange = maxNodeSize - minNodeSize;
      const sizeStep = sizeRange / (groupNodes.length - 1);

      groupNodes.forEach((node: any, index: any) => {
        // Nodes without a date are assigned the minimum size
        if (node.isContact ? !node.nexttaskduedate : !node.duedate) {
          node.size = minNodeSize;
        } else {
          // Other nodes are assigned a size based on their sorted order
          node.size = minNodeSize + index * sizeStep;
        }
      });
    });
  }

  // Modify the getNodeSize function
  const getNodeSize = (node: any) => {
    return node.size || minNodeSize;
  };

  // Helper function to determine text size based on node size
  const getTextSize = (nodeSize: number) => {
    // Calculate the text size proportionally
    return (
      minTextSize +
      ((nodeSize - minNodeSize) / (maxNodeSize - minNodeSize)) *
        (maxTextSize - minTextSize)
    );
  };

  // This function will ensure the distance between nodes grows in a way that forms a spiral
  const linkDistance = (link: any) => {
    const sourceGroupId = link.source.taskQueueId;
    const sortedGroup = groups.get(sourceGroupId).otherNodes;
    const nodeIndex = sortedGroup.findIndex(
      (node: any) => node.id === link.source.id
    );

    return baseLinkLength + nodeIndex * linkLengthScalingFactor + startRadius;
  };

  const groups = new Map();

  function initializeSpiralPositions(
    nodes: any,
    initialAngleIncrement: any,
    svgWidth: any,
    svgHeight: any,
    selectedGalaxyId: number // Add this parameter to pass the selected galaxy id
  ) {
    // Group nodes by taskQueueId
    nodes.forEach((node: any) => {
      const groupId = node.taskQueueId;
      if (!groups.has(groupId)) {
        groups.set(groupId, { centralNode: null, otherNodes: [] });
      }
      if (node.nodeType === "queue") {
        groups.get(groupId).centralNode = node; // Identify the central node
      } else {
        groups.get(groupId).otherNodes.push(node); // Other nodes to be arranged in a spiral
      }
    });

    // Calculate rows and columns for central nodes
    const centralNodes = Array.from(groups.values()).map(
      (group) => group.centralNode
    );
    const gridSize = Math.ceil(Math.sqrt(centralNodes.length));

    centralNodes.forEach((centralNode, index) => {
      let newX,
        newY,
        shouldUpdate = false;

      const galaxyWheelPosition = centralNode.wheelposition?.find(
        (wp: any) => wp.galaxy_id === selectedGalaxyId
      );

      if (
        !galaxyWheelPosition ||
        (galaxyWheelPosition.x === 0 && galaxyWheelPosition.y === 0)
      ) {
        // Calculate new positions
        const row = Math.floor(index / gridSize);
        const col = index % gridSize;
        newX = (svgWidth / (gridSize + 1)) * (col + 1) * spacingMultiplier;
        newY =
          (svgHeight / (gridSize + 1)) * (row + 1) * spacingMultiplier * 1.25;
        centralNode.fx = newX;
        centralNode.fy = newY;
        shouldUpdate = true;
      } else {
        // Use existing positions
        centralNode.fx = galaxyWheelPosition.x;
        centralNode.fy = galaxyWheelPosition.y;
      }

      // if (shouldUpdate) {
      //   if (
      //     !galaxyWheelPosition ||
      //     galaxyWheelPosition.x !== newX ||
      //     galaxyWheelPosition.y !== newY
      //   ) {
      //     // Update wheel position only for the selected galaxy
      //     let updatedWheelPositions = centralNode.wheelposition.filter(
      //       (wp: any) => wp.galaxy_id !== selectedGalaxyId
      //     );
      //     updatedWheelPositions.push({
      //       x: newX,
      //       y: newY,
      //       galaxy_id: selectedGalaxyId,
      //     });

      //     // Dispatch the update here
      //     const newData = {
      //       wheelposition: updatedWheelPositions,
      //       name: centralNode.name,
      //       alias: centralNode.alias,
      //       default: centralNode.default,
      //       galaxies_ids: centralNode.galaxies_ids,
      //       projects_id: centralNode.projects_id,
      //     };

      //     dispatch(
      //       updateTaskQueues({
      //         data: newData,
      //         taskqueueId: centralNode.taskQueueId,
      //       })
      //     );
      //   }
      // }
    });

    groups.forEach((group, groupId, index: any) => {
      // Position central node

      const centralNode = group.centralNode;
      const otherNodes = group.otherNodes;

      otherNodes.sort(
        (a: any, b: any) =>
          (b.isContact
            ? parseInt(b?.nexttaskduedate || 0)
            : parseInt(b.duedate || 0)) -
          (a.isContact
            ? parseInt(a?.nexttaskduedate || 0)
            : parseInt(a.duedate || 0))
      );
      // Arrange other nodes in a spiral
      let angle = 0;
      let radius = startRadius;

      otherNodes.forEach((node: any) => {
        node.x = centralNode.fx + Math.cos(angle) * radius;
        node.y = centralNode.fy + Math.sin(angle) * radius;
        angle += initialAngleIncrement / (1 + getNodeSize(node) / maxNodeSize);
        radius += Math.max(5, getNodeSize(node) / 2);
      });
    });
  }

  useEffect(() => {
    const svg = d3.select(svgRef.current);

    const { nodes, links } = data;

    const svgElement: any = svgRef.current;
    const width = svgElement.clientWidth;
    const height = svgElement.clientHeight;

    // Initialize node positions in a spiral
    initializeSpiralPositions(
      nodes,
      Math.PI / 8,
      width,
      height,
      selectedGalaxyId
    );

    computeNodeSizes();

    // Define node radius here so it can be reused
    // const nodeRadius = 25; // Radius for regular nodes
    const centralNodeRadius = 15; // Smaller radius for the central node

    // Custom force to apply repulsion within the same group
    function forceGroupRepulsion(strength = 10) {
      let nodes: any;

      function force(alpha: any) {
        const k = strength * alpha;
        nodes.forEach((node: any, i: any) => {
          nodes.forEach((other: any, j: any) => {
            if (i === j || node.taskQueueId !== other.taskQueueId) return;

            const dx = node.x - other.x;
            const dy = node.y - other.y;
            let distance = Math.sqrt(dx * dx + dy * dy);
            if (distance === 0) distance = 0.001;

            const repulsionStrength = k / distance;
            node.vx += (dx / distance) * repulsionStrength;
            node.vy += (dy / distance) * repulsionStrength;
          });
        });
      }

      force.initialize = (_: any) => {
        nodes = _;
      };
      return force;
    }

    // Update simulation with charge, link, and collision forces
    const simulation = d3
      .forceSimulation(nodes as any)
      // .force("charge", d3.forceManyBody().strength(-50)) // Repulsion force
      .force("charge", d3.forceManyBody().strength(0)) // Set to 0 or a small value
      .force(
        "link",
        d3
          .forceLink(links as any)
          .id((d: any) => d.id)
          .distance(linkDistance)
      )
      .force("groupRepulsion", forceGroupRepulsion(40)); // Apply group repulsion force

    // Add a line for each link, and a circle for each node.
    const link = svg
      .append("g")
      .attr("stroke", "#999")
      .attr("stroke-opacity", 0.6)
      .selectAll("line")
      .data(links)
      .join("line")
      .attr("stroke-width", (d: any) => Math.sqrt(d.value));

    const defs = svg.append("defs");

    defs
      .selectAll(".node-pattern")
      .data(nodes.filter((node: any) => node.nodeType !== "queue")) // Filter out the central node
      .enter()
      .append("pattern")
      .attr("id", (d: any) => `avatar-${d?.id}`)
      .attr("class", "node-pattern")
      .attr("height", "100%")
      .attr("width", "100%")
      .attr("patternContentUnits", "objectBoundingBox")
      .append("image")
      .attr("height", 1)
      .attr("width", 1)
      .attr("preserveAspectRatio", "none")
      .attr("xlink:href", (d: any) => {
        if (d.isContact) {
          if (d?.pictureurl !== "") {
            return d?.pictureurl;
          } else {
            return avatarPlaceholder;
          }
        } else {
          if (d?._contacts?.pictureurl !== "") {
            return d?._contacts?.pictureurl;
          } else {
            return avatarPlaceholder;
          }
        }
      });

    // Now when you create nodes, use the pattern for filling the node
    const node = svg
      .append("g")
      .attr("stroke-width", 1.5)
      .selectAll("circle")
      .data(nodes)
      .join("circle")
      .attr("r", (d: any) => {
        return d.nodeType === "queue" ? centralNodeRadius : getNodeSize(d);
      }) // Set size based on due date
      .attr("stroke", (d: any) => {
        return getNodeBorderColor(d.isContact ? d?.nexttaskduedate : d.duedate);
      }) // Set border color based on due date
      .attr("fill", (d: any) =>
        d.nodeType === "queue" ? "grey" : `url(#avatar-${d.id})`
      )
      .on("click", (event: any, d: any) => {
        if (d.nodeType === "external") {
          const contactId = d.contact_id;
          onNodeClick(contactId);
        }
      });

    // node.append("title").text((d: any) => d.name);

    // Add text for queue count above queue nodes
    const queueAliasText = svg
      .append("g")
      .selectAll("text")
      .data(nodes.filter((node: any) => node.nodeType === "queue"))
      .enter()
      .append("text")
      .text((d: any) => `${d.alias}`) // split("-").d.name
      .attr("x", (d: any) => d.x)
      .attr("y", (d: any) => d.y) // Position above the node
      .attr("text-anchor", "middle")
      .style("fill", "#fff")
      .style("font-size", 14)
      .style("font-weight", "bold")
      .style("pointer-events", "none"); // Add this line

    // Add text for queue names below queue nodes
    const queueNameText = svg
      .append("g")
      .selectAll("text")
      .data(nodes.filter((node: any) => node.nodeType === "queue"))
      .enter()
      .append("text")
      .text((d: any) => `${d.name}`) // Name of the queue
      .attr("x", (d: any) => d.x)
      .attr("y", (d: any) => d.y) // Adjust the y position to display below the alias
      .attr("text-anchor", "middle")
      .style("fill", "#555")
      .style("font-family", "Arial")
      .style("font-size", 10); // Adjust the font size as needed

    // Add text for contact names above contact nodes
    const contactText = svg
      .append("g")
      .selectAll("text")
      .data(nodes.filter((node: any) => node.nodeType === "external"))
      .enter()
      .append("text")
      .text((d: any) => {
        if (d.isContact) {
          if (!d?.firstname && !d?.lastname) return d?.email;
          return `${d?.firstname ? d?.firstname : ""} ${
            d?.lastname ? d?.lastname : ""
          }`;
        } else {
          return `${d?._contacts?.firstname} ${d?._contacts?.lastname}`;
        }
      })
      .attr("x", (d: any) => d.x)
      .attr(
        "y",
        (d: any) => d.y - getNodeSize(d) - 12 - getTextSize(getNodeSize(d))
      ) // Adjust this based on the node size
      .attr("text-anchor", "middle")
      .style("fill", (d: any) =>
        getNodeBorderColor(d.isContact ? d?.nexttaskduedate : d.duedate)
      ) // Set text color based on due date
      .style("font-family", "Arial")
      .style("font-size", (d: any) => getTextSize(getNodeSize(d))) // Set text size dynamically
      .style("pointer-events", "none"); // Add this line

    const drag = d3
      .drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", (event) => {
        dragended(event, selectedGalaxyId);
      });

    node.call(drag as any);

    // Set the position attributes of links and nodes each time the simulation ticks.
    simulation.on("tick", () => {
      // positionNodesInSpiral();

      // Update link positions
      link
        .attr("x1", (d: any) => d.source.x)
        .attr("y1", (d: any) => d.source.y)
        .attr("x2", (d: any) => d.target.x)
        .attr("y2", (d: any) => d.target.y);

      // Update node positions
      node.attr("cx", (d: any) => d.x).attr("cy", (d: any) => d.y);

      // Update text positions for queue alias
      queueAliasText
        .attr("x", (d: any) => d.x) // Center horizontally
        .attr("y", (d: any) => d.y) // Adjust if needed to position the alias text
        .attr("dominant-baseline", "middle") // Vertically center the text
        .attr("text-anchor", "middle"); // Horizontally center the text

      // Update text positions for queue names
      queueNameText
        .attr("x", (d: any) => d.x) // Center horizontally
        .attr("y", (d: any) => d.y + 27) // Position below the alias text
        .attr("text-anchor", "middle"); // Horizontally center the text

      // Update text positions for contact nodes
      contactText
        .attr("x", (d: any) => d.x)
        .attr("y", (d: any) => d.y + getNodeSize(d) + 12); // Adjust text position based on node size
    });

    const zoom: any = d3
      .zoom()
      .scaleExtent([1 / 4, 6]) // Adjust zoom limits if necessary
      .on("zoom", (event: any) => {
        svg.selectAll("g").attr("transform", event.transform);
      });

    // Apply initial zoom
    svg.call(zoom).call(zoom.transform, d3.zoomIdentity.scale(initialZoom));

    // Reheat the simulation when drag starts, and fix the subject position.
    function dragstarted(event: any) {
      if (!event.active) simulation.alphaTarget(0.3).restart();

      event.subject.fx = event.subject.x;
      event.subject.fy = event.subject.y;

      // Reset or remove the transition force when dragging starts
      simulation.force("transition", null);
    }

    // Update the subject (dragged node) position during drag.
    function dragged(event: any, d: any) {
      d.fx = event.x;
      d.fy = event.y;
    }

    // Restore the target alpha so the simulation cools after dragging ends.
    // Unfix the subject position now that it’s no longer being dragged.
    function dragended(event: any, selectedGalaxyId: number) {
      if (!event.active) simulation.alphaTarget(0);

      if (event.subject.nodeType === "queue") {
        let updatedWheelPositions = [...event.subject.wheelposition];

        // Find the index of the wheelposition for the selected galaxy
        const galaxyPositionIndex = updatedWheelPositions.findIndex(
          (wp) => wp.galaxy_id === selectedGalaxyId
        );

        // Update or add the wheelposition for the selected galaxy
        if (galaxyPositionIndex !== -1) {
          updatedWheelPositions[galaxyPositionIndex] = {
            x: event.subject.x,
            y: event.subject.y,
            galaxy_id: selectedGalaxyId,
          };
        } else {
          updatedWheelPositions.push({
            x: event.subject.x,
            y: event.subject.y,
            galaxy_id: selectedGalaxyId,
          });
        }

        const newData = {
          wheelposition: updatedWheelPositions,
          name: event.subject.name,
          alias: event.subject.alias,
          default: event.subject.default,
          galaxies_ids: event.subject.galaxies_ids,
          projects_id: event.subject.projects_id,
        };

        dispatch(
          updateTaskQueues({
            data: newData,
            taskqueueId: event.subject.taskQueueId,
          })
        );
      }

      if (event.subject.nodeType !== "queue") {
        event.subject.fx = null;
        event.subject.fy = null;
      }
    }

    // positionNodesInSpiral();

    // Remember to clear the old zoom behavior before applying a new one if the component updates
    return () => {
      svg.selectAll("*").remove();
      svg.on(".zoom", null);
    };
  }, [data]);

  return (
    <svg
      ref={svgRef}
      className="w-full h-full overflow-visible"
      width="100%"
      height="100%"
    ></svg>
  );
};

export default ForceGraph;
