import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrls from "../../../api";
import { IAppLoaderAction } from "../../../common/state/slice/loaderHandleMiddleware";
import { RootState } from "../../../common/state/store";

export interface IUser {
  myUser: any;
  myCurrentActiveSubscription: any;
}

export const getMyUser = createAsyncThunk(
  "users/getMyUser",
  async (componentId: IAppLoaderAction) => {
    let response;
    await axios
      .get(`${apiUrls.myUser.get}`)
      .then((res) => {
        response = res.data;
      })
      .catch((e) => {
        console.error(e);
        return e;
      });

    return response;
  }
);

export const getMyCurrentActiveSubscription = createAsyncThunk(
  "users/getMyCurrentActiveSubscription",
  async (componentId: IAppLoaderAction) => {
    let response;
    await axios
      .post(`${apiUrls.myUser.getMyCurrentActiveSubscription}`)
      .then((res) => {
        response = res.data;
      })
      .catch((e) => {
        console.error(e);
        return e;
      });

    return response;
  }
);

export const updateMyUser = createAsyncThunk(
  "users/updateMyUser",
  async ({ data }: { data: any } & IAppLoaderAction) => {
    let response;
    console.log(data);
    await axios
      .put(`${apiUrls.myUser.updateMe}`, { ...data })
      .then((res) => {
        console.log("res.data", res.data);
        response = res.data;
      })
      .catch((e) => console.error(e));

    return response;
  }
);

const initialState: IUser = {
  myUser: {},
  myCurrentActiveSubscription: {},
};

export const myUserSlice = createSlice({
  name: "myUser",
  initialState,
  reducers: {
    clearUser: (state: IUser) => {
      state.myUser = {};
    },
    // updateMyFirstProjectInTheUser: (state: IUser, { payload }) => {
    //   state.myUser.projectsowner[0] = payload;
    // },
    // createMyFirstProjectInTheUser: (state: IUser, { payload }) => {
    //   state.myUser.projectsowner.push(payload);
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(getMyUser.fulfilled, (state: IUser, { payload }) => {
      state.myUser = payload;
    });
    builder.addCase(
      getMyCurrentActiveSubscription.fulfilled,
      (state: IUser, { payload }) => {
        state.myCurrentActiveSubscription = payload;
      }
    );
    builder.addCase(updateMyUser.fulfilled, (state: IUser, { payload }) => {
      state.myUser = payload;
    });
  },
});

export const myUserReducer = myUserSlice.reducer;

export const getCurrentInfoAboutMyUser = (state: RootState) =>
  state.myUser.myUser;
export const getCurrentInfoAboutMyActiveSubscription = (state: RootState) =>
  state.myUser.myCurrentActiveSubscription;

export const { clearUser } = myUserSlice.actions;
