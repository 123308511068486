import { useEffect, useState } from "react";
import {
  FaChevronDown,
  FaChevronUp,
  FaGlobe,
  FaLink,
  FaMinus,
  FaPencilAlt,
  FaPlus,
  FaPodcast,
  FaRegCheckSquare,
  FaRegSquare,
  FaTimes,
  FaTrash,
  FaYoutube,
} from "react-icons/fa";
import { useForm } from "react-hook-form";
import { Transition } from "@headlessui/react";
import { useSelector } from "react-redux";
import { openStandardModal } from "../../../common/state/slice/modal/modalSlice";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../common/state/hooks";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiOutlineLinkedin,
} from "react-icons/ai";
import {
  getCurrentSpecificContact,
  updateContact,
  updateTask,
} from "../../../features/contacts/state/contactsSlice";
import { CONTACT_COMPONENT_ID } from "../dashboard";
import { DynamicTextarea } from "../../../common/localComponents/Textarea/DynamicTextarea";
import { RootState } from "../../../common/state/store";

type TaskFormInstances = {
  [key: number]: ReturnType<typeof useForm>;
};

export function ContactInfoTab({setChosenTask}: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [notEditableNotes, setNotEditableNotes] = useState(true);

  const { specificContact } = useSelector((state: RootState) => {
    return {
      specificContact: getCurrentSpecificContact(state),
    };
  });

  const renderLinkIcons = (contact: any) => {
    const linkTypes = [
      { type: "linkedin", icon: AiOutlineLinkedin, url: contact.linkedin },
      { type: "instagram", icon: AiFillInstagram, url: contact.instagram },
      { type: "facebook", icon: AiFillFacebook, url: contact.facebook },
      { type: "podcast", icon: FaPodcast, url: contact.podcast },
      { type: "youtube", icon: FaYoutube, url: contact.youtube },
      { type: "website", icon: FaGlobe, url: contact.website }, // Changed icon for website
      { type: "sourceurl", icon: FaLink, url: contact.sourceurl }, // Changed icon for source URL
    ];

    // Filter out link types without a URL
    const validLinks = linkTypes.filter((link) => link.url);

    if (validLinks.length === 0) {
      return null; // Return null if no links are present
    }

    return (
      <div className="flex flex-wrap">
        {validLinks.map((link, index) => (
          <button
            key={index}
            onClick={() => window.open(link.url, "_blank")}
            className="p-1 m-1"
          >
            <link.icon size={40} />
          </button>
        ))}
      </div>
    );
  };

  const {
    control,
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    if (notEditableNotes) {
      setNotEditableNotes(false);
    } else {
      if (watch("notes") !== specificContact.notes) {
        dispatch(
          updateContact({
            componentId: CONTACT_COMPONENT_ID,
            contactId: specificContact.id,
            data: {
              ...specificContact,
              notes: data.notes,
            },
          })
        ).then((res: any) => {
          setNotEditableNotes(true);
        });
      } else {
        setNotEditableNotes(true);
      }
    }
  };

  // Function to format date in the desired format
  const formatDate = (timestamp: any) => {
    const date = new Date(timestamp);
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }).format(date);
  };

  return (
    <>
      {/* LinkedIn and CRM links */}
      {specificContact?.email && specificContact.email !== "" && (
        <>
          <h3 className="flex-1 text-lg font-semibold">{"Email: "}</h3>
          <a
            href={`mailto:${specificContact.email}`}
            className="text-blue-500 hover:underline"
          >
            {specificContact.email}
          </a>
        </>
      )}
      {specificContact.phone && (
        <>
          <h3 className="flex-1 text-lg font-semibold">{"Phone number: "}</h3>
          <p className="text-blue-500">{specificContact.phone}</p>
        </>
      )}
      <div className="flex justify-start items-center mt-4">
        <h3 className="text-lg font-semibold mr-3">CRM:</h3>
        <button
          className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300 ease-in-out"
          onClick={() =>
            window.open(
              `https://app.brevo.com/contact/index/${specificContact.externalcrm_id}`,
              "_blank"
            )
          }
        >
          Go to Brevo
        </button>
      </div>
      {/* {contactDetails.linkedin && ( */}
      <div className="flex justify-start items-center">
        {specificContact && (
          <div className="mt-4">
            {renderLinkIcons(specificContact) && (
              <h3 className="flex-1 text-lg font-semibold">Links:</h3>
            )}
            {renderLinkIcons(specificContact)}
          </div>
        )}
      </div>
      <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex w-full">
          <h3 className="text-lg font-semibold mr-2">
            {t("pages.dashboard.noteInput.title")}
          </h3>
          <div className="flex items-center">
            <button
              type="submit"
              // onClick={(e) => {
              //   e.preventDefault(); // Prevent default form submission or any other default action
              //   // toggleTaskqueueCollapse(taskqueue.id);
              // }}
              className={` ${
                !notEditableNotes
                  ? ` text-green-500  hover:text-green-700 mr-2`
                  : `text-gray-500  hover:text-gray-700 mr-2`
              }`}
            >
              {!notEditableNotes && <FaRegCheckSquare size={20} />}
              {notEditableNotes && <FaPencilAlt size={20} />}
            </button>
          </div>
        </div>

        <div className="mt-2">
          <DynamicTextarea
            registerFct={() => register("notes")}
            placeholder={t("pages.dashboard.noteInput.placeholder")}
            readOnly={notEditableNotes}
            defaultHeight={70}
            style={{
              resize: "none",
              height: notEditableNotes ? "auto" : "",
            }}
            defaultValue={specificContact.notes}
            className=" min-h-[38px] read-only:cursor-default w-full font-medium read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600 text-center sm:text-left ${ ? }"
          />
        </div>
      </form>
      {/* Tasks Listing */}
      <div className="mt-4">
        <div className="flex w-full">
          <h3 className="flex-1 text-lg font-semibold">Tasks:</h3>
          <button
            className="flex items-center justify-center p-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition duration-300 ease-in-out"
            onClick={() => {
              dispatch(openStandardModal("modalNewTaskPopup"));
            }}
          >
            <FaPlus />
          </button>
        </div>
        <ul className="mb-24">
          {[...specificContact?.tasks] // Create a shallow copy of the array
            .sort((a: any, b: any) => {
              // First, sort by status (todo before done)
              if (a.status === "todo" && b.status !== "todo") {
                return -1;
              } else if (a.status !== "todo" && b.status === "todo") {
                return 1;
              }

              // If statuses are the same, then sort by due date
              return (
                new Date(a.duedate).getTime() - new Date(b.duedate).getTime()
              );
            })
            .map((task: any) => (
              // {specificContact?.tasks.map((task: any) => (
              <li
                key={task.id}
                className="border-b py-2 flex justify-between items-center"
              >
                <div>
                  {task.name && (
                    <div className="font-semibold">{task?.name}</div>
                  )}
                  {task.description && (
                    <div className="text-sm text-gray-600">
                      {task?.description}
                    </div>
                  )}
                  {/* Display formatted date */}
                  <div className="text-sm text-gray-600">
                    Due: {formatDate(task?.duedate)}
                  </div>
                  <div className="text-sm text-gray-600">
                    {`Wheel: ${task?.taskqueues[0]?.name ?? ""}`}
                  </div>
                </div>
                <div className="flex items-center">
                  <button
                    onClick={() => {
                      setChosenTask(task);
                      // Convert duedate from 'YYYY-MM-DD' to Unix timestamp
                      const dueDateTimestamp = new Date(
                        task?.duedate
                      ).getTime();

                      const status = task.status === "todo" ? "done" : "todo";

                      // Create the new task data structure
                      const editTaskData = {
                        ...task,
                        taskqueue_id: parseInt(task.taskqueue_id),
                        duedate: dueDateTimestamp,
                        status: status,
                        contacts_id: specificContact.id, // Add the contactId to the data if it's available
                      };

                      dispatch(
                        updateTask({
                          data: editTaskData,
                          taskId: task.id,
                        })
                      );
                    }}
                    className={`${
                      task.status === "todo"
                        ? `text-gray-500 hover:text-gray-700 mr-2`
                        : `text-green-500 hover:text-green-700 mr-2`
                    }`}
                  >
                    {task.status === "todo" ? (
                      <FaRegSquare />
                    ) : (
                      <FaRegCheckSquare />
                    )}
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default form submission or any other default action
                      setChosenTask(task);
                      dispatch(openStandardModal("modalEditTaskPopup"));
                    }}
                    className="text-gray-500 hover:text-gray-700 mr-2"
                  >
                    <FaPencilAlt />
                  </button>
                  <button
                    onClick={() => {
                      setChosenTask(task);
                      dispatch(openStandardModal("removeTheTaks"));
                    }}
                    className="text-red-500 hover:text-red-700"
                  >
                    <FaTrash />
                  </button>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
}
