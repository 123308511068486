import { IconName } from "../Icon/Icon";
import * as AllIcons from "@heroicons/react/24/outline";
import { InputProps } from "./Input";
import "../../../index.css";
import { FaSearch } from "react-icons/fa";

interface SearchBarProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    InputProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void; // Custom onChange handler
  onClick: (event: any) => void; // Custom onClick handler
}

const SearchBar = ({ onChange, onClick, ...props }: SearchBarProps) => {
  return (
    <>
      <div className="relative">
        <input
          {...props}
          onChange={onChange}
          className={`pl-10 rounded-xl border-gray-600 focus:border-gray-600 focus:outline-none ${props.className}`}
        />
        <FaSearch className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-600 w-5 h-5" />
        <div className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-600">
          <div className="flex">
            <button
              className="btn-primary-fill flex justify-center items-center text-white bg-blue-500 hover:bg-blue-600 focus-visible:ring-blue-500 h-7 text-sm"
              type="button"
              onClick={onClick}
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchBar;
