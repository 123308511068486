import React from "react";
import "../../../index.css";

interface ISpinnerProps {
  relative?: boolean;
  style?: any;
}

export const Spinner = ({ relative = false, style = {} }: ISpinnerProps) => {
  const DaSpinner = () => (
    <div className="absolute left-1/2 top-1/2 z-50 transform -translate-x-1/2 -translate-y-1/2 h-10 w-10">
      <svg
        className="relative animate-spin text-primary" //left-[calc(50%-1.5rem)] top-[calc(50%-1.25rem)]
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        style={{ ...style }}
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
  );

  return relative ? (
    <div className="relative">
      <DaSpinner />
    </div>
  ) : (
    <DaSpinner />
  );
};

//  default Spinner;
