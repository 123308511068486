import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, useFormState } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import React from "react";
import logo from "../../../assets/logo.png";
import { AppDispatch, RootState } from "../../../common/state/store";
import { errorSelector } from "../../../common/state/slice/appStateSlice";
import { jwtSelector } from "../../../common/state/selectors/authSelector";
import { IsLoading } from "../../../common/localComponents/AppState/IsLoading";
import { Input, InputIcon } from "../../../common/localComponents/Input";
export const LOGIN_COMPONENT_ID = "loginComponent";
import Icon, { IconName } from "../../../common/localComponents/Icon/Icon";
import { SettingsSidebarItem } from "./settingsSidebarItem";
import { use } from "i18next";
import {
  deleteTaskQueue,
  getCurrentMessageTemplates,
  getMessageTemplates,
  getMyProject,
  importContacts,
  updateProject,
  updateTaskQueues,
} from "../../../features/contacts/state/contactsSlice";
import { Transition } from "@headlessui/react";
import {
  FaCaretDown,
  FaCaretUp,
  FaMinus,
  FaPencilAlt,
  FaPlus,
  FaPlusCircle,
  FaRegArrowAltCircleDown,
  FaRegArrowAltCircleUp,
  FaTrash,
} from "react-icons/fa";
import { TaskqueueToEditForm } from "./TaskqueueToEditForm";
import {
  closeStandardModal,
  openStandardModal,
} from "../../../common/state/slice/modal/modalSlice";
import NewTaskqueuePopup from "../../../common/localComponents/Modal/forTaskqueue/NewTaskqueuePopup";
import { set } from "lodash";
import EditTaskqueuePopup from "../../../common/localComponents/Modal/forTaskqueue/EditTaskqueuePopup";
import { ModalConfirmation } from "../../../common/localComponents/Modal";
import NewMessageTemplatePopup from "../../../common/localComponents/Modal/forMessageTemplates/NewMessageTemplatesPopup";
import EditMessageTemplatePopup from "../../../common/localComponents/Modal/forMessageTemplates/EditMessageTemplatesPopup";
import { DynamicTextarea } from "../../../common/localComponents/Textarea/DynamicTextarea";
export const BUSINESSINFO_COMPONENT_ID = "businessInfoComponent";

type SettingsSideBarLinkProps = {
  title: string;
  iconName: IconName;
  onClick(): void;
};

const MyBusinessInfo = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const {
    control,
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(getMyProject({ componentId: BUSINESSINFO_COMPONENT_ID })).then(
      (res: any) => {
        reset(res.payload[0]);
      }
    );
  }, []);

  const onSubmit = (data: any) => {
    dispatch(
      updateProject({ data: data, componentId: BUSINESSINFO_COMPONENT_ID })
    );
  };

  return (
    <div className="mb-24">
      <div className="flex w-full mb-4">
        <h3 className="flex-1 text-4xl font-semibold text-start ">
          {t("pages.settings.myBusinessInfo.title")}
        </h3>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="border-t-2 py-4 mt-2">
          <IsLoading
            componentId={BUSINESSINFO_COMPONENT_ID}
            showSuccess={false}
          >
            <div className="flex flex-col w-full">
              <div className="flex w-full mb-4">
                <DynamicTextarea
                  registerFct={() => register("whoweare")}
                  label={"Who we are as a business?"}
                  style={{
                    resize: "none",
                    height: "auto",
                  }}
                  className={`min-h-[38px] w-full read-only:cursor-default font-medium read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600 text-center sm:text-left ${
                    errors.tags ? "border-red-500" : ""
                  } `}
                  placeholder={"Enter who we are as a business"}
                />
              </div>
              <div className="flex w-full mb-4">
                <DynamicTextarea
                  registerFct={() => register("whatwesell")}
                  label={"What do we sell?"}
                  style={{
                    resize: "none",
                    height: "auto",
                  }}
                  className={`min-h-[38px] w-full read-only:cursor-default font-medium read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600 text-center sm:text-left ${
                    errors.tags ? "border-red-500" : ""
                  } `}
                  placeholder={"Enter what we sell"}
                />
              </div>
              <div className="flex w-full mb-4">
                <DynamicTextarea
                  registerFct={() => register("towhomwesell")}
                  label={"To whom do we sell?"}
                  style={{
                    resize: "none",
                    height: "auto",
                  }}
                  className={`min-h-[38px] w-full read-only:cursor-default font-medium read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600 text-center sm:text-left ${
                    errors.tags ? "border-red-500" : ""
                  } `}
                  placeholder={"Enter to whom we sell"}
                />
              </div>
              <div className="flex flex-1 justify-end my-auto">
                <button
                  className="btn-primary-fill text-white bg-blue-500 hover:bg-blue-600 focus-visible:ring-blue-500"
                  type="submit"
                >
                  Update
                </button>
              </div>
            </div>
          </IsLoading>
        </div>
      </form>
    </div>
  );
};

export default MyBusinessInfo;
