import { IUser } from "../../model/model";

const AUTH_USER_KEY = "crm.auth.user";
const AUTH_AUTHTOKEN_KEY = "crm.auth.authToken";
const AUTH_AUTHTOKEN_ISREMEMBERED = "crm.auth.isRemebered";
const TOP_BAR_CONFIG = "crm.topBar.config";

export const storeInLocalStorage = (key: string, value: any) => {
  localStorage.setItem(key, value);
};

export const getFromLocalStorage = (key: string) => {
  return localStorage.getItem(key) ? localStorage.getItem(key) : "";
};

export const getTopBarConfigInLocalStorage = () => {
  return getFromLocalStorage(TOP_BAR_CONFIG);
};

export const storeTopBarConfigInLocalStorage = (value: string) => {
  storeInLocalStorage(TOP_BAR_CONFIG, value);
};

export const removeTopBarConfigInLocalStorage = () => {
  localStorage.removeItem(TOP_BAR_CONFIG);
};

export const storeAuthInLocalStorage = (
  authToken: string,
  isRemebered: boolean
) => {
  storeInLocalStorage(AUTH_AUTHTOKEN_KEY, authToken);
  storeInLocalStorage(AUTH_AUTHTOKEN_ISREMEMBERED, isRemebered);
};

export const getAuthFromLocalStorage = (): {
  authToken: string | null;
  isRemebered: boolean | null;
} => {
  const userStr = getFromLocalStorage(AUTH_USER_KEY);
  const user = userStr && userStr !== "" ? (JSON.parse(userStr) as any) : null;
  const authToken = getFromLocalStorage(AUTH_AUTHTOKEN_KEY);
  const isRemeberedStr = getFromLocalStorage(AUTH_AUTHTOKEN_ISREMEMBERED);
  const isRemebered =
    isRemeberedStr && isRemeberedStr !== ""
      ? (JSON.parse(isRemeberedStr) as boolean)
      : null;
  return { authToken, isRemebered };
};
