import { Tooltip } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Icon, { IconName } from "../../../common/localComponents/Icon/Icon";

export interface SettingsSideBarItemProps {
  icon: IconName;
  title: string;
  onClick(): void;
  disabled?: boolean;
  tooltipText?: string;
}

const SettingsSidebarItem = ({
  icon,
  title,
  onClick,
  disabled,
  ...props
}: SettingsSideBarItemProps) => {
  return (
    <div onClick={onClick}>
      <button
        className="flex w-full p-2 pl-4 items-center justify-center cursor-pointer hover:bg-slate-100" //bg-primary hover:bg-primary-darker rounded-lg my-4
        {...props}
      >
        <Icon
          icon={icon}
          className="w-6 h-6 ml-1 mr-4 stroke-black align-middle"
        />
        <div className="w-full">
          <p className="text-black text-sm align-middle text-start">{title}</p>
        </div>
      </button>
    </div>
  );
};

const SettingsSidebarItemSmall = ({
  icon,
  title,
  onClick,
  disabled,
  ...props
}: SettingsSideBarItemProps) => {
  return (
    <div onClick={onClick}>
      <button
        className="flex w-full my-1 px-2 cursor-pointer py-2 items-center hover:bg-slate-100 align-middle" //bg-primary hover:bg-primary-darker rounded-lg
        {...props}
      >
        <div className="mr-2">
          <Icon icon={icon} className="w-4 h-4 ml-1 stroke-black" />
        </div>
        <div className="w-full">
          <p className="text-black text-sm align-middle">{title}</p>
        </div>
      </button>
    </div>
  );
};

export { SettingsSidebarItem, SettingsSidebarItemSmall };
