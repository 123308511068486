import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../state/hooks";
import { RootState } from "../../../state/store";
import { closeStandardModal } from "../../../state/slice/modal/modalSlice";
import { XMarkIcon } from "@heroicons/react/24/outline";
import ReactPlayer from "react-player";
import { Spinner } from "../../Loader/Spinner";
import { useForm } from "react-hook-form";
import { Dropdown, Input } from "../../Input";
import { DynamicTextarea } from "../../Textarea/DynamicTextarea";
import { useTranslation } from "react-i18next";
import {
  createNewTask,
  getAllTaskQueues,
  getCurrentTaskQueues,
} from "../../../../features/contacts/state/contactsSlice";
import { IsLoading } from "../../AppState/IsLoading";
import { CONTACT_COMPONENT_ID } from "../../../../pages/Dashboard/dashboard";
import { use } from "i18next";

export const TASKQUEUES_COMPONENT_ID = "taskQueuesComponent";

type NewTaskPopupProps = {
  id: string;
  contactId: number;
  galaxies: any;
};

const NewTaskPopup = ({ id, contactId, galaxies }: NewTaskPopupProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const modalState = useSelector(
    (state: RootState) => state.modals.showStandardModal
  );

  const [taskQueuesOptions, setTaskQueuesOptions] = useState<any[]>([]); // [{ value: "1", optionText: "Published" }
  const [galaxiesOptions, setGalaxiesOptions] = useState<any[]>([]); // [{ value: "1", optionText: "Published" }

  const {
    control,
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    // Convert duedate from 'YYYY-MM-DD' to Unix timestamp
    const dueDateTimestamp = new Date(data.duedate).getTime();

    // Create the new task data structure
    const newTaskData = {
      ...data,
      taskqueue_id: parseInt(data.taskqueue_id),
      duedate: dueDateTimestamp,
      // status: "todo", // You seem to want to set the status as 'todo' by default
      contacts_id: contactId, // Add the contactId to the data if it's available
    };

    // Dispatch the action to create a new task with the modified data

    dispatch(closeStandardModal({ id }));

    dispatch(
      createNewTask({ componentId: CONTACT_COMPONENT_ID, data: newTaskData })
    );
  };

  const { taskQueues } = useSelector((state: RootState) => {
    return {
      taskQueues: getCurrentTaskQueues(state),
    };
  });

  useEffect(() => {
    setGalaxiesOptions(
      galaxies
        .filter((galaxy: any) => galaxy.id !== 0)
        .sort((a: any, b: any) => a.order - b.order)
        .map((galaxy: any) => ({
          value: galaxy.id,
          optionText: galaxy.name,
        }))
    );
  }, [contactId]);

  useEffect(() => {
    const foundGalaxy: any = galaxies.find(
      (galaxy: any) => galaxy.id === parseInt(watch("galaxy_id"))
    );

    console.log("foundGalaxy 2", foundGalaxy);

    if (foundGalaxy !== undefined && foundGalaxy.taskqueues !== undefined) {
      const localTaskQueuesOptions = [...foundGalaxy.taskqueues]
        .sort((a: any, b: any) => a.alias - b.alias)
        .map((taskQueue: any) => ({
          value: taskQueue.id,
          optionText: taskQueue.name,
        }));

      setTaskQueuesOptions(localTaskQueuesOptions);

      reset({
        ...watch(),
        taskqueue_id: localTaskQueuesOptions[0]?.value,
      });
    } else {
      setTaskQueuesOptions([]);
      reset({ ...watch(), taskqueue_id: "0" });
    }
  }, [watch("galaxy_id")]);

  useEffect(() => {
    if (contactId) {
      reset({ taskqueue_id: "0" });
    }
  }, [contactId]);

  return (
    <>
      {id === modalState.id && (
        <Transition appear show={modalState.show} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 overflow-y-auto"
            onClose={() => dispatch(closeStandardModal({ id }))}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className={"fixed inset-0 bg-black bg-opacity-25"} />
            </Transition.Child>
            <div className={`fixed inset-0`}>
              <div
                className={
                  "flex items-center justify-center p-4 text-center h-full"
                }
              >
                <div className={"mt-auto mb-auto"}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel
                      className={`h-full transform no-scrollbarw-halfscreen max-w-3xl rounded-2xl pt-auto pb-auto overflow-y-auto bg-slate-100 p-6 text-left align-middle shadow-xl transition-all`}
                    >
                      <div className="relative grow w-full md:w-[500px] max-w-9xl px-4 xl:px-0 mx-auto">
                        <IsLoading
                          componentId={TASKQUEUES_COMPONENT_ID}
                          showSuccess={false}
                        >
                          <form
                            className="w-full"
                            onSubmit={handleSubmit(onSubmit)}
                          >
                            <h3 className="text-2xl font-semibold text-start mb-4">
                              {t("pages.dashboard.newTask.title")}
                            </h3>
                            <Input
                              registerFct={() =>
                                register("name", {
                                  required: true,
                                })
                              }
                              type="text"
                              label={"Title"}
                              placeholder={t(
                                "pages.dashboard.newTask.inputs.titlePlaceholder"
                              )}
                              className={`w-full ${
                                errors.name ? "border-red-500" : ""
                              }`}
                            />
                            <div className="flex mt-2">
                              <DynamicTextarea
                                registerFct={() => register("description")}
                                label={"Description"}
                                // style={{ resize: notEditable ? "none" : "vertical" }}
                                style={{
                                  resize: "none",
                                  height: "auto", //notEditable ? "auto" :
                                }}
                                className={`min-h-[38px] w-full read-only:cursor-default font-medium read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600 text-center sm:text-left ${
                                  errors.description ? "border-red-500" : ""
                                } `}
                                placeholder={t(
                                  "pages.dashboard.newTask.inputs.descriptionPlaceholder"
                                )}
                              />
                            </div>
                            <div className="flex">
                              <div className="w-full pr-2">
                                <Input
                                  registerFct={() =>
                                    register("duedate", {
                                      required: true,
                                    })
                                  }
                                  type="date"
                                  label={"Due Date"}
                                  placeholder={t(
                                    "pages.dashboard.newTask.inputs.duedatePlaceholder"
                                  )}
                                  className={`w-full ${
                                    errors.duedate ? "border-red-500" : ""
                                  }`}
                                />
                              </div>
                              <div className="w-full pl-2">
                                <Dropdown
                                  options={[
                                    {
                                      value: "todo",
                                      optionText: "To Do",
                                    },
                                    {
                                      value: "done",
                                      optionText: "Done",
                                    },
                                  ]}
                                  label="Status"
                                  className={`w-full font-medium disabled:hide-arrow-select disabled:bg-white disabled:border-white disabled:shadow-none disabled:focus:ring-0 disabled:text-gray-900 text-gray-600 text-center sm:text-left ${
                                    errors.taskqueue_id ? "border-red-500" : ""
                                  } `}
                                  defaultValue={"todo"}
                                  // style={{
                                  //   border: errors.taskqueue_id
                                  //     ? "1.5px solid rgb(239 68 68)"
                                  //     : "",
                                  // }}
                                  registerFct={() =>
                                    register("status", {
                                      required: true,
                                      validate: (value) => value !== "0",
                                    })
                                  }
                                  asDefaultValue={true}
                                />
                              </div>
                            </div>
                            <div className="flex mt-2">
                              <div className="w-full pr-2">
                                <Dropdown
                                  options={galaxiesOptions}
                                  label="Galaxy"
                                  className={`w-full font-medium disabled:hide-arrow-select disabled:bg-white disabled:border-white disabled:shadow-none disabled:focus:ring-0 disabled:text-gray-900 text-gray-600 text-center sm:text-left ${
                                    errors.galaxy_id ? "border-red-500" : ""
                                  } `}
                                  // style={{
                                  //   border: errors.taskqueue_id
                                  //     ? "1.5px solid rgb(239 68 68)"
                                  //     : "",
                                  // }}
                                  registerFct={() =>
                                    register("galaxy_id", {
                                      required: true,
                                      validate: (value) => value !== "0",
                                    })
                                  }
                                  asDefaultValue={false}
                                />
                              </div>
                              <div className="w-full pl-2">
                                <Dropdown
                                  options={taskQueuesOptions}
                                  label="Task Queue"
                                  className={`w-full font-medium disabled:hide-arrow-select disabled:bg-white disabled:border-white disabled:shadow-none disabled:focus:ring-0 disabled:text-gray-900 text-gray-600 text-center sm:text-left ${
                                    errors.taskqueue_id ? "border-red-500" : ""
                                  } `}
                                  // style={{
                                  //   border: errors.taskqueue_id
                                  //     ? "1.5px solid rgb(239 68 68)"
                                  //     : "",
                                  // }}
                                  registerFct={() =>
                                    register("taskqueue_id", {
                                      required: true,
                                      validate: (value) => value !== "0",
                                    })
                                  }
                                  asDefaultValue={
                                    watch("galaxy_id") !== "0" ? true : false
                                  }
                                />
                              </div>
                            </div>
                            <div className="flex justify-end m-auto mt-4">
                              <button
                                className="btn-primary-fill"
                                type="submit"
                              >
                                {t("pages.dashboard.newTask.button")}
                              </button>
                            </div>
                          </form>
                        </IsLoading>
                      </div>

                      <button
                        className="z-50 absolute top-2 right-4"
                        type="button"
                        onClick={() => {
                          dispatch(closeStandardModal({ id }));
                          reset();
                        }}
                      >
                        <XMarkIcon className="w-6 h-6" />
                      </button>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
    </>
  );
};

export default NewTaskPopup;
