import { RootState } from "../../common/state/store";
import { createSlice } from "@reduxjs/toolkit";

export const tabStateSlice = createSlice({
  name: "tabState",
  initialState: {
    currentTab: "contactInfo",
  },
  reducers: {
    changeTab: (state, action) => {
      state.currentTab = action.payload;
    },
  },
});

export const { changeTab } = tabStateSlice.actions;

export const selectCurrentTab = (state: RootState) => state.tabState.currentTab;

export const tabStateReducer = tabStateSlice.reducer;
