import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrls from "../../../api";
import { IAppLoaderAction } from "../../../common/state/slice/loaderHandleMiddleware";
import { RootState } from "../../../common/state/store";
import { stat } from "fs";
import exp from "constants";

export interface IContacts {
  contacts?: any[];
  specificContact?: any;
  tasks?: any[];
  galaxies?: any[];
  specificTask?: any;
  taskQueues?: any[];
  messageTemplates?: any[];
  myProject?: any;
}

export const getAllContacts = createAsyncThunk(
  "contacts/getAllContacts",
  async (componentId: IAppLoaderAction) => {
    let allItems: any[] = [];
    let currentPage = 1;
    let totalPages = 0;

    do {
      try {
        const response = await axios.get(
          `${apiUrls.contacts.get}?page=${currentPage}`
        );
        const data = response.data;
        console.log("Page:", currentPage, "data:", data);

        // Add items from the current page to the allItems array
        allItems = allItems.concat(data.items);

        // Update the page count
        currentPage++;
        totalPages = data.pageTotal;
      } catch (error) {
        console.error(error);
        return error;
      }
    } while (currentPage <= totalPages);

    return allItems;
  }
);

export const getAllTasks = createAsyncThunk(
  "contacts/getAllTasks",
  async (componentId: IAppLoaderAction) => {
    let response;

    console.log("apiUrls.tasks.get", apiUrls.tasks.get);

    await axios
      .get(`${apiUrls.tasks.get}?status=todo`)
      .then((res: any) => {
        console.log("res.data", res.data);
        response = res.data;
      })
      .catch((e: any) => {
        console.error(e);
        return e;
      });

    return response;
  }
);

export const getGalaxies = createAsyncThunk(
  "contacts/getGalaxies",
  async (componentId: IAppLoaderAction) => {
    let response;

    console.log("apiUrls.galaxies.getMy", apiUrls.galaxies.getMy);

    await axios
      .get(`${apiUrls.galaxies.getMy}`)
      .then((res: any) => {
        console.log("res.data", res.data);
        response = res.data;
      })
      .catch((e: any) => {
        console.error(e);
        return e;
      });

    return response;
  }
);

export const createNewGalaxy = createAsyncThunk(
  "contacts/createNewGalaxy",
  async ({ data }: { data: any } & IAppLoaderAction) => {
    let response;

    console.log("createNewGalaxy data", data);
    await axios
      .post(`${apiUrls.galaxies.create}`, data)
      .then((res: any) => {
        response = res.data;
      })
      .catch((e: any) => {
        console.error(e);
        return e;
      });

    return response;
  }
);

export const updateGalaxy = createAsyncThunk(
  "contacts/updateGalaxy",
  async ({
    data,
    galaxyId,
  }: { data: any; galaxyId: any } & IAppLoaderAction) => {
    let response;

    console.log("updateGalaxy data", data);
    await axios
      .patch(`${apiUrls.galaxies.update}/${galaxyId}`, data)
      .then((res: any) => {
        console.log("updateGalaxy res.data", res.data);

        response = res.data;
      })
      .catch((e: any) => {
        console.error("updateGalaxy e:", e);
        return e;
      });

    console.log("updateGalaxy response:", response);

    return response;
  }
);

export const deleteGalaxy = createAsyncThunk(
  "contacts/deleteGalaxy",
  async ({ galaxyId }: { galaxyId: any } & IAppLoaderAction) => {
    let response;

    console.log("deleteGalaxy galaxyId", galaxyId);

    await axios
      .delete(`${apiUrls.galaxies.update}/${galaxyId}`)
      .then((res: any) => {
        console.log("deleteGalaxy res", res);
        console.log("deleteGalaxy res.data", res.data);

        if (res.status === 200) {
          console.log("deleteGalaxy res.status === 200");
          response = { id: galaxyId };
        }
      })
      .catch((e: any) => {
        console.error("deleteGalaxy e:", e);
        return e;
      });

    console.log("deleteGalaxy response:", response);

    return response;
  }
);

export const getMessageTemplates = createAsyncThunk(
  "contacts/getMessageTemplates",
  async (componentId: IAppLoaderAction) => {
    let response;

    console.log("apiUrls.messageTemplates.get", apiUrls.messageTemplates.get);

    await axios
      .get(`${apiUrls.messageTemplates.get}`)
      .then((res: any) => {
        console.log("res.data", res.data);
        response = res.data;
      })
      .catch((e: any) => {
        console.error(e);
        return e;
      });

    return response;
  }
);

export const getSpecificMessageTemplate = createAsyncThunk(
  "contacts/getSpecificMessageTemplate",
  async ({
    messageTemplateId,
  }: { messageTemplateId: any } & IAppLoaderAction) => {
    let response;
    await axios
      .get(`${apiUrls.messageTemplates.get}/${messageTemplateId}`)
      .then((res: any) => {
        console.log("res.data", res.data);
        response = res.data;
      })
      .catch((e: any) => {
        console.error(e);
        return e;
      });

    return response;
  }
);

export const createNewMessageTemplate = createAsyncThunk(
  "contacts/createNewMessageTemplate",
  async ({ data }: { data: any } & IAppLoaderAction) => {
    let response;
    await axios
      .post(`${apiUrls.messageTemplates.create}`, data)
      .then((res: any) => {
        console.log("test res", res);
        console.log("test res.data", res.data);
        console.log("test res.payload", res.payload);

        response = res.data;
      })
      .catch((e: any) => {
        console.error(e);
        return e;
      });

    return response;
  }
);

export const updateMessageTemplate = createAsyncThunk(
  "contacts/updateMessageTemplate",
  async ({
    data,
    messageTemplateId,
  }: { data: any; messageTemplateId: any } & IAppLoaderAction) => {
    let response;

    console.log("test updateMessageTemplate data", data);
    await axios
      .patch(`${apiUrls.messageTemplates.update}/${messageTemplateId}`, data)
      .then((res: any) => {
        console.log("test updateMessageTemplate res.data", res.data);

        response = res.data;
      })
      .catch((e: any) => {
        console.error("test updateMessageTemplate e:", e);
        return e;
      });

    console.log("test updateMessageTemplate response:", response);

    return response;
  }
);

export const deleteMessageTemplate = createAsyncThunk(
  "contacts/deleteMessageTemplate",
  async ({
    messageTemplateId,
  }: { messageTemplateId: any } & IAppLoaderAction) => {
    let response;

    console.log(
      "test deleteMessageTemplate messageTemplateId",
      messageTemplateId
    );

    await axios
      .delete(`${apiUrls.messageTemplates.update}/${messageTemplateId}`)
      .then((res: any) => {
        console.log("test deleteMessageTemplate res.data", res.data);

        if (res.status === 200) {
          console.log("test deleteMessageTemplate res.status === 200");
          response = { id: messageTemplateId };
        }
      })
      .catch((e: any) => {
        console.error("test deleteMessageTemplate e:", e);
        return e;
      });

    console.log("test deleteMessageTemplate response:", response);

    return response;
  }
);

export const importContacts = createAsyncThunk(
  "contacts/importContacts",
  async ({ data }: { data: any } & IAppLoaderAction) => {
    let response;

    console.log("test importContacts data", data);
    await axios
      .post(`${apiUrls.contacts.import}`, data)
      .then((res: any) => {
        console.log("test res", res);
        console.log("test res.data", res.data);
        console.log("test res.payload", res.payload);

        response = res.data;
      })
      .catch((e: any) => {
        console.error(e);
        return e;
      });

    return response;
  }
);

export const generateMessage = createAsyncThunk(
  "contacts/generateMessage",
  async ({ data }: { data: any } & IAppLoaderAction) => {
    let response;

    console.log("test generateMessage data", data);
    console.log("test apiUrls.generateMessage", apiUrls.generateMessage);
    console.log(
      "test https://xbd3-yxc7-ibfu.n7c.xano.io/api:PfsuqTWw/generatemessage"
    );
    await axios
      .post(`${apiUrls.generateMessage}`, data)
      .then((res: any) => {
        console.log("test res", res);
        console.log("test res.data", res.data);
        console.log("test res.payload", res.payload);

        response = res.data;
      })
      .catch((e: any) => {
        console.error(e);
        return e;
      });

    return response;
  }
);

export const getSpecificContact = createAsyncThunk(
  "automations/getSpecificContact",
  async ({ contactId }: { contactId: number } & IAppLoaderAction) => {
    let response;
    await axios
      .get(`${apiUrls.contacts.get}/${contactId}`)
      .then((res: any) => {
        console.log("res.data", res.data);
        response = res.data;
      })
      .catch((e: any) => {
        console.error(e);
        return e;
      });

    return response;
  }
);

export const createNewContact = createAsyncThunk(
  "contact/createNewContact",
  async ({ data }: { data: any } & IAppLoaderAction) => {
    let response;

    console.log("createNewContact data", data);
    await axios
      .post(`${apiUrls.contacts.create}`, data)
      .then((res: any) => {
        response = res.data;
      })
      .catch((e: any) => {
        console.error(e);
        return e;
      });

    console.log("createNewContact response", response);

    return response;
  }
);

export const updateContact = createAsyncThunk(
  "contact/updateContact",
  async ({
    data,
    contactId,
  }: { data: any; contactId: any } & IAppLoaderAction) => {
    let response;
    console.log("updateContact data", data);

    console.log("updateContact contactId", contactId);
    await axios
      .patch(`${apiUrls.contacts.update}/${contactId}`, data)
      .then((res: any) => {
        console.log("updateContact res.data", res.data);

        response = res.data;
      })
      .catch((e: any) => {
        console.error("updateContact e:", e);
        return e;
      });

    console.log("updateContact response:", response);

    return response;
  }
);

export const getMyProject = createAsyncThunk(
  "contact/getMyProject",
  async (componentId: IAppLoaderAction) => {
    let response;
    await axios
      .get(`${apiUrls.projects.get}`)
      .then((res: any) => {
        console.log("res.data", res.data);
        response = res.data;
      })
      .catch((e: any) => {
        console.error(e);
        return e;
      });

    return response;
  }
);

export const updateProject = createAsyncThunk(
  "contact/updateProject",
  async ({ data }: { data: any } & IAppLoaderAction) => {
    let response;

    console.log("updateProject data", data);
    await axios
      .put(`${apiUrls.projects.update}`, data)
      .then((res: any) => {
        console.log("updateProject res.data", res.data);

        response = res.data;
      })
      .catch((e: any) => {
        console.error("updateProject e:", e);
        return e;
      });

    console.log("updateProject response:", response);

    return response;
  }
);

export const searchForExistedContact = createAsyncThunk(
  "contact/searchForExistedContact",
  async ({
    email,
    url,
    freetext,
  }: {
    email?: string;
    url?: string;
    freetext?: string;
  } & IAppLoaderAction) => {
    let queryParams = "";

    // Add email to query params if it exists
    if (email) {
      queryParams += `email=${email}`;
    }

    // Add URL to query params if it exists
    if (url) {
      queryParams += email ? `&url=${url}` : `url=${url}`;
    }

    if (freetext) {
      queryParams +=
        email || url ? `&freetext=${freetext}` : `freetext=${freetext}`;
    }

    let response;

    const fullUrl = `${apiUrls.contacts.search}?${queryParams}`;
    console.log("Full URL for search:", fullUrl);

    await axios
      .get(fullUrl)
      .then((res: any) => {
        console.log("res.data", res.data);
        response = res.data;
      })
      .catch((e: any) => {
        console.error(e);
        return e;
      });

    console.log("response", response);

    return response;
  }
);

export const getAllTaskQueues = createAsyncThunk(
  "contacts/getAllTaskQueues",
  async (componentId: IAppLoaderAction) => {
    let response;

    console.log(
      "apiUrls.taskqueue.getTaskQueues",
      apiUrls.taskqueue.getTaskQueues
    );

    await axios
      .get(`${apiUrls.taskqueue.getTaskQueues}`)
      .then((res: any) => {
        console.log("res.data", res.data);
        response = res.data;
      })
      .catch((e: any) => {
        console.error(e);
        return e;
      });

    return response;
  }
);

export const createNewTaskQueue = createAsyncThunk(
  "contacts/createNewTaskQueue",
  async ({ data }: { data: any } & IAppLoaderAction) => {
    let response;

    console.log("createNewTaskQueue data", data);
    await axios
      .post(`${apiUrls.taskqueue.getTaskQueues}`, data)
      .then((res: any) => {
        response = res.data;
      })
      .catch((e: any) => {
        console.error(e);
        return e;
      });

    return response;
  }
);

export const updateTaskQueues = createAsyncThunk(
  "contacts/updateTaskQueues",
  async ({
    data,
    taskqueueId,
  }: { data: any; taskqueueId: any } & IAppLoaderAction) => {
    let response;

    console.log("updateTaskqueue data", data);
    await axios
      .patch(`${apiUrls.taskqueue.update}/${taskqueueId}`, data)
      .then((res: any) => {
        console.log("updateTaskqueue res.data", res.data);

        response = res.data;
      })
      .catch((e: any) => {
        console.error("updateTaskqueue e:", e);
        return e;
      });

    console.log("updateTaskqueue response:", response);

    console.log("updateTaskqueue id:", (response as any).id);

    return response;
  }
);

export const deleteTaskQueue = createAsyncThunk(
  "contacts/deleteTaskQueue",
  async ({ taskqueueId }: { taskqueueId: any } & IAppLoaderAction) => {
    let response;

    console.log("deleteTaskQueue taskqueueId", taskqueueId);

    await axios
      .delete(`${apiUrls.taskqueue.update}/${taskqueueId}`)
      .then((res: any) => {
        console.log("deleteTaskQueue res.data", res.data);

        if (res.status === 200) {
          response = { id: taskqueueId };
        }
      })
      .catch((e: any) => {
        console.error("deleteTaskQueue e:", e);
        return e;
      });

    console.log("deleteTaskQueue response:", response);

    return response;
  }
);

export const getSpecificTask = createAsyncThunk(
  "contacts/getSpecificTask",
  async ({ taskId }: { taskId: number } & IAppLoaderAction) => {
    let response;
    await axios
      .get(`${apiUrls.tasks.get}/${taskId}`)
      .then((res: any) => {
        console.log("res.data", res.data);
        response = res.data;
      })
      .catch((e: any) => {
        console.error(e);
        return e;
      });

    return response;
  }
);

export const createNewTask = createAsyncThunk(
  "contacts/createNewTask",
  async ({ data }: { data: any } & IAppLoaderAction) => {
    let response;

    console.log("test data", data);

    console.log("test createNewTask data", data);
    await axios
      .post(`${apiUrls.tasks.create}`, data)
      .then((res: any) => {
        response = res.data;
      })
      .catch((e: any) => {
        console.error(e);
        return e;
      });

    return response;
  }
);

export const updateTask = createAsyncThunk(
  "contacts/updateTask",
  async ({ data, taskId }: { data: any; taskId: any } & IAppLoaderAction) => {
    let response;

    console.log("data", data);

    console.log("updateTask data", data);
    await axios
      .patch(`${apiUrls.tasks.update}/${taskId}`, data)
      .then((res: any) => {
        console.log("updateTask res.data", res.data);

        response = res.data;
      })
      .catch((e: any) => {
        console.error("updateTask e:", e);
        return e;
      });

    console.log("updateTask response:", response);

    return response;
  }
);

export const deleteTask = createAsyncThunk(
  "contacts/deleteTask",
  async ({ taskId }: { taskId: any } & IAppLoaderAction) => {
    let response;

    console.log("deleteTask taskId", taskId);

    await axios
      .delete(`${apiUrls.tasks.update}/${taskId}`)
      .then((res: any) => {
        console.log("deleteTask res.data", res.data);

        response = res.data;
      })
      .catch((e: any) => {
        console.error("deleteTask e:", e);
        return e;
      });

    console.log("deleteTask response:", response);

    return response;
  }
);

// Deep Copy Function
function deepCopy(obj: any): any {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  if (obj instanceof Array) {
    const copy = [];
    for (let i = 0; i < obj.length; i++) {
      copy[i] = deepCopy(obj[i]);
    }
    return copy;
  }

  if (obj instanceof Object) {
    const copy: any = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        copy[key] = deepCopy(obj[key]);
      }
    }
    return copy;
  }

  throw new Error("Unable to copy object! Its type is not supported.");
}

// Deep Comparison Function
function deepEqual(obj1: any, obj2: any) {
  if (obj1 === obj2) return true;
  if (
    typeof obj1 !== "object" ||
    typeof obj2 !== "object" ||
    obj1 == null ||
    obj2 == null
  )
    return false;

  let keys1 = Object.keys(obj1);
  let keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) return false;
  }

  return true;
}

const initialState: IContacts = {
  contacts: [],
  specificContact: {},
  tasks: [],
  taskQueues: [],
  messageTemplates: [],
  myProject: {},
};

export const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    clearAllContactsStates: (state: IContacts) => {
      state.contacts = [];
    },
    clearAllStates: (state: IContacts) => {
      state.contacts = [];
      state.specificContact = {};
      state.tasks = [];
      state.specificTask = {};
      state.taskQueues = [];
    },
    clearSpecificContact: (state: IContacts) => {
      state.specificContact = {};
    },
    updateFullListOfContacts: (state: IContacts, { payload }) => {
      if (state.contacts?.findIndex((x) => x.id == payload.id) !== undefined) {
        state.contacts[
          state.contacts?.findIndex((x) => x.id == payload.id) as any
        ] = payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAllContacts.fulfilled,
      (state: IContacts, { payload }: any) => {
        state.contacts = payload;
      }
    );
    builder.addCase(
      getSpecificContact.fulfilled,
      (state: IContacts, { payload }) => {
        state.specificContact = payload;
      }
    );
    builder.addCase(getGalaxies.fulfilled, (state: IContacts, { payload }) => {
      state.galaxies = payload;
    });
    builder.addCase(
      createNewGalaxy.fulfilled,
      (state: IContacts, { payload }: any) => {
        const newGalaxies = state.galaxies || [];

        // if payload doesnt havekey taskqueues, add it
        if (!payload.taskqueues) {
          payload.taskqueues = [];
        }

        newGalaxies.push(payload);

        state.galaxies = newGalaxies;
      }
    );
    builder.addCase(
      updateGalaxy.fulfilled,
      (state: IContacts, { payload }: any) => {
        //find the index of the galaxy to be updated
        const galaxyIndex = (state.galaxies as any).findIndex(
          (galaxy: any) => galaxy.id === payload.id
        );

        console.log("galaxyIndex", galaxyIndex);

        //update the galaxy in the state
        if (galaxyIndex !== -1) {
          const currentGalaxyTaskQueues = (state.galaxies as any)[galaxyIndex]
            .taskqueues;

          // if the payload doesnt have taskqueues, add it
          if (!payload.taskqueues) {
            payload.taskqueues = currentGalaxyTaskQueues;
          }

          (state.galaxies as any)[galaxyIndex] = payload;
        }
      }
    );
    builder.addCase(
      deleteGalaxy.fulfilled,
      (state: IContacts, { payload }: any) => {
        const newGalaxies = state.galaxies?.filter(
          (galaxy) => galaxy.id !== payload.id
        );

        state.galaxies = newGalaxies;
      }
    );
    builder.addCase(
      createNewContact.fulfilled,
      (state: IContacts, { payload }) => {
        const oldContacts = state.contacts || [];
        console.log("test payload", payload);
        console.log("test oldContacts", oldContacts);
        oldContacts.push(payload);
        console.log("test newContacts", oldContacts);

        state.contacts = oldContacts;
      }
    );
    builder.addCase(
      updateContact.fulfilled,
      (state: IContacts, { payload }: any) => {
        // Merge the existing state with the payload
        // This ensures all existing keys are retained and updated if present in payload
        state.specificContact = { ...state.specificContact, ...payload };

        // Additionally, update the contact in the contacts array if it exists
        const contactIndex = (state.contacts || []).findIndex(
          (contact) => contact.id === payload.id
        );
        if (contactIndex !== -1 && state.contacts) {
          state.contacts[contactIndex] = {
            ...state.contacts[contactIndex],
            ...payload,
          };
        }
      }
    );
    builder.addCase(getMyProject.fulfilled, (state: IContacts, { payload }) => {
      state.myProject = payload;
    });
    builder.addCase(
      updateProject.fulfilled,
      (state: IContacts, { payload }: any) => {
        state.myProject = { ...state.myProject, ...payload };
      }
    );
    builder.addCase(
      searchForExistedContact.fulfilled,
      (state: IContacts, { payload }) => {
        state.contacts = payload;
      }
    );
    builder.addCase(getAllTasks.fulfilled, (state: IContacts, { payload }) => {
      state.tasks = payload;
    });
    builder.addCase(
      getAllTaskQueues.fulfilled,
      (state: IContacts, { payload }) => {
        state.taskQueues = payload;
      }
    );
    builder.addCase(
      updateTaskQueues.fulfilled,
      (state: IContacts, { payload }: any) => {
        // Find the index of the task queue to be updated

        console.log("test payload", payload);

        const taskQueueIndex = (state.taskQueues as any).findIndex(
          (taskQueue: any) => taskQueue.id === payload.id
        );

        console.log("test taskQueueIndex", taskQueueIndex);

        if (taskQueueIndex !== -1) {
          // Get the current task queue from the state
          const currentTaskQueue = (state.taskQueues as any)[taskQueueIndex];

          const currentCopy = deepCopy(currentTaskQueue);
          const updatedCopy = deepCopy(payload);

          delete currentCopy.wheelposition;
          delete updatedCopy.wheelposition;

          console.log("test currentCopy", currentCopy);
          console.log("test updatedCopy", updatedCopy);
          console.log(
            "test JSON.stringify(currentCopy) !== JSON.stringify(updatedCopy)",
            JSON.stringify(currentCopy) !== JSON.stringify(updatedCopy)
          );
          console.log(
            "test !deepEqual(currentCopy, updatedCopy)",
            !deepEqual(currentCopy, updatedCopy)
          );

          // Compare the current task queue (without wheelposition) with the payload (without wheelposition)
          if (!deepEqual(currentCopy, updatedCopy)) {
            console.log("test here");
            // If they are different, update the task queue in the state
            (state.taskQueues as any)[taskQueueIndex] = payload;
          }
          // If only the wheelposition has changed, do not update the task queue in the state
        }

        if (state.galaxies) {
          console.log("test state.galaxies 1", state.galaxies);

          // Iterate over galaxies to find the one containing the updated task queue
          state.galaxies.forEach((galaxy: any) => {
            const taskQueueIndex = galaxy.taskqueues.findIndex(
              (taskQueue: any) => taskQueue.id === payload.id
            );

            if (taskQueueIndex !== -1) {
              if (
                !payload.galaxies_ids ||
                !payload.galaxies_ids.includes(galaxy.id)
              ) {
                // Remove the task queue from this galaxy as it's no longer associated
                galaxy.taskqueues.splice(taskQueueIndex, 1);
              } else {
                // Get the current task queue from the state
                const currentGalaxyTaskQueue =
                  galaxy.taskqueues[taskQueueIndex];

                const currentCopy = deepCopy(currentGalaxyTaskQueue);
                const updatedCopy = deepCopy(payload);

                delete currentCopy.wheelposition;
                delete updatedCopy.wheelposition;

                console.log("test currentCopy", currentCopy);
                console.log("test updatedCopy", updatedCopy);
                console.log(
                  "test !deepEqual(currentCopy, updatedCopy)",
                  !deepEqual(currentCopy, updatedCopy)
                );

                // Compare the current task queue (without wheelposition) with the payload (without wheelposition)
                if (!deepEqual(currentCopy, updatedCopy)) {
                  console.log("test here 2");
                  // Update the task queue within the galaxy
                  galaxy.taskqueues[taskQueueIndex] = payload;
                }
              }
            }
          });
        }
      }
    );
    builder.addCase(
      deleteTaskQueue.fulfilled,
      (state: IContacts, { payload }: any) => {
        const newTaskQueues = state.taskQueues?.filter(
          (taskQueue) => taskQueue.id !== payload.id
        );

        state.taskQueues = newTaskQueues;

        // Update galaxies to remove the deleted task queue
        if (state.galaxies) {
          state.galaxies.forEach((galaxy) => {
            // Filter out the deleted task queue from each galaxy's taskqueues
            galaxy.taskqueues = galaxy.taskqueues.filter(
              (taskQueue: any) => taskQueue.id !== payload.id
            );
          });
        }
      }
    );
    builder.addCase(
      getMessageTemplates.fulfilled,
      (state: IContacts, { payload }) => {
        state.messageTemplates = payload;
      }
    );
    builder.addCase(
      createNewMessageTemplate.fulfilled,
      (state: IContacts, { payload }) => {
        const newMessageTemplates = state.messageTemplates || [];

        newMessageTemplates.push(payload);

        state.messageTemplates = newMessageTemplates;
      }
    );
    builder.addCase(
      updateMessageTemplate.fulfilled,
      (state: IContacts, { payload }: any) => {
        const messageTemplateIndex = (state.messageTemplates || []).findIndex(
          (messageTemplate) => messageTemplate.id === payload.id
        );
        if (messageTemplateIndex !== -1 && state.messageTemplates) {
          state.messageTemplates[messageTemplateIndex] = {
            ...state.messageTemplates[messageTemplateIndex],
            ...payload,
          };
        }
      }
    );
    builder.addCase(
      deleteMessageTemplate.fulfilled,
      (state: IContacts, { payload }: any) => {
        const newMessageTemplates = state.messageTemplates?.filter(
          (messageTemplate) => messageTemplate.id !== payload.id
        );

        state.messageTemplates = newMessageTemplates;
      }
    );
    builder.addCase(
      createNewTaskQueue.fulfilled,
      (state: IContacts, { payload }: any) => {
        const newTaskQueues = state.taskQueues || [];

        newTaskQueues.push(payload);

        state.taskQueues = newTaskQueues;

        console.log("test payload", payload);

        // Update galaxies with the new task queue
        if (state.galaxies) {
          // Iterate over each galaxy in the state
          state.galaxies.forEach((galaxy) => {
            // Check if the current galaxy's ID is in the payload's galaxies_ids array
            if (payload.galaxies_ids.includes(galaxy.id)) {
              // Ensure that the galaxy has a taskqueues array
              if (!galaxy.taskqueues) {
                galaxy.taskqueues = [];
              }
              // Add the new task queue to this galaxy's taskqueues
              galaxy.taskqueues.push(payload);
            }
          });
        }
      }
    );
    builder.addCase(
      getSpecificTask.fulfilled,
      (state: IContacts, { payload }) => {
        state.specificTask = payload;
      }
    );
    builder.addCase(
      createNewTask.fulfilled,
      (state: IContacts, { payload }) => {
        const response: any = payload;

        if (state.specificContact) {
          // Make a copy of the current tasks
          const updatedTasks = state.specificContact.tasks
            ? Array.from(state.specificContact.tasks)
            : [];

          // Add the new task to the copied tasks array
          updatedTasks.push(response.task);

          // Replace the entire tasks array with the new one
          state.specificContact = {
            ...response.contact,
            tasks: updatedTasks,
          };

          // Debugging: Log the updated tasks array
          console.log("Updated tasks array:", updatedTasks);
        }

        //if contact already exists in the state, update it if not add it
        if (state.contacts) {
          state.contacts = state.contacts.map((contact) => {
            if (contact.id === response.contact.id) {
              return response.contact;
            } else {
              return contact;
            }
          });
        } else {
          state.contacts = [response.contact];
        }

        //remove tasks from response.contact
        const editedContact = {
          ...response.contact,
          tasks: [],
        };

        const newTask = {
          ...response.task,
          _contacts: editedContact,
        };

        if (state.tasks) {
          console.log("state.tasks", state.tasks);
          console.log("response", response);
          console.log("response.task", response.task);

          const updatedTasks = state.tasks ? Array.from(state.tasks) : [];
          console.log("updatedTasks", updatedTasks);

          updatedTasks.push(newTask);
          console.log("updatedTasks", updatedTasks);

          state.tasks = updatedTasks;
        } else {
          state.tasks = [newTask];
        }
      }
    );
    builder.addCase(updateTask.fulfilled, (state: IContacts, { payload }) => {
      const response: any = payload;

      console.log("response = payload updateTask", response);

      if (state.specificContact) {
        // Make a copy of the current tasks

        const updatedTasks = state.specificContact.tasks.map((task: any) => {
          if (task.id === response.task.id) {
            return response.task;
          } else {
            return task;
          }
        });

        // Replace the entire tasks array with the new one
        state.specificContact = {
          ...response.contact,
          tasks: updatedTasks,
        };

        // Debugging: Log the updated tasks array
        console.log("Updated tasks array:", updatedTasks);
      }

      //if contact already exists in the state, update it if not add it
      if (state.contacts) {
        state.contacts = state.contacts.map((contact) => {
          if (contact.id === response.contact.id) {
            return response.contact;
          } else {
            return contact;
          }
        });
      } else {
        state.contacts = [response.contact];
      }

      const newTask = {
        ...response.task,
        _contacts: response.contact,
      };

      if (state.tasks) {
        console.log("state.tasks", state.tasks);
        console.log("response", response);
        console.log("response.task", response.task);

        const updatedTasks = state.tasks.map((task: any) => {
          if (task.id === response.task.id) {
            return response.task;
          } else {
            return task;
          }
        });
        console.log("updatedTasks", updatedTasks);

        state.tasks = updatedTasks;
      } else {
        state.tasks = [newTask];
      }
    });
    builder.addCase(deleteTask.fulfilled, (state: IContacts, { payload }) => {
      const response: any = payload;

      console.log("response = payload deleteTask", response);

      if (state.specificContact) {
        // Make a copy of the current tasks

        const updatedTasks = state.specificContact.tasks.filter(
          (task: any) => task.id !== response.task.id
        );

        // Replace the entire tasks array with the new one
        state.specificContact = {
          ...response.contact,
          tasks: updatedTasks,
        };

        // Debugging: Log the updated tasks array
        console.log("Updated tasks array:", updatedTasks);
      }

      //if contact already exists in the state, update it if not add it
      if (state.contacts) {
        state.contacts = state.contacts.map((contact) => {
          if (contact.id === response.contact.id) {
            return response.contact;
          } else {
            return contact;
          }
        });
      } else {
        state.contacts = [response.contact];
      }

      const newTask = {
        ...response.task,
        _contacts: response.contact,
      };

      if (state.tasks) {
        console.log("state.tasks", state.tasks);
        console.log("response", response);
        console.log("response.task", response.task);

        const updatedTasks = state.tasks.filter(
          (task: any) => task.id !== response.task.id
        );
        console.log("updatedTasks", updatedTasks);

        state.tasks = updatedTasks;
      } else {
        state.tasks = [newTask];
      }
    });
  },
});

export const contactsReducer = contactsSlice.reducer;

export const getCurrentContacts = (state: RootState) => state.contacts.contacts;
export const getCurrentGalaxies = (state: RootState) => state.contacts.galaxies;
export const getCurrentSpecificContact = (state: RootState) =>
  state.contacts.specificContact;
export const getCurrentMessageTemplates = (state: RootState) =>
  state.contacts.messageTemplates;
export const getCurrentTasks = (state: RootState) => state.contacts.tasks;
export const getCurrentSpecificTask = (state: RootState) =>
  state.contacts.specificTask;
export const getCurrentTaskQueues = (state: RootState) =>
  state.contacts.taskQueues;

export const { clearAllStates, clearAllContactsStates } = contactsSlice.actions;
