import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, useFormState } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import React from "react";
import logo from "../../../assets/logo.png";
import { AppDispatch, RootState } from "../../../common/state/store";
import { errorSelector } from "../../../common/state/slice/appStateSlice";
import { jwtSelector } from "../../../common/state/selectors/authSelector";
import { IsLoading } from "../../../common/localComponents/AppState/IsLoading";
import { Input, InputIcon } from "../../../common/localComponents/Input";
export const LOGIN_COMPONENT_ID = "loginComponent";
import Icon, { IconName } from "../../../common/localComponents/Icon/Icon";
import { SettingsSidebarItem } from "./settingsSidebarItem";
import { use } from "i18next";
import {
  deleteTaskQueue,
  getCurrentMessageTemplates,
  getMessageTemplates,
  importContacts,
  updateTaskQueues,
} from "../../../features/contacts/state/contactsSlice";
import { Transition } from "@headlessui/react";
import {
  FaCaretDown,
  FaCaretUp,
  FaMinus,
  FaPencilAlt,
  FaPlus,
  FaPlusCircle,
  FaRegArrowAltCircleDown,
  FaRegArrowAltCircleUp,
  FaTrash,
} from "react-icons/fa";
import { TaskqueueToEditForm } from "./TaskqueueToEditForm";
import {
  closeStandardModal,
  openStandardModal,
} from "../../../common/state/slice/modal/modalSlice";
import NewTaskqueuePopup from "../../../common/localComponents/Modal/forTaskqueue/NewTaskqueuePopup";
import { set } from "lodash";
import EditTaskqueuePopup from "../../../common/localComponents/Modal/forTaskqueue/EditTaskqueuePopup";
import { ModalConfirmation } from "../../../common/localComponents/Modal";
import NewMessageTemplatePopup from "../../../common/localComponents/Modal/forMessageTemplates/NewMessageTemplatesPopup";
import EditMessageTemplatePopup from "../../../common/localComponents/Modal/forMessageTemplates/EditMessageTemplatesPopup";
export const IMPORTCONTACTS_COMPONENT_ID = "importContactsComponent";

type SettingsSideBarLinkProps = {
  title: string;
  iconName: IconName;
  onClick(): void;
};

const ImportContacts = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const {
    control,
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    dispatch(
      importContacts({ data: data, componentId: IMPORTCONTACTS_COMPONENT_ID })
    );
  };

  return (
    <div className="mb-24">
      <div className="flex w-full mb-4">
        <h3 className="flex-1 text-4xl font-semibold text-start ">
          {t("pages.settings.importContacts.title")}
        </h3>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="border-t-2 py-4 mt-2">
          <div className="flex flex-col w-full">
            <div className="flex w-full mb-4">
              <Input
                registerFct={() =>
                  register("spreadsheeturl", {
                    required: true,
                  })
                }
                type="text"
                label={t("pages.settings.importContacts.spreadsheeturl")}
                placeholder={t(
                  "pages.settings.importContacts.spreadsheeturlPlaceholder"
                )}
                className={`w-full ${errors.name ? "border-red-500" : ""}`}
              />
            </div>
            <div className="flex flex-1 justify-end my-auto">
              <button
                className="btn-primary-fill text-white bg-blue-500 hover:bg-blue-600 focus-visible:ring-blue-500"
                type="submit"
              >
                {t("pages.settings.importContacts.import")}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ImportContacts;
