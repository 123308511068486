import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { IconName } from "../Icon/Icon";
import { Link, useNavigate } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { TabsBarItem } from "./TabsbarItem";
import { changeTab } from "../../../features/tab/tabStateSlice";

type BottomBarLinkProps = {
  title: string;
  iconName: IconName;
  routePath: string;
};

type BottomBarDropdownLinkProps = {
  title: string;
  routePath: string;
};

const TabsBar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef: any = useRef(null);
  const navigate = useNavigate();

  // Function to handle outside click for closing dropdown
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const bottombarLinks: BottomBarLinkProps[] = [
    {
      title: t("tabsbar.contactInfo"),
      iconName: "UserIcon",
      routePath: "contactInfo",
    },
    {
      title: t("tabsbar.moreInfo"),
      iconName: "InformationCircleIcon",
      routePath: "moreInfo",
    },
    // { title: t("bottombar.dashboard"), iconName: "HomeIcon", routePath: "./" },
  ];

  const bottombarDropdownLinks: BottomBarDropdownLinkProps[] = [
    // { title: t("bottombar.profile"), routePath: "./" },
    // { title: t("bottombar.settings"), routePath: "./" },
  ];

  const handleChangeTab = (routePath: any) => {
    dispatch(changeTab(routePath)); // Dispatch the changeTab action
  };

  return (
    <div className="my-4 flex items-center justify-center w-full h-16 border-y-2 bg-white z-40">
      <div className="flex grow justify-start items-center">
        {bottombarLinks.map((link, i) => (
          <TabsBarItem
            key={i}
            title={link.title}
            icon={link.iconName}
            onClick={() => handleChangeTab(link.routePath)} // Add onClick handler
          />
        ))}
      </div>
    </div>
  );
};

export default TabsBar;
