import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, useFormState } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import React from "react";
import logo from "../../../assets/logo.png";
import { AppDispatch, RootState } from "../../../common/state/store";
import { errorSelector } from "../../../common/state/slice/appStateSlice";
import { jwtSelector } from "../../../common/state/selectors/authSelector";
import { IsLoading } from "../../../common/localComponents/AppState/IsLoading";
import { Input, InputIcon } from "../../../common/localComponents/Input";
export const LOGIN_COMPONENT_ID = "loginComponent";
import Icon, { IconName } from "../../../common/localComponents/Icon/Icon";
import { SettingsSidebarItem } from "./settingsSidebarItem";
import { use } from "i18next";
import {
  deleteGalaxy,
  deleteTaskQueue,
  getCurrentGalaxies,
  getGalaxies,
  updateTaskQueues,
} from "../../../features/contacts/state/contactsSlice";
import { Transition } from "@headlessui/react";
import {
  FaCaretDown,
  FaCaretUp,
  FaMinus,
  FaPencilAlt,
  FaPlus,
  FaPlusCircle,
  FaRegArrowAltCircleDown,
  FaRegArrowAltCircleUp,
  FaTrash,
} from "react-icons/fa";
import { TaskqueueToEditForm } from "./TaskqueueToEditForm";
import NewGalaxyPopup from "../../../common/localComponents/Modal/forGalaxy/NewGalaxyPopup";
import {
  closeStandardModal,
  openStandardModal,
} from "../../../common/state/slice/modal/modalSlice";
import NewTaskqueuePopup from "../../../common/localComponents/Modal/forTaskqueue/NewTaskqueuePopup";
import { set } from "lodash";
import EditTaskqueuePopup from "../../../common/localComponents/Modal/forTaskqueue/EditTaskqueuePopup";
import { ModalConfirmation } from "../../../common/localComponents/Modal";
import EditGalaxyPopup from "../../../common/localComponents/Modal/forGalaxy/EditGalaxyPopup";

export const GALAXIES_COMPONENT_ID = "galaxiesComponent";

type SettingsSideBarLinkProps = {
  title: string;
  iconName: IconName;
  onClick(): void;
};

const GalaxyConfiguration = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const { galaxies } = useSelector((state: RootState) => {
    return {
      galaxies: getCurrentGalaxies(state),
    };
  });

  const {
    control: galaxyControl,
    handleSubmit: galaxyHandleSubmit,
    register: galaxyRegister,
    watch: galaxyWatch,
    reset: galaxyReset,
    formState: { errors: galaxyErrors },
  } = useForm();

  const {
    control: taskqueueToEditControl,
    handleSubmit: taskqueueToEditHandleSubmit,
    register: taskqueueToEditRegister,
    watch: taskqueueToEditWatch,
    reset: taskqueueToEditReset,
    formState: { errors: taskqueueToEditErrors },
  } = useForm();

  const firstSideBarItemTitle = "Galxies Configuration";

  // State to track the current active form/setting
  const [activeSetting, setActiveSetting] = useState(firstSideBarItemTitle);
  const [isNewGalaxyCollapsed, setIsNewGalaxyCollapsed] = useState(true);
  const [taskqueueCollapseStates, setTaskqueueCollapseStates] = useState<
    Record<number, boolean>
  >({});

  // State to track collapse status of galaxies
  const [galaxyCollapseStates, setGalaxyCollapseStates] = useState<
    Record<number, boolean>
  >({});

  const [chosenGalaxy, setChosenGalaxy] = useState({ id: 0, name: "" });
  const [chosenTaskqueue, setChosenTaskqueue] = useState({ id: 0, name: "" });

  const toggleTaskqueueCollapse = (taskId: number) => {
    setTaskqueueCollapseStates((prevState) => ({
      ...prevState,
      [taskId]: !prevState[taskId],
    }));
  };

  const toggleGalaxyCollapse = (galaxyId: number) => {
    setGalaxyCollapseStates((prevState) => ({
      ...prevState,
      [galaxyId]: !prevState[galaxyId],
    }));
  };

  const onGalaxySubmit = (data: any) => {};

  const handleRemoveTaskqueue = (galaxy: any, taskqueue: any) => {
    // Check if taskqueue exists in multiple galaxies
    const isTaskQueueInMultipleGalaxies = taskqueue.galaxies_ids.length > 1;

    if (isTaskQueueInMultipleGalaxies) {
      // Remove galaxyId from the taskqueue's galaxies_ids
      const updatedGalaxiesIds = taskqueue.galaxies_ids.filter(
        (id: any) => id !== galaxy.id
      );

      // Dispatch updateTaskQueues with the updated galaxy ids
      dispatch(
        updateTaskQueues({
          data: { ...taskqueue, galaxies_ids: updatedGalaxiesIds },
          taskqueueId: taskqueue.id,
          // componentId: GALAXIES_COMPONENT_ID,
        })
      );
    } else {
      // Show confirmation popup for deleting the taskqueue
      setChosenTaskqueue(taskqueue); // Set chosen taskqueue for deletion
      setChosenGalaxy(galaxy); // Set chosen taskqueue for deletion
      dispatch(openStandardModal("removeTheTaksqueue"));
    }
  };

  const sidebarLinks: SettingsSideBarLinkProps[] = [
    {
      title: firstSideBarItemTitle,
      iconName: "WrenchScrewdriverIcon",
      onClick: () => {
        setActiveSetting(firstSideBarItemTitle);
      },
    },
    {
      title: "Other Settings",
      iconName: "WrenchScrewdriverIcon",
      onClick: () => {
        setActiveSetting("Other Settings");
      },
    },
  ];

  useEffect(() => {
    if (activeSetting === firstSideBarItemTitle) {
      dispatch(getGalaxies({ componentId: GALAXIES_COMPONENT_ID }));
    }
  }, []);

  useEffect(() => {
    if (galaxies && galaxies.length > 0) {
      const initialCollapseStates: Record<number, boolean> = {};
      const shouldCollapse = galaxies.length > 3; // Collapse only if more than 3 galaxies

      galaxies.forEach((galaxy: any) => {
        if (chosenGalaxy.id === galaxy.id) {
          initialCollapseStates[galaxy.id] = false; // Set based on the condition
        } else {
          initialCollapseStates[galaxy.id] = shouldCollapse; // Set based on the condition
        }
      });

      setGalaxyCollapseStates(initialCollapseStates);
    }
  }, [galaxies]);

  useEffect(() => {
    if (galaxies && galaxies.length > 0) {
      const localGalaxies: any = galaxies;

      const newCollapseStates: Record<number, boolean> = {};

      localGalaxies.forEach((galaxy: any) => {
        galaxy.taskqueues.forEach((taskqueue: any) => {
          if (chosenTaskqueue.id === taskqueue.id) {
            newCollapseStates[taskqueue.id] = false; // Initialize as collapsed
          } else {
            newCollapseStates[taskqueue.id] = true; // Initialize as collapsed
          }
        });
      });

      setTaskqueueCollapseStates(newCollapseStates);
    }
  }, [galaxies]);

  return (
    <>
      <NewGalaxyPopup id="modalNewGalaxyPopup" />
      <EditGalaxyPopup id="modalEditGalaxyPopup" galaxy={chosenGalaxy} />
      <NewTaskqueuePopup id="modalNewTaskqueuePopup" galaxy={chosenGalaxy} />
      <EditTaskqueuePopup
        id="modalEditTaskqueuePopup"
        galaxy={chosenGalaxy}
        taskqueue={chosenTaskqueue}
      />
      <ModalConfirmation
        id="removeTheTaksqueue"
        onClick={() => {
          dispatch(closeStandardModal("removeTheTaksqueue"));
          dispatch(
            deleteTaskQueue({
              // componentId: GALAXIES_COMPONENT_ID,
              taskqueueId: chosenTaskqueue?.id,
            })
          );
        }}
        title={t("pages.settings.galaxyConfiguration.deleteTaskqueue.title")}
        text={t("pages.settings.galaxyConfiguration.deleteTaskqueue.text")}
        // componentId={GALAXIES_COMPONENT_ID}
      />
      <ul className="mb-24">
        <div className="flex w-full mb-4">
          <h3 className="flex-1 text-4xl font-semibold text-start ">
            My Galaxies:
          </h3>
          <div className="flex flex-1 justify-end my-auto">
            <button
              className="btn-primary-fill text-white bg-blue-500 hover:bg-blue-600 focus-visible:ring-blue-500"
              type="button"
              onClick={(e) => {
                e.preventDefault(); // Prevent default form submission or any other default action
                dispatch(openStandardModal("modalNewGalaxyPopup"));
              }}
            >
              Create New Galaxy
            </button>
          </div>
        </div>
        <IsLoading componentId={GALAXIES_COMPONENT_ID} showSuccess={false}>
          <>
            {galaxies && galaxies.length > 0 && (
              <>
                <form>
                  {[...galaxies]
                    ?.sort((a: any, b: any) => a.order - b.order) // Sort taskqueues by alias
                    .map((galaxy: any) => (
                      <div className="border-t-2 py-4 mt-2">
                        <div className="flex w-full">
                          <div className="flex flex-col flex-1">
                            <h3 className="text-2xl font-semibold text-start">
                              {galaxy.name}
                            </h3>
                            <h2 className="text-lg font-normal text-start mb-4">
                              {galaxy.description}
                            </h2>
                          </div>
                          <div className="flex items-center">
                            {galaxy && galaxy.taskqueues.length !== 0 && (
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  toggleGalaxyCollapse(galaxy.id);
                                }}
                                className="text-gray-500 hover:text-gray-700 mr-4 border-r pr-4"
                              >
                                {galaxyCollapseStates[galaxy.id] ? (
                                  <FaCaretDown size={30} />
                                ) : (
                                  <FaCaretUp size={30} />
                                )}
                              </button>
                            )}
                            <button
                              onClick={(e) => {
                                e.preventDefault(); // Prevent default form submission or any other default action
                                dispatch(
                                  openStandardModal("modalNewTaskqueuePopup")
                                );
                                setChosenGalaxy(galaxy);
                              }}
                              className="text-green-500  hover:text-green-700 mr-2"
                            >
                              <FaPlusCircle size={20} />
                            </button>
                            <button
                              onClick={(e) => {
                                e.preventDefault(); // Prevent default form submission or any other default action
                                // toggleTaskqueueCollapse(taskqueue.id);

                                setChosenGalaxy(galaxy);

                                dispatch(
                                  openStandardModal("modalEditGalaxyPopup")
                                );
                              }}
                              className="text-gray-500  hover:text-gray-700"
                            >
                              <FaPencilAlt size={20} />
                            </button>
                            {galaxy && galaxy.taskqueues.length === 0 && (
                              <button
                                onClick={(e) => {
                                  // setChosenTask(task);
                                  // dispatch(openStandardModal("removeTheTaks"));
                                  e.preventDefault(); // Prevent default form submission or any other default action
                                  dispatch(
                                    deleteGalaxy({
                                      // componentId:
                                      //   GALAXIES_COMPONENT_ID,
                                      galaxyId: galaxy?.id,
                                    })
                                  );
                                }}
                                className="text-red-500 hover:text-red-700 ml-2"
                              >
                                <FaTrash size={20} />
                              </button>
                            )}
                          </div>
                        </div>
                        <Transition
                          as="div"
                          show={
                            Object.keys(taskqueueCollapseStates).length !== 0 &&
                            !galaxyCollapseStates[galaxy.id]
                          }
                          className="transition-all duration-500 overflow-hidden"
                          enterFrom="transform scale-95 opacity-0 max-h-0"
                          enterTo="transform scale-100 opacity-100 max-h-[100%]"
                          leaveFrom="transform scale-100 opacity-100 max-h-[100%]"
                          leaveTo="transform scale-95 opacity-0 max-h-0"
                        >
                          <div className="ml-6">
                            {galaxy?.taskqueues.length !== 0 && (
                              <div className="flex w-full">
                                <h4 className="flex-1 text-xl font-semibold text-start mb-4">
                                  Groups:
                                </h4>
                              </div>
                            )}
                            {[...galaxy?.taskqueues] // Create a shallow copy of the array
                              .sort((a: any, b: any) => a.alias - b.alias) // Sort taskqueues by alias
                              .map((taskqueue: any) => (
                                <>
                                  <li
                                    key={taskqueue.id}
                                    className="border-t py-2 flex justify-between items-center "
                                  >
                                    <div>
                                      {taskqueue.name && (
                                        <div className="font-semibold">
                                          {taskqueue.name}
                                        </div>
                                      )}
                                      <div className="text-sm text-gray-600">
                                        {`Alias: ${taskqueue.alias}`}
                                      </div>
                                    </div>
                                    <div className="flex items-center">
                                      <button
                                        onClick={(e) => {
                                          e.preventDefault(); // Prevent default form submission or any other default action
                                          setChosenTaskqueue(taskqueue);
                                          setChosenGalaxy(galaxy);

                                          dispatch(
                                            openStandardModal(
                                              "modalEditTaskqueuePopup"
                                            )
                                          );
                                        }}
                                        className="text-gray-500 hover:text-gray-700 mr-2"
                                      >
                                        <FaPencilAlt />
                                      </button>
                                      <button
                                        onClick={(e) => {
                                          // setChosenTask(task);
                                          // dispatch(openStandardModal("removeTheTaks"));
                                          e.preventDefault(); // Prevent default form submission or any other default action

                                          handleRemoveTaskqueue(
                                            galaxy,
                                            taskqueue
                                          );
                                        }}
                                        className="text-red-500 hover:text-red-700"
                                      >
                                        <FaTrash />
                                      </button>
                                    </div>
                                  </li>
                                  {/* Edit Task Form */}
                                  {/* <TaskqueueToEditForm
                                              taskqueue={taskqueue}
                                              register={taskqueueToEditRegister}
                                              onSubmit={onTaskqueueToEditSubmit}
                                              onCancel={() => {
                                                toggleTaskqueueCollapse(
                                                  taskqueue.id
                                                );
                                              }}
                                              errors={taskqueueToEditErrors}
                                              show={
                                                Object.keys(
                                                  taskqueueCollapseStates
                                                ).length !== 0 &&
                                                !taskqueueCollapseStates[
                                                  taskqueue.id
                                                ]
                                              }
                                            /> */}
                                </>
                              ))}
                          </div>
                        </Transition>
                      </div>
                    ))}
                </form>
              </>
            )}
          </>
        </IsLoading>
      </ul>
    </>
  );
};

export default GalaxyConfiguration;
