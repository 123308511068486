import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import React from "react";
import { AppDispatch } from "../../common/state/store";
export const LOGIN_COMPONENT_ID = "loginComponent";
import Icon, { IconName } from "../../common/localComponents/Icon/Icon";
import { SettingsSidebarItem } from "./components/settingsSidebarItem";
import GalaxyConfiguration from "./components/galaxyConfiguration";
import MessageTemplates from "./components/messageTemplates";
import ImportContacts from "./components/importContacts";
import MyBusinessInfo from "./components/myBusinessInfo";

export const GALAXIES_COMPONENT_ID = "galaxiesComponent";

type SettingsSideBarLinkProps = {
  title: string;
  iconName: IconName;
  onClick(): void;
};

const Settings = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const firstSideBarItemTitle = "Galxies Configuration";

  // State to track the current active form/setting
  const [activeSetting, setActiveSetting] = useState(firstSideBarItemTitle);

  const sidebarLinks: SettingsSideBarLinkProps[] = [
    {
      title: firstSideBarItemTitle,
      iconName: "WrenchScrewdriverIcon",
      onClick: () => {
        setActiveSetting(firstSideBarItemTitle);
      },
    },
    {
      title: "Message Templates",
      iconName: "WrenchScrewdriverIcon",
      onClick: () => {
        setActiveSetting("Message Templates");
      },
    },
    {
      title: "Import contacts",
      iconName: "WrenchScrewdriverIcon",
      onClick: () => {
        setActiveSetting("Import contacts");
      },
    },
    {
      title: "My Business Info",
      iconName: "WrenchScrewdriverIcon",
      onClick: () => {
        setActiveSetting("My Business Info");
      },
    },
  ];

  const getActiveComponent = () => {
    switch (activeSetting) {
      case firstSideBarItemTitle:
        return <GalaxyConfiguration />;
      case "Message Templates":
        return <MessageTemplates />;
      case "Import contacts":
        return <ImportContacts />;
      case "My Business Info":
        return <MyBusinessInfo />;
      default:
        return <div>Select a setting</div>;
    }
  };

  return (
    <>
      <div className="flex justify-center items-start m-8 overflow-visible">
        <div>
          <div className="flex justify-start items-center mb-8">
            <button
              onClick={() => {
                navigate("../");
              }}
              className="mr-5"
            >
              <Icon icon="ArrowLeftIcon" className="w-6 h-6" />
            </button>
            <h1 className="text-3xl text-black text-start font-bold">
              Settings
            </h1>
          </div>
          <div className="flex justify-center items-start">
            <div className="bg-white rounded-xl shadow-xl border border-gray-200 py-3 px-4  mr-8">
              <div>
                {sidebarLinks.map((link, i) => {
                  return (
                    <SettingsSidebarItem
                      key={i}
                      title={link.title}
                      onClick={link.onClick}
                      icon={link.iconName}
                    />
                  );
                })}
              </div>
            </div>
            <div className="xl:w-[900px] lg:w-[600px] md:w-[400px] h-auto">
              <div className="relative bg-white rounded-xl shadow-xl border border-gray-200 py-7 px-6 min-h-[150px]">
                {getActiveComponent()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
