import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ButtonIcon, ButtonText } from "../../common/localComponents/Button";
import {
  AiOutlineLinkedin,
  AiFillInstagram,
  AiFillFacebook,
} from "react-icons/ai";
import {
  FaPodcast,
  FaYoutube,
  FaLink,
  FaGlobe,
  FaRegCheckSquare,
  FaRegSquare,
} from "react-icons/fa";
import { IsLoading } from "../../common/localComponents/AppState/IsLoading";
import { AppDispatch, RootState } from "../../common/state/store";
import ForceGraph from "../../common/localComponents/ForceGraph/ForceGraph";
import {
  deleteTask,
  getAllContacts,
  getAllTaskQueues,
  getAllTasks,
  getCurrentContacts,
  getCurrentGalaxies,
  getCurrentSpecificContact,
  getCurrentTaskQueues,
  getCurrentTasks,
  getGalaxies,
  getSpecificContact,
  searchForExistedContact,
  updateContact,
  updateTask,
} from "../../features/contacts/state/contactsSlice";
import { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import {
  FaTimes,
  FaUserCircle,
  FaPlus,
  FaTrash,
  FaPencilAlt,
} from "react-icons/fa";
import NewTaskPopup from "../../common/localComponents/Modal/forTask/NewTaskPopup";
import {
  closeStandardModal,
  openStandardModal,
} from "../../common/state/slice/modal/modalSlice";
import { ToggleSwitch } from "../../common/localComponents/ToggleSwitch/ToggleSwitch";
import { UseFormRegisterReturn, useForm } from "react-hook-form";
import { use } from "i18next";
import EditTaskPopup from "../../common/localComponents/Modal/forTask/EditTaskPopup";
import { ModalConfirmation } from "../../common/localComponents/Modal";
import EditContactPopup from "../../common/localComponents/Modal/forContact/EditContactPopup";
import NewContactPopup from "../../common/localComponents/Modal/forContact/NewContactPopup";
import SearchBar from "../../common/localComponents/Input/SearchBar";
import { Dropdown } from "../../common/localComponents/Input";
import { DynamicTextarea } from "../../common/localComponents/Textarea/DynamicTextarea";
import { set } from "lodash";
import {
  getTopBarConfigInLocalStorage,
  storeTopBarConfigInLocalStorage,
} from "../../common/util/localStorage";
import CreateContactPopup from "../../common/localComponents/Modal/forContact/CreateContactPopup";
import DraftMessagePopup from "../../common/localComponents/Modal/forContact/DraftMessagePopup";
import { ContactInfoTab } from "./components/ContactInfoTab";
import { MoreInfoTab } from "./components/MoreInfoTab";
import { selectCurrentTab } from "../../features/tab/tabStateSlice";
import TabsBar from "../../common/localComponents/TabsBar/TabsBar";

export const TASKS_COMPONENT_ID = "tasksComponent";
export const CONTACT_COMPONENT_ID = "contactComponent";
export const REMOVE_THE_TASK_COMPONENT_ID = "removeTheTaskComponent";

const Dashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch: AppDispatch = useDispatch();

  const { contacts, tasks, galaxies, taskQueues, specificContact, currentTab } =
    useSelector((state: RootState) => {
      return {
        contacts: getCurrentContacts(state),
        taskQueues: getCurrentTaskQueues(state),
        tasks: getCurrentTasks(state),
        galaxies: getCurrentGalaxies(state),
        specificContact: getCurrentSpecificContact(state),
        currentTab: selectCurrentTab(state),
      };
    });

  const [data, setData] = useState<{ nodes: any[]; links: any[] }>({
    nodes: [],
    links: [],
  });

  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [isExternalEqTask, setExternalEqTask] = useState(false);
  const [topBarConfig, setTopBarConfig] = useState<any>({
    isExternalEqTask: false,
    selectedGalaxy: "0",
    ...JSON.parse(getTopBarConfigInLocalStorage() || "{}"),
  });
  const [chosenTask, setChosenTask] = useState({ id: 0 });

  const [notEditableNotes, setNotEditableNotes] = useState(true);

  const [searchTerm, setSearchTerm] = useState("");

  const [galaxiesOptions, setGalaxiesOptions] = useState<any[]>([]); // [{ value: "1", optionText: "Published" }
  const [selectedGalaxy, setSelectedGalaxy] = useState("0"); // 0 or any default value

  const {
    control,
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    if (notEditableNotes) {
      setNotEditableNotes(false);
    } else {
      if (watch("notes") !== specificContact.notes) {
        dispatch(
          updateContact({
            componentId: CONTACT_COMPONENT_ID,
            contactId: specificContact.id,
            data: {
              ...specificContact,
              notes: data.notes,
            },
          })
        ).then((res: any) => {
          setNotEditableNotes(true);
        });
      } else {
        setNotEditableNotes(true);
      }
    }
  };

  const handleContactsSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const term = event.target.value;
    console.log("term", term);

    if (term === searchTerm) {
      setSearchTerm(term);
      return;
    }

    if (term === "") {
      dispatch(getAllContacts({ componentId: TASKS_COMPONENT_ID }));
    }
    setSearchTerm(term);
    // Implement additional search logic here, if needed
  };

  const handleContactsSearchClick = () => {
    dispatch(
      searchForExistedContact({
        componentId: TASKS_COMPONENT_ID,
        freetext: searchTerm as any,
      })
    );
  };

  useEffect(() => {
    // Function to fetch data based on the current state
    const fetchData = async () => {
      if (!topBarConfig.isExternalEqTask) {
        if (searchTerm === "") {
          dispatch(getAllContacts({ componentId: TASKS_COMPONENT_ID }));
        } else {
          dispatch(
            searchForExistedContact({
              componentId: TASKS_COMPONENT_ID,
              freetext: searchTerm as any,
            })
          );
        }
      }

      if (topBarConfig.isExternalEqTask) {
        dispatch(getAllTasks({ componentId: TASKS_COMPONENT_ID }));
      }

      dispatch(getAllTaskQueues({ componentId: TASKS_COMPONENT_ID }));

      dispatch(getGalaxies({ componentId: TASKS_COMPONENT_ID })).then(
        (res: any) => {
          const options = [
            {
              value: 0,
              optionText: "All",
            },
          ];

          const sortedGalaxies = [...res.payload].sort((a: any, b: any) => {
            return a.order - b.order;
          });

          //add all option from res.payload options is an object
          sortedGalaxies.forEach((galaxy: any) => {
            const tempOptions = {
              value: galaxy.id,
              optionText: galaxy.name,
            };

            options.push(tempOptions);
          });

          setGalaxiesOptions(options);
        }
      );
    };
    fetchData();
    //convert topBarConfig to string
    const topBarConfigString = JSON.stringify(topBarConfig);

    storeTopBarConfigInLocalStorage(topBarConfigString);
  }, [topBarConfig]);

  useEffect(() => {
    if (specificContact) {
      reset({ notes: specificContact?.notes ?? "" });
    }
  }, [specificContact]);

  useEffect(() => {
    if (taskQueues && taskQueues.length > 0) {
      let taskQueueNodes: any[] = [];
      let externalNodes: any[] = [];
      let updatedLinks: any[] = [];

      // Apply filter only if selectedGalaxy is not 0
      const filteredTaskQueues =
        topBarConfig.selectedGalaxy !== "0"
          ? taskQueues.filter((queue) => {
              return queue.galaxies_ids.includes(
                parseInt(topBarConfig.selectedGalaxy)
              );
            })
          : taskQueues;

      // Create a set of valid taskQueueIds for quick lookup
      const validTaskQueueIds = new Set(filteredTaskQueues.map((q) => q.id));

      if (!topBarConfig.isExternalEqTask && contacts && contacts.length > 0) {
        taskQueueNodes = filteredTaskQueues.map((queue) => ({
          ...queue,
          id: `queue-${queue.id}`,
          nodeType: "queue",
          taskQueueId: queue.id,
        }));

        externalNodes = contacts
          .map((contact) => {
            // Determine the taskQueueId for this contact
            const taskQueueId =
              !contact?.nexttaskqueue_id || contact.nexttaskqueue_id === 0
                ? filteredTaskQueues.find((queue) => queue.default)?.id
                : contact.nexttaskqueue_id;

            console.log("test contact", contact);
            console.log(
              "test !contact?.nexttaskqueue_id",
              !contact?.nexttaskqueue_id
            );
            console.log(
              "test contact?.nexttaskqueue_id === 0",
              contact?.nexttaskqueue_id === 0
            );

            console.log("test filteredTaskQueues", filteredTaskQueues);

            console.log(
              "test filteredTaskQueues.find((queue) => queue.default)?.id",
              filteredTaskQueues.find((queue) => queue.default)?.id
            );

            console.log("test taskQueueId", taskQueueId);

            // Return the node object only if the taskQueueId is valid
            if (validTaskQueueIds.has(taskQueueId)) {
              return {
                ...contact,
                id: `external-${contact.id}`,
                contact_id: contact.id,
                nodeType: "external",
                isContact: true,
                taskQueueId: taskQueueId,
              };
            }
            return null; // Exclude this contact if no valid taskQueueId
          })
          .filter((node) => node !== null); // Remove null values

        contacts.forEach((contact) => {
          const externalNode = externalNodes.find(
            (node) => node.contact_id === contact.id
          );
          if (externalNode) {
            const targetId = `queue-${externalNode.taskQueueId}`;
            updatedLinks.push({
              source: `external-${contact.id}`,
              target: targetId,
            });
          }
        });
      } else if (topBarConfig.isExternalEqTask && tasks && tasks.length > 0) {
        var defaultQueueId = filteredTaskQueues.find(
          (queue) => queue.default
        )?.id;

        taskQueueNodes = filteredTaskQueues
          .filter((queue) => !queue.default)
          .map((queue) => ({
            ...queue,
            id: `queue-${queue.id}`,
            nodeType: "queue",
            taskQueueId: queue.id,
          }));

        externalNodes = tasks
          .filter((task) => task.status === "todo") // Filter tasks by status
          .map((task) => {
            const taskQueueId =
              task.taskqueue_id !== defaultQueueId ? task.taskqueue_id : null;

            // Return the node object only if the taskQueueId is valid
            if (validTaskQueueIds.has(taskQueueId)) {
              return {
                ...task,
                id: `external-${task.id}`,
                contact_id: task._contacts.id,
                nodeType: "external",
                isContact: false,
                taskQueueId: taskQueueId,
              };
            }
            return null; // Exclude this contact if no valid taskQueueId
          })
          .filter((node) => node !== null); // Remove null values

        tasks.forEach((task) => {
          if (task.taskqueue_id !== defaultQueueId) {
            const externalNode = externalNodes.find(
              (node) => node.id === `external-${task.id}`
            );
            if (externalNode) {
              const targetId = `queue-${task.taskqueue_id}`;
              updatedLinks.push({
                source: `external-${task.id}`,
                target: targetId,
              });
            }
          }
        });
      }

      setData({
        nodes: [...taskQueueNodes, ...externalNodes],
        links: updatedLinks,
      });
    }
  }, [contacts, galaxies, tasks, taskQueues]);

  const handleNodeClick = (contactId: number) => {
    // Find the contact in the contacts array with the given ID

    // setContactDetails(res.payload);
    setIsPanelOpen(true);

    dispatch(
      getSpecificContact({
        componentId: CONTACT_COMPONENT_ID,
        contactId: contactId,
      })
    ).then((res: any) => {
      setNotEditableNotes(
        !res.payload.notes || res.payload.notes === "" ? false : true
      );
    });
  };

  const onChangeOfGalaxy = (e: any) => {
    setTopBarConfig({
      ...topBarConfig,
      selectedGalaxy: e.target.value,
    });
  };

  const closePanel = () => {
    setIsPanelOpen(false);
    // setContactDetails(null);
  };

  // Function to format date in the desired format
  const formatDate = (timestamp: any) => {
    const date = new Date(timestamp);
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }).format(date);
  };

  // Function to render link icons
  const renderLinkIcons = (contact: any) => {
    const linkTypes = [
      { type: "linkedin", icon: AiOutlineLinkedin, url: contact.linkedin },
      { type: "instagram", icon: AiFillInstagram, url: contact.instagram },
      { type: "facebook", icon: AiFillFacebook, url: contact.facebook },
      { type: "podcast", icon: FaPodcast, url: contact.podcast },
      { type: "youtube", icon: FaYoutube, url: contact.youtube },
      { type: "website", icon: FaGlobe, url: contact.website }, // Changed icon for website
      { type: "sourceurl", icon: FaLink, url: contact.sourceurl }, // Changed icon for source URL
    ];

    // Filter out link types without a URL
    const validLinks = linkTypes.filter((link) => link.url);

    if (validLinks.length === 0) {
      return null; // Return null if no links are present
    }

    return (
      <div className="flex flex-wrap">
        {validLinks.map((link, index) => (
          <button
            key={index}
            onClick={() => window.open(link.url, "_blank")}
            className="p-1 m-1"
          >
            <link.icon size={40} />
          </button>
        ))}
      </div>
    );
  };

  // Define a function to render different content based on the currentTab state
  const renderTabContent = () => {
    switch (currentTab) {
      case "contactInfo":
        return <ContactInfoTab setChosenTask={setChosenTask} />;
      case "moreInfo":
        return <MoreInfoTab specificContact={specificContact} />;
      default:
        return <ContactInfoTab specificContact={specificContact} />;
    }
  };

  return (
    <>
      {topBarConfig.isExternalEqTask !== undefined && (
        <ToggleSwitch
          id="typeOfQuestions"
          dataYes="task"
          dataNo="contact"
          className="absolute z-50 top-3 right-20"
          checked={topBarConfig.isExternalEqTask}
          onClick={(e) => {
            e.preventDefault(); // Prevent default form submission or any other default action

            const newIsExternalEqTask = !topBarConfig.isExternalEqTask;
            setTopBarConfig({
              ...topBarConfig,
              isExternalEqTask: newIsExternalEqTask,
            });
          }}
        />
      )}
      {topBarConfig.selectedGalaxy && (
        <Dropdown
          options={galaxiesOptions}
          className="absolute z-50 top-3 w-40 right-56 font-medium disabled:hide-arrow-select disabled:bg-white disabled:border-white disabled:shadow-none disabled:focus:ring-0 disabled:text-gray-900 text-gray-600 text-center sm:text-left"
          asDefaultValue={true}
          value={topBarConfig.selectedGalaxy}
          onChange={(e: any) => onChangeOfGalaxy(e)}
        />
      )}
      <div className="absolute z-50 top-3 w-48 right-96">
        <button
          className="btn-primary-fill text-white bg-blue-500 hover:bg-blue-600 focus-visible:ring-blue-500"
          type="button"
          onClick={(e) => {
            e.preventDefault(); // Prevent default form submission or any other default action
            dispatch(openStandardModal("modalCreateContactPopup"));
          }}
        >
          Create New Contact
        </button>
      </div>
      <div>
        {specificContact && Object.keys(specificContact).length !== 0 && (
          <div>
            <IsLoading componentId={TASKS_COMPONENT_ID} showSuccess={false}>
              <NewTaskPopup
                id="modalNewTaskPopup"
                contactId={specificContact?.id}
                galaxies={galaxies}
              ></NewTaskPopup>
              {chosenTask && chosenTask.id !== 0 ? (
                <EditTaskPopup
                  id="modalEditTaskPopup"
                  contactId={specificContact?.id}
                  task={chosenTask}
                  galaxies={galaxies}
                ></EditTaskPopup>
              ) : (
                <></>
              )}
              <ModalConfirmation
                id="removeTheTaks"
                onClick={() => {
                  dispatch(closeStandardModal("removeTheTaks"));
                  dispatch(
                    deleteTask({
                      componentId: CONTACT_COMPONENT_ID,
                      taskId: chosenTask?.id,
                    })
                  );
                }}
                title={t("pages.dashboard.deleteTask.title")}
                text={t("pages.dashboard.deleteTask.text")}
                componentId={REMOVE_THE_TASK_COMPONENT_ID}
              />
            </IsLoading>

            {/* <NewContactPopup
            id="modalNewTaskPopup"
            contactId={specificContact?.id}
          ></NewContactPopup> */}
            <EditContactPopup
              id="modalEditContactPopup"
              contact={specificContact}
            ></EditContactPopup>
            <DraftMessagePopup
              id="modalDraftMessagePopup"
              contact={specificContact}
            ></DraftMessagePopup>
          </div>
        )}
        <CreateContactPopup
          id="modalCreateContactPopup"
          galaxies={galaxies}
        ></CreateContactPopup>
      </div>
      <Transition
        show={isPanelOpen}
        enter="transition ease-out duration-300"
        enterFrom="transform -translate-x-full"
        enterTo="transform translate-x-0"
        leave="transition ease-in duration-300"
        leaveFrom="transform translate-x-0"
        leaveTo="transform -translate-x-full"
        className="absolute inset-y-0 left-0 w-1/4 top-[4rem] bg-white shadow-md z-40 overflow-auto"
        aria-labelledby="contact-details-title"
      >
        <IsLoading componentId={CONTACT_COMPONENT_ID} showSuccess={false}>
          <>
            {/* if not not empty object */}
            {specificContact && Object.keys(specificContact).length !== 0 && (
              <div className="p-4">
                <div className="flex w-full">
                  <button
                    onClick={closePanel}
                    className="text-gray-600 hover:text-gray-800"
                  >
                    <FaTimes size={20} />
                  </button>
                  <div className="flex justify-end flex-1 w-full">
                    <div className="flex my-auto">
                      <button
                        className="btn-primary-fill text-white bg-blue-500 hover:bg-blue-600 focus-visible:ring-blue-500"
                        type="button"
                        onClick={() => {
                          dispatch(openStandardModal("modalDraftMessagePopup"));
                        }}
                      >
                        Draft message
                      </button>
                    </div>
                    <div className="flex ml-4">
                      <button
                        className="btn-primary-fill text-white bg-blue-500 hover:bg-blue-600 focus-visible:ring-blue-500"
                        type="button"
                        onClick={() => {
                          dispatch(openStandardModal("modalEditContactPopup"));
                        }}
                      >
                        Edit Contact
                      </button>
                    </div>
                  </div>
                </div>
                <TabsBar />
                <div className="flex items-center space-x-4 py-2">
                  {/* Image with rounded corners and hover effect */}
                  <div className="relative">
                    <img
                      src={specificContact.pictureurl}
                      alt="Contact"
                      className="rounded-full w-12 h-12 object-cover"
                    />
                    <div className="absolute inset-0 rounded-full hover:bg-black hover:bg-opacity-10 flex justify-center items-center overflow-hidden">
                      <img
                        src={specificContact.pictureurl}
                        alt="Contact"
                        className="rounded-full w-24 h-24 object-cover opacity-0 hover:opacity-100"
                      />
                    </div>
                  </div>
                  <h2
                    id="contact-details-title"
                    className="text-xl font-semibold"
                  >
                    {specificContact.firstname} {specificContact.lastname}
                  </h2>
                </div>
                {renderTabContent()}
              </div>
            )}
          </>
        </IsLoading>
      </Transition>
      {/* <div className="flex flex-row"> */}

      <div
        className={`flex flex-col transition-all duration-300 ease-out h-[calc(100vh-4rem)] ${
          isPanelOpen ? "pl-[12.5%] w-full" : "ml-0 w-full"
        }`}
      >
        {!topBarConfig.isExternalEqTask && (
          <div
            className={`absolute z-20 flex justify-center items-center ${
              isPanelOpen ? "pl-[1.5%] w-full" : "ml-0 w-full"
            }`}
          >
            {/* Center the search bar in its container */}
            <div className="absolute top-0 p-4 w-full xl:max-w-3xl max-w-xl mx-auto">
              <SearchBar
                onChange={handleContactsSearchChange}
                onClick={handleContactsSearchClick}
                placeholder="Search contacts"
                className="w-full shadow-md"
              />
            </div>
          </div>
        )}
        <IsLoading componentId={TASKS_COMPONENT_ID} showSuccess={false}>
          <div className="flex flex-1 overflow-visible flex-col w-full">
            {data.nodes.length !== 0 && (
              <div className="overflow-hidden h-full w-full">
                <ForceGraph
                  data={data}
                  onNodeClick={handleNodeClick}
                  selectedGalaxyId={parseInt(topBarConfig.selectedGalaxy)}
                />
              </div>
            )}
          </div>
        </IsLoading>
      </div>
      {/* </div> */}
    </>
  );
};

export default Dashboard;
