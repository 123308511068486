import {
  ChangeEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { nanoid } from "nanoid";
import "../../../index.css";

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    ReactHookFormTextarea {
  label?: string;
  defaultHeight?: number;
}

export interface ReactHookFormTextarea {
  registerFct?: () => UseFormRegisterReturn;
}

export const DynamicTextarea = ({
  label,
  defaultHeight,
  registerFct,
  ...props
}: TextareaProps) => {
  const textareaId = props.id || `textarea-${nanoid()}`;
  const handleInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    // Reset the height to its default or a minimum value to properly detect the scroll height
    e.target.style.height = defaultHeight ? `${defaultHeight}px` : "auto";

    // Adjust the height based on the scrollHeight
    const textHeight = e.target.scrollHeight + 5;
    e.target.style.height = `${textHeight}px`;
  };

  useEffect(() => {
    const textarea = document.getElementById(textareaId) as HTMLTextAreaElement;
    if (textarea && registerFct) {
      textarea.style.height = defaultHeight ? `${defaultHeight}px` : "auto";
      textarea.style.height = `${textarea.scrollHeight + 5}px`;
    }
  }, [registerFct, textareaId, defaultHeight]);

  return (
    <>
      {label && (
        <div className="w-full">
          <label className="block text-sm font-medium text-gray-700">
            {label}
          </label>
          <div className="mt-1 mb-3">
            <textarea
              id={textareaId}
              rows={1}
              onInput={handleInput}
              {...props}
              {...(registerFct && registerFct())}
            />
          </div>
        </div>
      )}
      {!label && (
        <textarea
          id={textareaId}
          rows={1}
          onInput={handleInput}
          {...props}
          {...(registerFct && registerFct())}
        />
      )}
    </>
  );
};

// export default DynamicTextarea;
