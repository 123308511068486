import { Key } from "react";
import { ReactHookFormInput } from "./Input";
import "../../../index.css";

export interface DropdownProps
  extends React.SelectHTMLAttributes<HTMLSelectElement>,
    ReactHookFormInput {
  asDefaultValue?: boolean;
  label?: string;
  options: {
    disabled?: boolean;
    value: string;
    optionText: string;
  }[];
  onClickCb?: any;
}

const Dropdown = ({
  label,
  asDefaultValue,
  options,
  registerFct,
  onClickCb,
  ...props
}: DropdownProps) => {
  return (
    <div className="w-full">
      <label className="block text-sm font-medium text-gray-700 w-full">{label}</label>
      <div className="mt-1">
        <select
          {...props}
          name="########"
          id="########"
          {...(registerFct && registerFct())}
          defaultValue={asDefaultValue ? options[0]?.value : ""}
          onClick={(e: any) => (onClickCb ? onClickCb(e.target.value) : null)}
        >
          {!asDefaultValue && <option value={0}>Please select...</option>}
          {options.map((option, i: Key | null | undefined) => {
            return (
              <option
                className="disabled:bg-gray-200"
                disabled={option?.disabled}
                key={i}
                value={option?.value}
              >
                {option?.optionText}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default Dropdown;
