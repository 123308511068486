import { Tooltip } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Icon, { IconName } from "../Icon/Icon";

export interface TabsBarItemProps {
  icon: IconName;
  title: string;
  onClick?(): void;
  disabled?: boolean;
  tooltipText?: string;
}

export interface DropdownItemProps {
  title: string;
  routeTo?: string;
  onClick?(): void;
  disabled?: boolean;
  tooltipText?: string;
}

const TabsBarItem = ({ icon, title, disabled, ...props }: TabsBarItemProps) => {
  return (
    <button
      className="flex items-center mx-2 px-4 py-2 hover:bg-slate-100"
      {...props}
    >
      <Icon icon={icon} className="w-6 h-6 mr-2" />
      <span className="text-sm">{title}</span>
    </button>
  );
};

const SidebarItemSmall = ({
  icon,
  title,
  disabled,
  ...props
}: TabsBarItemProps) => {
  return (
    <button
      className="flex w-full my-1 px-2 cursor-pointer py-2 items-center hover:bg-slate-100 align-middle" //bg-primary hover:bg-primary-darker rounded-lg
      {...props}
    >
      <div className="mr-2">
        <Icon icon={icon} className="w-4 h-4 ml-1 stroke-black" />
      </div>
      <div className="w-full">
        <p className="text-black text-sm align-middle">{title}</p>
      </div>
    </button>
  );
};

export const DropdownItem = ({
  title,
  routeTo = "",
  onClick,
  ...props
}: DropdownItemProps) => {
  return (
    <Link
      to={routeTo}
      onClick={onClick}
      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
    >
      {title}
    </Link>
  );
};

export { TabsBarItem, SidebarItemSmall };
