import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "../../features/auth/state/authSlice";
import modalReducer from "./slice/modal/modalSlice";
import { appStateReducer } from "./slice/appStateSlice";
import { loadingHandler } from "./slice/loaderHandleMiddleware";
import { createLogger } from "redux-logger";
import { myUserReducer } from "../../features/my-user/state/myUserSlice";
import { configReducer } from "../../features/config-collectiontype/state/configSlice";
import { contactsReducer } from "../../features/contacts/state/contactsSlice";
import { tabStateReducer } from "../../features/tab/tabStateSlice";

const logger = createLogger({});

export const store = configureStore({
  reducer: {
    modals: modalReducer,
    auth: authReducer,
    app: appStateReducer,
    config: configReducer,
    myUser: myUserReducer,
    contacts: contactsReducer,
    tabState: tabStateReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(loadingHandler).concat(logger),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
