import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "../../../model/model";
import axios from "axios";
import apiUrls from "../../../api";
import {
  getAuthFromLocalStorage,
  storeAuthInLocalStorage,
} from "../../../common/util/localStorage";
import { IAppLoaderAction } from "../../../common/state/slice/loaderHandleMiddleware";
import { isTokenExpired } from "../../../common/util/auth";

export interface IAuthFormData {
  email: string;
  password: string;
  rememberme: boolean;
}

export interface IRegisterFormData {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  termsAndConditions: boolean;
}

export interface IForgottenPasswordFormData {
  email: string;
}

export interface IResetPasswordFormData {
  password: string;
  passwordConfirmation: string;
}

export interface IAuth {
  authToken: string;
}

export const signIn = createAsyncThunk(
  "auth/login",
  async ({ email, password }: IAuthFormData & IAppLoaderAction) => {
    let response: any;

    await axios
      .post<{ jwt: string; user: IUser }>(
        `${apiUrls.auth.signIn}?email=${email}&password=${password}`
      )
      .then((res) => {
        response = res.data;
      });

    const { authToken } = response;
    // Storing authTokenand user in local storage
    storeAuthInLocalStorage(authToken, true);

    return { authToken };
  }
);

export const register = createAsyncThunk(
  "auth/register",
  async ({
    firstname,
    lastname,
    email,
    password,
  }: IRegisterFormData & IAppLoaderAction) => {
    let response: any;

    try {
      await axios
        .post(apiUrls.auth.register, {
          username: email,
          email: email,
          password: password,
          firstname: firstname,
          lastname: lastname,
        })
        .then(async (res) => {
          response = res.data;

          // // Send email confirmation request after successful registration
          // await axios.post(apiUrls.auth.sendEmailConfirmation, {
          //   email: email,
          // });
        });
    } catch (e: any) {
      console.error("error", e);
    }
    return;
  }
);

export const setCurrentUserAndAuthtoken = createAsyncThunk(
  "profile/setCurrentUserAndAuthtoken",
  // eslint-disable-next-line no-empty-pattern
  async () => {
    let authToken: string | undefined;
    const authFromLocalStorage = getAuthFromLocalStorage();
    if (
      authFromLocalStorage.authToken &&
      authFromLocalStorage.isRemebered &&
      authFromLocalStorage.authToken !== null
      //&& !isTokenExpired(authFromLocalStorage.authToken)
    ) {
      authToken = authFromLocalStorage.authToken;
    } else {
      // await axios
      //   .get(`${apiUrls.register.users}/me`)
      //   .then((res) => {
      //     currentUser = res.data;
      //     organization = res.data.organization;
      //   })
      //   .catch((e) => {
      //     console.error(e);
      //     return e;
      //   });
      authToken = "";
    }

    return { authToken };
  }
);

export const forgottenPassword = createAsyncThunk(
  "auth/forgottenPassword",
  async (
    { email }: IForgottenPasswordFormData & IAppLoaderAction,
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post<{ jwt: string; user: IUser }>(
        apiUrls.auth.forgetPassword,
        {
          email,
        }
      );
      return { response };
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data);
      } else {
        throw err;
      }
    }
  }
);
export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (
    {
      password,
      passwordConfirmation,
      code,
    }: IResetPasswordFormData & { code: string } & IAppLoaderAction,
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post<{ jwt: string; user: IUser }>(
        apiUrls.auth.resetPassword,
        {
          code: code,
          password: password,
          passwordConfirmation: passwordConfirmation,
        }
      );
      return { response };
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data);
      } else {
        throw err;
      }
    }
  }
);

const initialState: IAuth = { authToken: "" };

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state: IAuth, action: PayloadAction<{ authToken: string }>) => {
      state.authToken = action.payload.authToken;
    },
    logoutAuth: (state: IAuth) => {
      state.authToken = "";
      delete axios.defaults.headers.common["Authorization"];
      window.localStorage.clear();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signIn.fulfilled, (state, { payload }) => {
      state.authToken = payload.authToken;
    });
    builder.addCase(
      setCurrentUserAndAuthtoken.fulfilled,
      (state, { payload }) => {
        state.authToken = payload.authToken;
      }
    );
  },
});

export const authReducer = authSlice.reducer;

export const getCurrentAuthtoken = (state: IAuth) => state.authToken;

export const { logoutAuth, setAuth } = authSlice.actions;
