import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { InputIcon } from "../../../common/localComponents/Input";
import { loadingSelector, LoadingState } from "../../../common/state/slice/appStateSlice";
import { AppDispatch, RootState } from "../../../common/state/store";
import { IResetPasswordFormData, resetPassword } from "../state/authSlice";
import { useNavigate } from "react-router-dom";
import { IsLoading } from "../../../common/localComponents/AppState/IsLoading";
import { Error } from "../../../common/localComponents/AppState/Error";

export const RESET_PASSWORD_COMPONENTID = "resetPasswordComponent";

interface IResetPasswordForm {
  labels: {
    title: string;
    password: string;
    passwordConfirm: string;
    passwordNotMarch: string;
  };
  onSubmit: (data: any) => void;
}

const ResetPasswordContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [resetPasswordToken, setResetPasswordToken] = React.useState("");

  const { loading } = useSelector((state: RootState) => {
    return {
      loading: loadingSelector(state, RESET_PASSWORD_COMPONENTID),
    };
  });
  React.useEffect(() => {
    window.localStorage.removeItem("resetPasswordToken");
    const queryString = location.search;
    const codeIr = queryString.split("?code=");
    const code = codeIr[codeIr.length - 1];
    if (code) {
      window.localStorage.setItem("resetPasswordToken", code);
      // this is for circumventing the issue regarding the S3/nginx routing 'hack' (redirection "swallows" the token in querystring)
    }
    try {
      const theToken = window.localStorage.getItem("resetPasswordToken");
      if (theToken) {
        setResetPasswordToken(theToken);
      }
    } catch (e) {
      console.error(`There was a problem...\n${e}`);
    }
  }, [location.search]);

  React.useEffect(() => {
    if (loading && loading === LoadingState.Success) {
      navigate("/");
    }
  }, [loading]);

  const labels = {
    title: t("pages.resetPassword.title"),
    password: t("pages.resetPassword.enterNewPwd"),
    passwordConfirm: t("pages.resetPassword.confirmNewPwd"),
    passwordNotMarch: t("pages.resetPassword.confirmReset"),
  };
  const onSubmit = (data: IResetPasswordFormData) => {
    dispatch(
      resetPassword({ componentId: RESET_PASSWORD_COMPONENTID, code: resetPasswordToken, ...data }),
    );
  };

  return <ResetPassword labels={labels} onSubmit={onSubmit} />;
};

const ResetPassword = ({ labels, onSubmit }: IResetPasswordForm) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IResetPasswordFormData>();
  const [passwordOneVisible, setPasswordOneVisible] = useState(false);
  const [passwordTwoVisible, setPasswordTwoVisible] = useState(false);

  return (
    <div
      className="flex h-screen bg-cover"
    >
      {/* <div className="bg-slate-100 flex h-screen"> */}
      <div className="flex flex-1 max-w-xs md:max-w-lg mx-auto items-center justify-center ">
        <form className="w-full sm:w-3/4" onSubmit={handleSubmit(onSubmit)}>
          <div className="border flex flex-col bg-white border-gray-200 rounded-xl shadow-md py-3 px-4">
            <div className="my-2">
              <h2 className="text-xl font-medium text-black">{labels.title}</h2>
            </div>

            <Error componentId={RESET_PASSWORD_COMPONENTID} />
            <IsLoading componentId={RESET_PASSWORD_COMPONENTID} />

            {errors.passwordConfirmation && (
              <span className="text-red-500">{errors.passwordConfirmation.message}</span>
            )}
            <InputIcon
              registerFct={() => register("password", { required: true })}
              type={passwordOneVisible === true ? "text" : "password"}
              position="right"
              label={labels.password}
              placeholder={labels.password}
              handleClick={() => setPasswordOneVisible(!passwordOneVisible)}
              icon={passwordOneVisible === true ? "EyeIcon" : "EyeSlashIcon"}
            />
            <div className="mt-1">
              <InputIcon
                registerFct={() =>
                  register("passwordConfirmation", {
                    required: true,
                    validate: (val: string) => {
                      if (watch("password") !== val) {
                        return labels.passwordNotMarch;
                      }
                      return true;
                    },
                  })
                }
                type={passwordTwoVisible === true ? "text" : "password"}
                position="right"
                label={labels.passwordConfirm}
                placeholder={labels.passwordConfirm}
                handleClick={() => setPasswordTwoVisible(!passwordTwoVisible)}
                icon={passwordTwoVisible === true ? "EyeIcon" : "EyeSlashIcon"}
              />
            </div>
            <div className="flex flex-col sm:flex-row sm:pt-8 sm:justify-between">
              <button className="btn-primary-outline" type="submit">
                {labels.title}
              </button>
            </div>
          </div>
        </form>
      </div>
      {/* </div> */}
    </div>
  );
};

export default ResetPasswordContainer;
