import React from "react";
import PropTypes from "prop-types";
import "./ToggleSwitch.css";
import { UseFormRegisterReturn } from "react-hook-form";
import { t, use } from "i18next";
import "../../../index.css";
import { useState, useEffect, useCallback } from "react";

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function. The props name, small, disabled
and optionLabels are optional.
Usage:   id="id" checked={value} onChange={checked => setValue(checked)}} />
*/

export interface ToggleSwitchProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    ReactHookFormToggleSwitch {
  label?: string;
  children?: "";
  dataYes?: string; // Added prop for data-yes
  dataNo?: string; // Added prop for data-no
}

export interface ReactHookFormToggleSwitch {
  registerFct?: () => UseFormRegisterReturn;
  watchFct?: () => any;
}

export const ToggleSwitch = ({
  label,
  id,
  name,
  disabled,
  registerFct,
  watchFct,
  dataYes = `${t("general.yes")}`,
  dataNo = `${t("general.no")}`,
  className = "",
  ...props
}: ToggleSwitchProps) => {
  function handleKeyPress(e: any) {
    if (e.keyCode !== 32) return;

    e.preventDefault();
  }

  const [switchWidth, setSwitchWidth] = useState(75);
  const [togglePosition, setTogglePosition] = useState("0px"); // Set the initial position
  const [isChecked, setIsChecked] = useState(
    watchFct ? watchFct() : props.checked
  ); // Add this state to track the checkbox state

  const toggleButtonWidth = 24; // Width of the toggle button
  const textPadding = 20; // Total padding around the text
  const calculatedWidth = `calc(100% - (${toggleButtonWidth}px + 12px))`; // Width of the unchecked state

  const handleTogglePosition = () => {
    setTogglePosition(
      isChecked ? `0px` : calculatedWidth // Padding when it's at the left
    );
  };

  useEffect(() => {
    // Update internal state based on the 'checked' prop
    setIsChecked(props.checked);
  }, [props.checked]);

  // handleChange remains the same
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsChecked(e.target.checked);
      if (registerFct && registerFct().onChange) {
        registerFct().onChange(e);
      }
    },
    [registerFct]
  );

  useEffect(() => {
    handleTogglePosition();
  }, [isChecked]);

  useEffect(() => {
    if (disabled) {
      if (isChecked) {
        setTogglePosition("0px");
      } else {
        setTogglePosition(calculatedWidth);
      }
    }
  }, [disabled]);

  useEffect(() => {
    const yesWidth = getTextWidth(dataYes);
    const noWidth = getTextWidth(dataNo);
    const maxTextWidth = Math.max(yesWidth, noWidth);
    setSwitchWidth(maxTextWidth);
  }, [dataYes, dataNo]);

  useEffect(() => {
    handleTogglePosition();
  }, [switchWidth]);

  // Function to calculate text width
  const getTextWidth = (text: any) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (context) {
      context.font = "14px sans-serif";
      const metrics = context.measureText(text);
      // Include the padding and toggle button width
      return metrics.width + textPadding + toggleButtonWidth; // Include the toggle button width twice
    }
    return 0;
  };

  return (
    <div className={`flex flex-col ${className}`}>
      <div>
        <label className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      </div>
      <div className="mt-1 flex h-full w-full items-center">
        <div
          className={"toggle-switch"}
          style={{ minWidth: `${switchWidth}px` }}
        >
          <input
            {...props}
            type="checkbox"
            className="toggle-switch-checkbox"
            id={id}
            disabled={disabled}
            {...(registerFct && registerFct())}
            onChange={(e) => {
              handleChange(e);
            }}
          />
          {id && (
            <label
              className="toggle-switch-label"
              tabIndex={disabled ? -1 : 1}
              onKeyDown={(e) => handleKeyPress(e)}
              htmlFor={id}
            >
              <span
                className={
                  disabled
                    ? "toggle-switch-inner toggle-switch-disabled"
                    : "toggle-switch-inner"
                }
                data-yes={dataYes}
                data-no={dataNo}
                tabIndex={-1}
              />
              <span
                className={
                  disabled
                    ? "toggle-switch-switch toggle-switch-disabled"
                    : "toggle-switch-switch"
                }
                style={{ right: togglePosition, transition: "right 0.3s" }} // Add transition here
                tabIndex={-1}
              />
            </label>
          )}
        </div>
      </div>
    </div>
  );
};

// Set optionLabels for rendering.
// ToggleSwitch.defaultProps = {
//   optionLabels: ["Yes", "No"],
// };

// ToggleSwitch.propTypes = {
//   id: PropTypes.string.isRequired,
//   name: PropTypes.string,
//   disabled: PropTypes.bool,
// };

// export default ToggleSwitch;
