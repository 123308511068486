import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../state/store";
import { Dashboard } from "../../pages/Dashboard";
import { Login, ForgottenPassword } from "../../pages/Login";

import ResetPassword from "../../pages/Login/reset-password";

import Layout from "./Layout";
import {
  getCurrentAuthtoken,
  logoutAuth,
  setAuth,
} from "../../features/auth/state/authSlice";
import { getAuthFromLocalStorage } from "../util/localStorage";
import { isTokenExpired } from "../util/auth";
import { setAuthToken } from "../util/axios";
import Settings from "../../pages/Settings/settings";

const Router = () => {
  const { authToken } = useSelector((state: RootState) => {
    return {
      authToken: getCurrentAuthtoken(state.auth),
    };
  });

  const [IsAuthenticated, SetIsAuthenticated] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (IsAuthenticated == false) {
      if (authToken === "") {
        const authFromLocalStorage = getAuthFromLocalStorage();
        if (
          authFromLocalStorage.authToken &&
          authFromLocalStorage.authToken !== null
          //&& !isTokenExpired(authFromLocalStorage.authToken)
        ) {
          dispatch(
            setAuth({
              authToken: authFromLocalStorage.authToken,
            })
          );
          setAuthToken(authFromLocalStorage.authToken);
          SetIsAuthenticated(true);
        } else {
          dispatch(logoutAuth());
        }
      }
    }
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Dashboard />} />
          <Route path="/settings" element={<Settings />} />
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>Theres nothing here!</p>
              </main>
            }
          />
        </Route>
        <Route path="/login" element={<Login />} />
        {/* <Route path="/email-confirmed" element={<EmailConfirmed />} />
        <Route path="/registration" element={<Registration />} /> */}
        <Route path="/forgottenpassword" element={<ForgottenPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
      </Routes>
    </>
  );
};

export default Router;
