import { IconName } from "../Icon/Icon";
import * as AllIcons from "@heroicons/react/24/outline";
import { InputProps } from "./Input";
import "../../../index.css";

interface InputIconProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    InputProps {
  icon: IconName;
  position: string;
  handleClick?: () => void;
}

const InputIcon = ({
  position,
  icon,
  label,
  handleClick,
  registerFct,
  ...props
}: InputIconProps) => {
  const SingleIcon = AllIcons[icon];

  let iconPosition = "";
  let textPosition = "";

  if (position === "right") {
    iconPosition = "top-2 right-2";
  }

  if (position === "left") {
    iconPosition = "top-2 left-2";
    textPosition = " pl-10";
  }

  return (
    <>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <div className="relative">
        <input
          {...props}
          className={`${textPosition} ${props.className}`}
          {...(registerFct && registerFct())}
        />
        <SingleIcon
          onClick={handleClick}
          className={`absolute cursor-pointer ${iconPosition} w-6 h-6 stroke-gray-300`}
        />
      </div>
    </>
  );
};

export default InputIcon;
