import { useState } from "react";
import { ReactHookFormInput } from "./Input";
// import Select, { components, GroupBase, Props } from "react-select";
import "../../../index.css";

import SelectMultiple, { components } from "react-select";
import { Controller } from "react-hook-form";
export interface DropdownPropsForManyChoices
  extends React.SelectHTMLAttributes<HTMLSelectElement>,
    ReactHookFormInput {
  asDefaultValue?: boolean;
  label?: string;
  name: string;
  options: any[];
  onClickCb?: any;
  control: any;
  defaultValue?: any;
}

export const DropdownManyChoices = ({
  label,
  name,
  options,
  control,
  defaultValue,
}: DropdownPropsForManyChoices) => {
  const [isFocused, setIsFocused] = useState(false);

  const style = {
    control: (base: any, state: any) => ({
      ...base,
      width: "100%",
      twTextOpacity: "1",
      color: "rgb(203 213 225 / var(--tw-placeholder-opacity))",
      twBgOpacity: "1",
      backgroundColor: "rgb(255 255 255 / var(--tw-bg-opacity))",
      borderWidth: "1px",
      twBorderOpacity: isFocused ? "1" : "1",
      borderColor: isFocused
        ? "rgb(209 213 219 / var(--tw-border-opacity))"
        : "rgb(209 213 219 / var(--tw-border-opacity))",
      borderRadius: "0.375rem",
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      twShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
      twShadowColored: "0 1px 2px 0 var(--tw-shadow-color)",
      boxShadow: isFocused
        ? "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)"
        : "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      twPlaceholderOpacity: "1",
      outline: isFocused ? "2px solid transparent" : "",
      outlineOffset: isFocused ? "2px" : "",
      twRingOffsetShadow: isFocused
        ? "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)"
        : "",
      twRingShadow: isFocused
        ? "var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)"
        : "",
      twRingOpacity: isFocused ? "1" : "",
      twRingColor: isFocused ? "rgb(209 213 219 / var(--tw-ring-opacity))" : "",
    }),
    menuPortal: (base: any) => ({
      ...base,
      zIndex: 9999, // Use an appropriate z-index value
    }),
  };

  const MultiValueCon = ({ index, getValue, ...props }: any) => {
    const maxToShow = 3;
    const overflow = getValue()
      .slice(maxToShow)
      .map((x: any) => x.label);

    return index < maxToShow ? (
      <components.MultiValue {...props} />
    ) : index === maxToShow ? (
      <MoreSelectedBadge items={overflow} />
    ) : null;
  };

  const MoreSelectedBadge = ({ items }: any) => {
    const style = {
      background: "#d4eefa",
      fontSize: "12px",
      padding: "4px",
    };

    const title = items.join(", ");
    const length = items.length;
    const label = `+ ${length} item${length !== 1 ? "s" : ""} selected`;

    return (
      <div style={style} title={title}>
        {label}
      </div>
    );
  };

  return (
    <div className="w-full">
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <div className="mt-1">
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field: { value, onChange, onBlur } }) => {
            return (
              <SelectMultiple
                options={options}
                placeholder={"Please select..."}
                components={{
                  MultiValue: MultiValueCon,
                }}
                styles={style}
                closeMenuOnSelect={false}
                isMulti={true}
                onChange={(options) =>
                  onChange(options?.map((option) => option.value))
                }
                onBlur={onBlur}
                onFocus={() => {
                  setIsFocused(true);
                }}
                value={options.filter((option) =>
                  value?.includes(option.value)
                )}
                menuPortalTarget={document.body}
                // defaultValue={
                //   defaultValue ??
                //   options.filter((option) => value?.includes(option.value))
                // }
              />
            );
          }}
        />
      </div>
    </div>
  );
};

// export default DropdownManyChoices;
