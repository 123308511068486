import { Tooltip } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Icon, { IconName } from "../Icon/Icon";
import "../../../index.css";

export interface TopBarItemProps {
  icon: IconName;
  routeTo: string;
  title: string;
  onClick?(): void;
  disabled?: boolean;
  tooltipText?: string;
}

export interface DropdownItemProps {
  title: string;
  routeTo?: string;
  onClick?(): void;
  disabled?: boolean;
  tooltipText?: string;
}

const TopbarItem = ({
  icon,
  routeTo,
  title,
  disabled,
  ...props
}: TopBarItemProps) => {
  return (
    <Link to={routeTo}>
      <button
        className="flex items-center mx-2 px-4 py-2 hover:bg-slate-100"
        {...props}
      >
        <Icon icon={icon} className="w-6 h-6 mr-2" />
        <span className="text-sm">{title}</span>
      </button>
    </Link>
  );
};

export const DropdownItem = ({
  title,
  routeTo = "",
  onClick,
  ...props
}: DropdownItemProps) => {
  return (
    <Link
      to={routeTo}
      onClick={onClick}
      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
    >
      {title}
    </Link>
  );
};

const SidebarItemSmall = ({
  icon,
  routeTo,
  title,
  disabled,
  ...props
}: TopBarItemProps) => {
  return (
    <Link to={routeTo}>
      <button
        className="flex w-full my-1 px-2 cursor-pointer py-2 items-center hover:bg-slate-100 align-middle" //bg-primary hover:bg-primary-darker rounded-lg
        {...props}
      >
        <div className="mr-2">
          <Icon icon={icon} className="w-4 h-4 ml-1 stroke-black" />
        </div>
        <div className="w-full">
          <p className="text-black text-sm align-middle">{title}</p>
        </div>
      </button>
    </Link>
  );
};

const SidebarItemProject = ({
  icon,
  routeTo,
  title,
  disabled,
  tooltipText,
  ...props
}: TopBarItemProps) => {
  let button = (
    <button
      className={`${
        disabled ? "disabled:opacity-50" : "hover:bg-blue-300"
      } flex flex-col w-full my-1 px-2 cursor-pointer py-2 items-center bg-blue-500 rounded-lg`}
      {...props}
      disabled={disabled}
    >
      <Icon icon={icon} className="w-6 h-6 stroke-white m-auto" />
      <span className="text-white">{title}</span>
    </button>
  );

  if (tooltipText && tooltipText !== "") {
    button = <Tooltip title={tooltipText}>{button}</Tooltip>;
  }

  if (!disabled && routeTo !== "") {
    button = <Link to={routeTo}>{button}</Link>;
  }

  return button;
};

export { TopbarItem, SidebarItemSmall, SidebarItemProject };
