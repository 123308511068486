import { useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { RootState } from "../state/store";
import Router from "./Router";
import Translator from "./Translator";
import { useEffect } from "react";
import { setAuthToken } from "../util/axios";
import { isTokenExpired } from "../util/auth";
import { getCurrentAuthtoken } from "../../features/auth/state/authSlice";

export default function App() {
  const { authToken } = useSelector((state: RootState) => {
    return {
      authToken: getCurrentAuthtoken(state.auth),
    };
  });

  // Set AUTHTOKEN globally
  useEffect(() => {
    if (
      authToken
      // && isTokenExpired(authToken))
    ) {
      setAuthToken(authToken);
    }
  }, [authToken]);

  return (
    <Translator>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </Translator>
  );
}
