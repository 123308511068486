import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../state/hooks";
import { RootState } from "../../../state/store";
import { closeStandardModal } from "../../../state/slice/modal/modalSlice";
import { XMarkIcon } from "@heroicons/react/24/outline";
import ReactPlayer from "react-player";
import { Spinner } from "../../Loader/Spinner";
import { useForm } from "react-hook-form";
import { Dropdown, Input } from "../../Input";
import { DynamicTextarea } from "../../Textarea/DynamicTextarea";
import { useTranslation } from "react-i18next";
import {
  createNewContact,
  createNewTask,
  getAllTaskQueues,
  getCurrentSpecificTask,
  getCurrentTaskQueues,
  getSpecificTask,
} from "../../../../features/contacts/state/contactsSlice";
import { IsLoading } from "../../AppState/IsLoading";
import { CONTACT_COMPONENT_ID } from "../../../../pages/Dashboard/dashboard";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { use } from "i18next";

export const TASKQUEUES_COMPONENT_ID = "taskQueuesComponent";
export const CREATE_COMPONENT_ID = "createComponent";

type CreateContactPopupProps = {
  id: string;
  galaxies: any;
};

const CreateContactPopup = ({ id, galaxies }: CreateContactPopupProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const modalState = useSelector(
    (state: RootState) => state.modals.showStandardModal
  );

  const [isLinksCollapsed, setIsLinksCollapsed] = useState(true); // New state for collapsed status

  // Toggle collapse state
  const toggleLinksCollapse = () => {
    setIsLinksCollapsed(!isLinksCollapsed);
  };

  const handleClose = () => {
    dispatch(closeStandardModal(id));
    reset(); // Reset form state
  };

  const [galaxiesOptions, setGalaxiesOptions] = useState<any[]>([]);
  const [taskQueuesOptions, setTaskQueuesOptions] = useState<any[]>([]);

  const {
    control,
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm({});

  const {
    control: taskControl,
    handleSubmit: taskHandleSubmit,
    register: taskRegister,
    watch: taskWatch,
    reset: taskReset,
    formState: { errors: taskErrors },
  } = useForm({});

  const onSubmit = (data: any) => {
    const taskData = taskWatch();

    dispatch(
      createNewContact({
        componentId: CREATE_COMPONENT_ID,
        data: data,
      })
    ).then((res: any) => {
      if (res.payload && res.payload.id) {
        if (
          (taskData.name && taskData.name !== "") ||
          (taskData.galaxy_id !== "0" && taskData.taskqueue_id !== "0")
        ) {
          // Convert duedate from 'YYYY-MM-DD' to Unix timestamp
          const dueDateTimestamp = new Date(taskData.duedate).getTime();

          const newTaskData = {
            ...taskData,
            duedate: dueDateTimestamp,
            taskqueue_id: parseInt(taskData.taskqueue_id),
            contacts_id: res.payload.id,
          };
          dispatch(
            createNewTask({
              componentId: CONTACT_COMPONENT_ID,
              data: newTaskData,
            })
          );
        }
        dispatch(closeStandardModal(id));
      }
    });
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (galaxies && galaxies.length > 0) {
      const localGalaxies = [...galaxies].sort(
        (a: any, b: any) => a.order - b.order
      );

      setGalaxiesOptions(
        localGalaxies.map((galaxy: any) => ({
          value: galaxy.id,
          optionText: galaxy.name,
        }))
      );
    }
  }, [galaxies]);

  useEffect(() => {
    if (galaxies && galaxies.length > 0) {
      const foundGalaxy = galaxies.find(
        (galaxy: any) => galaxy.id === parseInt(taskWatch("galaxy_id"))
      );

      if (foundGalaxy && foundGalaxy.taskqueues) {
        const localTaskQueuesOptions = [...foundGalaxy.taskqueues]
          .sort((a, b) => a.alias - b.alias)
          .filter((taskQueue) => taskQueue.default === false)
          .map((taskQueue) => ({
            value: taskQueue.id,
            optionText: taskQueue.name,
          }));

        setTaskQueuesOptions(localTaskQueuesOptions);

        taskReset({
          ...taskWatch(),
          taskqueue_id: String(localTaskQueuesOptions[0]?.value),
        });
      } else {
        setTaskQueuesOptions([]);
        taskReset({ ...taskWatch(), taskqueue_id: "0" });
      }
    }
  }, [taskWatch("galaxy_id"), galaxies]);

  return (
    <>
      {id === modalState.id && (
        <Transition appear show={modalState.show} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 overflow-y-auto"
            onClose={() => dispatch(closeStandardModal({ id }))}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className={"fixed inset-0 bg-black bg-opacity-25"} />
            </Transition.Child>
            <div className={`fixed inset-0`}>
              <div
                className={
                  "flex items-center justify-center p-4 text-center h-full"
                }
              >
                <div className={"mt-auto mb-auto"}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel
                      className={`h-full transform no-scrollbarw-halfscreen max-w-3xl rounded-2xl pt-auto pb-auto overflow-y-auto bg-slate-100 p-6 text-left align-middle shadow-xl transition-all`}
                    >
                      <div className="relative grow w-full md:w-[500px] max-w-9xl px-4 xl:px-0 mx-auto">
                        <IsLoading
                          componentId={TASKQUEUES_COMPONENT_ID}
                          showSuccess={false}
                        >
                          <form
                            className="w-full h-full"
                            onSubmit={handleSubmit(onSubmit)}
                          >
                            <div className="flex">
                              <h3 className="flex-1 text-2xl font-semibold text-start mb-4">
                                Create contact
                              </h3>
                            </div>
                            <div className="flex mb-2">
                              <div className="w-full pr-2">
                                <Input
                                  type="email"
                                  label="Email"
                                  placeholder={"Enter email"}
                                  registerFct={() =>
                                    register("email", {
                                      pattern: /^\S+@\S+$/i,
                                    })
                                  }
                                />
                              </div>
                              <div className="w-full pl-2">
                                <Input
                                  type="text"
                                  label="Phone Number"
                                  placeholder={"Enter phone number"}
                                  registerFct={() => register("phone", {})}
                                />
                              </div>
                            </div>
                            <div className="flex mb-2">
                              <div className="w-full pr-2">
                                <Input
                                  registerFct={() => register("firstname")}
                                  type="text"
                                  label={"First Name"}
                                  placeholder={"Enter first name"}
                                  className={`w-full  ${
                                    errors.firstname ? "border-red-500" : ""
                                  }`}
                                />
                              </div>
                              <div className="w-full pl-2">
                                <Input
                                  registerFct={() => register("lastname")}
                                  type="text"
                                  label={"Last Name"}
                                  placeholder={"Enter last name"}
                                  className={`w-full  ${
                                    errors.lastname ? "border-red-500" : ""
                                  }`}
                                />
                              </div>
                            </div>
                            <div className="flex mb-2">
                              <div className="w-full pr-2">
                                <Input
                                  registerFct={() => register("pictureurl")}
                                  type="text"
                                  label={"Picture Url"}
                                  placeholder={"Enter Picture URL"}
                                  className={`w-full ${
                                    errors.pictureurl ? "border-red-500" : ""
                                  }`}
                                />
                              </div>
                              <div className="w-full pl-2">
                                <Input
                                  registerFct={() => register("position")}
                                  type="text"
                                  label={"Position"}
                                  placeholder={"Enter position"}
                                  className={`w-full  ${
                                    errors.position ? "border-red-500" : ""
                                  }`}
                                />
                              </div>
                            </div>
                            <div className="flex mt-2">
                              <div className="w-full pr-2">
                                <DynamicTextarea
                                  registerFct={() => register("sourcetext")}
                                  label={"Source Text"}
                                  style={{
                                    resize: "none",
                                    height: "auto",
                                  }}
                                  className={`min-h-[38px] w-full read-only:cursor-default font-medium read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600 text-center sm:text-left ${
                                    errors.sourcetext ? "border-red-500" : ""
                                  } `}
                                  placeholder={"Enter from where you found it"}
                                />
                              </div>
                              <div className="w-full pl-2">
                                <DynamicTextarea
                                  registerFct={() => register("tags")}
                                  label={"Tags"}
                                  style={{
                                    resize: "none",
                                    height: "auto",
                                  }}
                                  className={`min-h-[38px] w-full read-only:cursor-default font-medium read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600 text-center sm:text-left ${
                                    errors.tags ? "border-red-500" : ""
                                  } `}
                                  placeholder={"Enter tags "}
                                />
                              </div>
                            </div>
                            <div className="border-2 rounded-xl p-4 mt-4 bg-white">
                              <div
                                className={`flex justify-between items-center mt-1 ${
                                  isLinksCollapsed ? "mb-1" : "mb-4"
                                }  cursor-pointer`}
                                onClick={toggleLinksCollapse}
                              >
                                <h4 className="text-lg font-semibold">Links</h4>
                                {isLinksCollapsed ? (
                                  <FaChevronDown /> // Expand Down icon
                                ) : (
                                  <FaChevronUp /> // Expand Up icon
                                )}
                              </div>
                              <Transition
                                as="div"
                                show={!isLinksCollapsed}
                                className="transition-all duration-500 overflow-hidden"
                                enterFrom="transform scale-95 opacity-0 max-h-0"
                                enterTo="transform scale-100 opacity-100 max-h-96"
                                leaveFrom="transform scale-100 opacity-100 max-h-96"
                                leaveTo="transform scale-95 opacity-0 max-h-0"
                              >
                                <div className="mb-2">
                                  <Input
                                    registerFct={() => register("linkedin")}
                                    type="text"
                                    label={"LinkedIn"}
                                    placeholder={"Enter LinkedIn URL"}
                                    className={`w-full ${
                                      errors.linkedin ? "border-red-500" : ""
                                    }`}
                                  />
                                </div>
                                <div className="flex mb-2">
                                  <div className="w-full pr-2">
                                    <Input
                                      registerFct={() => register("sourceurl")}
                                      type="text"
                                      label={"Source Url"}
                                      placeholder={"Enter Source URL"}
                                      className={`w-full  ${
                                        errors.sourceurl ? "border-red-500" : ""
                                      }`}
                                    />
                                  </div>
                                  <div className="w-full pl-2">
                                    <Input
                                      registerFct={() => register("website")}
                                      type="text"
                                      label={"Website Url"}
                                      placeholder={"Enter Website URL"}
                                      className={`w-full ${
                                        errors.website ? "border-red-500" : ""
                                      }`}
                                    />
                                  </div>
                                </div>
                                <div className="flex mb-2">
                                  <div className="w-full pr-2">
                                    <Input
                                      registerFct={() => register("facebook")}
                                      type="text"
                                      label={"Facebook"}
                                      placeholder={"Enter Facebook URL"}
                                      className={`w-full  ${
                                        errors.facebook ? "border-red-500" : ""
                                      }`}
                                    />
                                  </div>
                                  <div className="w-full pl-2">
                                    <Input
                                      registerFct={() => register("instagram")}
                                      type="text"
                                      label={"Instagram"}
                                      placeholder={"Enter Instagram URL"}
                                      className={`w-full  ${
                                        errors.instagram ? "border-red-500" : ""
                                      }`}
                                    />
                                  </div>
                                </div>
                                <div className="flex">
                                  <div className="w-full pr-2">
                                    <Input
                                      registerFct={() => register("youtube")}
                                      type="text"
                                      label={"Youtube"}
                                      placeholder={"Enter Youtube URL"}
                                      className={`w-full  ${
                                        errors.youtube ? "border-red-500" : ""
                                      }`}
                                    />
                                  </div>
                                  <div className="w-full pl-2">
                                    <Input
                                      registerFct={() => register("podcast")}
                                      type="text"
                                      label={"Podcast"}
                                      placeholder={"Enter Podcast URL"}
                                      className={`w-full  ${
                                        errors.podcast ? "border-red-500" : ""
                                      }`}
                                    />
                                  </div>
                                </div>
                              </Transition>
                            </div>
                            <div className="mt-4">
                              <form>
                                <h3 className="text-2xl font-semibold text-start mb-4">
                                  {t("pages.dashboard.newTask.title")}
                                </h3>
                                <div className="flex w-full ">
                                  <Input
                                    registerFct={() =>
                                      taskRegister("name", {
                                        // required: true,
                                      })
                                    }
                                    type="text"
                                    label={"Title"}
                                    placeholder={t(
                                      "pages.dashboard.newTask.inputs.titlePlaceholder"
                                    )}
                                    className={`w-full ${
                                      taskErrors.name ? "border-red-500" : ""
                                    }`}
                                    onKeyDown={handleKeyDown}
                                  />
                                </div>
                                <div className="flex mt-2">
                                  <DynamicTextarea
                                    registerFct={() =>
                                      taskRegister("description")
                                    }
                                    label={"Description"}
                                    // style={{ resize: notEditable ? "none" : "vertical" }}
                                    style={{
                                      resize: "none",
                                      height: "auto", //notEditable ? "auto" :
                                    }}
                                    className={`min-h-[38px] w-full read-only:cursor-default font-medium read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600 text-center sm:text-left ${
                                      taskErrors.description
                                        ? "border-red-500"
                                        : ""
                                    } `}
                                    placeholder={t(
                                      "pages.dashboard.newTask.inputs.descriptionPlaceholder"
                                    )}
                                  />
                                </div>
                                <div className="flex">
                                  <div className="w-full pr-2">
                                    <Input
                                      registerFct={() =>
                                        taskRegister("duedate", {
                                          // required: true,
                                        })
                                      }
                                      type="date"
                                      label={"Due Date"}
                                      placeholder={t(
                                        "pages.dashboard.newTask.inputs.duedatePlaceholder"
                                      )}
                                      className={`w-full ${
                                        taskErrors.duedate
                                          ? "border-red-500"
                                          : ""
                                      }`}
                                      onKeyDown={handleKeyDown}
                                    />
                                  </div>
                                  <div className="w-full pl-2">
                                    <Dropdown
                                      options={[
                                        {
                                          value: "todo",
                                          optionText: "To Do",
                                        },
                                        {
                                          value: "done",
                                          optionText: "Done",
                                        },
                                      ]}
                                      label="Status"
                                      className={`w-full font-medium disabled:hide-arrow-select disabled:bg-white disabled:border-white disabled:shadow-none disabled:focus:ring-0 disabled:text-gray-900 text-gray-600 text-center sm:text-left ${
                                        taskErrors.status
                                          ? "border-red-500"
                                          : ""
                                      } `}
                                      defaultValue={"todo"}
                                      // style={{
                                      //   border: contactErrors.taskqueue_id
                                      //     ? "1.5px solid rgb(239 68 68)"
                                      //     : "",
                                      // }}
                                      registerFct={() =>
                                        taskRegister("status", {
                                          // required: true,
                                          // validate: (value) => value !== "0",
                                        })
                                      }
                                      asDefaultValue={true}
                                    />
                                  </div>
                                </div>
                                <div className="flex">
                                  <div className="w-full pr-2">
                                    <Dropdown
                                      options={galaxiesOptions}
                                      label="Galaxy"
                                      className={`w-full font-medium disabled:hide-arrow-select disabled:bg-white disabled:border-white disabled:shadow-none disabled:focus:ring-0 disabled:text-gray-900 text-gray-600 text-center sm:text-left ${
                                        taskErrors.galaxy_id
                                          ? "border-red-500"
                                          : ""
                                      } `}
                                      registerFct={() =>
                                        taskRegister("galaxy_id", {
                                          // required: true,
                                          // validate: (value) => value !== "0",
                                        })
                                      }
                                      asDefaultValue={false}
                                    />
                                  </div>
                                  <div className="w-full pl-2">
                                    <Dropdown
                                      options={taskQueuesOptions}
                                      label="Task Queue"
                                      className={`w-full font-medium disabled:hide-arrow-select disabled:bg-white disabled:border-white disabled:shadow-none disabled:focus:ring-0 disabled:text-gray-900 text-gray-600 text-center sm:text-left ${
                                        taskErrors.taskqueue_id
                                          ? "border-red-500"
                                          : ""
                                      } `}
                                      // style={{
                                      //   border: contactErrors.taskqueue_id
                                      //     ? "1.5px solid rgb(239 68 68)"
                                      //     : "",
                                      // }}
                                      registerFct={() =>
                                        taskRegister("taskqueue_id", {
                                          // required: true,
                                          // validate: (value) => value !== "0",
                                        })
                                      }
                                      asDefaultValue={
                                        taskWatch("galaxy_id") !== "0"
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                </div>
                              </form>
                            </div>
                            <div className="flex justify-end m-auto mt-4">
                              <button
                                className="btn-primary-fill"
                                type="submit"
                              >
                                {t("pages.dashboard.createContact.button")}
                              </button>
                            </div>
                          </form>
                        </IsLoading>
                      </div>

                      <button
                        className="z-50 absolute top-2 right-4"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          handleClose();
                        }}
                      >
                        <XMarkIcon className="w-6 h-6" />
                      </button>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
    </>
  );
};

export default CreateContactPopup;
