import * as AllIcons from "@heroicons/react/24/outline";
import "../../../index.css";

export type IconName = keyof typeof AllIcons;

type IconProps = {
  icon: IconName;
  className: string;
};

const Icon = ({ icon, className }: IconProps) => {
  const SingleIcon = AllIcons[icon];

  return <SingleIcon className={className} />;
};

export default Icon;
