import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Input from "../../../common/localComponents/Input/Input";
import {
  forgottenPassword,
  IForgottenPasswordFormData,
} from "../state/authSlice";
import { IsLoading } from "../../../common/localComponents/AppState/IsLoading";
import { Error } from "../../../common/localComponents/AppState/Error";
import { AppDispatch } from "../../../common/state/store";
import { useDispatch } from "react-redux";

export const FORGOTTEN_PASSWORD_COMPONENT_ID = "forgottenPasswordComponent";

interface IForgottenPasswordForm {
  labels: {
    title: string;
    emailPlaceholder: string;
    cancel: string;
    resetPassword: string;
  };
  onSubmit: (data: any) => void;
}

const ForgottenPasswordContainer = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const labels = {
    title: t("pages.forgottenPassword.title"),
    emailPlaceholder: t("pages.forgottenPassword.emailPlaceholder"),
    cancel: t("general.cancel"),
    resetPassword: t("pages.forgottenPassword.resetPassword"),
  };

  const onSubmit = async (data: IForgottenPasswordFormData) => {
    await dispatch(
      forgottenPassword({
        componentId: FORGOTTEN_PASSWORD_COMPONENT_ID,
        ...data,
      })
    );
  };

  return <ForgottenPassword labels={labels} onSubmit={onSubmit} />;
};

const ForgottenPassword = ({ labels, onSubmit }: IForgottenPasswordForm) => {
  const { register, handleSubmit } = useForm<IForgottenPasswordFormData>();

  return (
    <div
      className="flex h-screen bg-cover"
    >
      <div className="flex flex-1 max-w-xs md:max-w-lg mx-auto items-center justify-center ">
        <form className="w-full sm:w-3/4" onSubmit={handleSubmit(onSubmit)}>
          <div className="border flex flex-col bg-white border-gray-200 rounded-xl shadow-md py-3 px-4">
            <div className="my-2">
              <h2 className="text-xl font-medium text-black">{labels.title}</h2>
            </div>
            <Error componentId={FORGOTTEN_PASSWORD_COMPONENT_ID} />
            <IsLoading componentId={FORGOTTEN_PASSWORD_COMPONENT_ID} />

            <Input
              type="email"
              label="Email"
              placeholder={labels.emailPlaceholder}
              registerFct={() =>
                register("email", { required: true, pattern: /^\S+@\S+$/i })
              }
            />
            <div className="flex flex-col sm:flex-row sm:pt-8 sm:justify-between">
              <button className="btn-alternative-outline mb-2 sm:mb-0">
                <Link to="/"> {labels.cancel}</Link>
              </button>
              <button className="btn-primary-outline" type="submit">
                {labels.title}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgottenPasswordContainer;
