import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, useFormState } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import React from "react";
import logo from "../../../assets/logo.png";
import { AppDispatch, RootState } from "../../../common/state/store";
import { errorSelector } from "../../../common/state/slice/appStateSlice";
import { jwtSelector } from "../../../common/state/selectors/authSelector";
import { IsLoading } from "../../../common/localComponents/AppState/IsLoading";
import { Input, InputIcon } from "../../../common/localComponents/Input";
export const LOGIN_COMPONENT_ID = "loginComponent";
import Icon, { IconName } from "../../../common/localComponents/Icon/Icon";
import { SettingsSidebarItem } from "./settingsSidebarItem";
import { use } from "i18next";
import {
  deleteMessageTemplate,
  deleteTaskQueue,
  getCurrentMessageTemplates,
  getMessageTemplates,
  updateTaskQueues,
} from "../../../features/contacts/state/contactsSlice";
import { Transition } from "@headlessui/react";
import {
  FaCaretDown,
  FaCaretUp,
  FaMinus,
  FaPencilAlt,
  FaPlus,
  FaPlusCircle,
  FaRegArrowAltCircleDown,
  FaRegArrowAltCircleUp,
  FaTrash,
} from "react-icons/fa";
import { TaskqueueToEditForm } from "./TaskqueueToEditForm";
import {
  closeStandardModal,
  openStandardModal,
} from "../../../common/state/slice/modal/modalSlice";
import NewTaskqueuePopup from "../../../common/localComponents/Modal/forTaskqueue/NewTaskqueuePopup";
import { set } from "lodash";
import EditTaskqueuePopup from "../../../common/localComponents/Modal/forTaskqueue/EditTaskqueuePopup";
import { ModalConfirmation } from "../../../common/localComponents/Modal";
import NewMessageTemplatePopup from "../../../common/localComponents/Modal/forMessageTemplates/NewMessageTemplatesPopup";
import EditMessageTemplatePopup from "../../../common/localComponents/Modal/forMessageTemplates/EditMessageTemplatesPopup";
export const MESSAGETEMPLATES_COMPONENT_ID = "messageTemplatesComponent";

type SettingsSideBarLinkProps = {
  title: string;
  iconName: IconName;
  onClick(): void;
};

const MessageTemplates = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const { messageTemplates } = useSelector((state: RootState) => {
    return {
      messageTemplates: getCurrentMessageTemplates(state),
    };
  });

  const [taskqueueCollapseStates, setTaskqueueCollapseStates] = useState<
    Record<number, boolean>
  >({});

  // State to track collapse status of messageTemplates
  const [messageTemplatesCollapseStates, setMessageTemplatesCollapseStates] =
    useState<Record<number, boolean>>({});

  const [chosenMessageTemplate, setChosenMessageTemplate] = useState({
    id: 0,
    name: "",
  });
  const [chosenTaskqueue, setChosenTaskqueue] = useState({ id: 0, name: "" });

  const toggleMessageTemplateCollapse = (messageTemplateId: number) => {
    setMessageTemplatesCollapseStates((prevState) => ({
      ...prevState,
      [messageTemplateId]: !prevState[messageTemplateId],
    }));
  };

  useEffect(() => {
    if (messageTemplates && messageTemplates.length > 0) {
      const initialCollapseStates: Record<number, boolean> = {};
      const shouldCollapse = messageTemplates.length > 3; // Collapse only if more than 3 messageTemplates

      messageTemplates.forEach((messageTemplate: any) => {
        if (chosenMessageTemplate.id === messageTemplate.id) {
          initialCollapseStates[messageTemplate.id] = false; // Set based on the condition
        } else {
          initialCollapseStates[messageTemplate.id] = shouldCollapse; // Set based on the condition
        }
      });

      setMessageTemplatesCollapseStates(initialCollapseStates);
    }
  }, [messageTemplates]);

  useEffect(() => {
    dispatch(
      getMessageTemplates({ componentId: MESSAGETEMPLATES_COMPONENT_ID })
    );
  }, []);

  return (
    <>
      <NewMessageTemplatePopup id="modalNewMessageTemplatePopup" />
      <EditMessageTemplatePopup
        id="modalEditMessageTemplatePopup"
        messageTemplate={chosenMessageTemplate}
      />

      {/* <ModalConfirmation
        id="removeTheTaksqueue"
        onClick={() => {
          dispatch(closeStandardModal("removeTheTaksqueue"));
          dispatch(
            deleteTaskQueue({
              taskqueueId: chosenTaskqueue?.id,
            })
          );
        }}
        title={t("pages.settings.galaxyConfiguration.deleteTaskqueue.title")}
        text={t("pages.settings.galaxyConfiguration.deleteTaskqueue.text")}
      /> */}
      <ul className="mb-24">
        <div className="flex w-full mb-4">
          <h3 className="flex-1 text-4xl font-semibold text-start ">
            My Message Templates:
          </h3>
          <div className="flex flex-1 justify-end my-auto">
            <button
              className="btn-primary-fill text-white bg-blue-500 hover:bg-blue-600 focus-visible:ring-blue-500"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                dispatch(openStandardModal("modalNewMessageTemplatePopup"));
              }}
            >
              Create New Message Template
            </button>
          </div>
        </div>

        <>
          {messageTemplates && messageTemplates.length > 0 && (
            <>
              <form>
                <div className="border-t-2 py-4 mt-2">
                  <IsLoading
                    componentId={MESSAGETEMPLATES_COMPONENT_ID}
                    showSuccess={false}
                  >
                    <div className="flex flex-col w-full">
                      {[...messageTemplates]
                        ?.sort((a: any, b: any) => a.order - b.order) // Sort taskqueues by alias
                        .map((messageTemplate: any) => (
                          <div className="border-b-2 py-4 mb-2">
                            <div className="flex w-full">
                              <div className="flex flex-col flex-1">
                                <h3 className="text-2xl font-semibold text-start">
                                  {messageTemplate.name}
                                </h3>
                                <h2 className="text-lg font-normal text-start mb-4">
                                  {messageTemplate.description}
                                </h2>
                              </div>
                              <div className="flex items-center">
                                {messageTemplate &&
                                  messageTemplate.length !== 0 && (
                                    <button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        toggleMessageTemplateCollapse(
                                          messageTemplate.id
                                        );
                                      }}
                                      className="text-gray-500 hover:text-gray-700 mr-4 border-r pr-4"
                                    >
                                      {messageTemplatesCollapseStates[
                                        messageTemplate.id
                                      ] ? (
                                        <FaCaretDown size={30} />
                                      ) : (
                                        <FaCaretUp size={30} />
                                      )}
                                    </button>
                                  )}
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();

                                    setChosenMessageTemplate(messageTemplate);

                                    dispatch(
                                      openStandardModal(
                                        "modalEditMessageTemplatePopup"
                                      )
                                    );
                                  }}
                                  className="text-gray-500  hover:text-gray-700"
                                >
                                  <FaPencilAlt size={20} />
                                </button>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault(); // Prevent default form submission or any other default action
                                    dispatch(
                                      deleteMessageTemplate({
                                        messageTemplateId: messageTemplate?.id,
                                      })
                                    );
                                  }}
                                  className="text-red-500 hover:text-red-700 ml-2"
                                >
                                  <FaTrash size={20} />
                                </button>
                              </div>
                            </div>
                            <Transition
                              as="div"
                              show={
                                !messageTemplatesCollapseStates[
                                  messageTemplate.id
                                ]
                              }
                              className="transition-all duration-500 overflow-hidden"
                              enterFrom="transform scale-95 opacity-0 max-h-0"
                              enterTo="transform scale-100 opacity-100 max-h-[100%]"
                              leaveFrom="transform scale-100 opacity-100 max-h-[100%]"
                              leaveTo="transform scale-95 opacity-0 max-h-0"
                            >
                              <div className="ml-6">
                                <div className="flex flex-col w-full">
                                  <div className="flex flex-col w-full mb-2">
                                    <h4 className="flex-1 text-lg font-semibold text-start">
                                      Default Message:
                                    </h4>
                                    <p>
                                      {messageTemplate.defaultmessage
                                        ? messageTemplate.defaultmessage
                                        : "No default message"}
                                    </p>
                                  </div>
                                  <div className="flex flex-col w-full mb-2">
                                    <h4 className="flex-1 text-lg font-semibold text-start">
                                      Prompt to customize the message:
                                    </h4>
                                    <p>
                                      {messageTemplate.prompttocustomizethemessage
                                        ? messageTemplate.prompttocustomizethemessage
                                        : "No prompt to customize the message"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Transition>
                          </div>
                        ))}
                    </div>
                  </IsLoading>
                </div>
              </form>
            </>
          )}
        </>
      </ul>
    </>
  );
};

export default MessageTemplates;
