import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../state/hooks";
import { RootState } from "../../../state/store";
import { closeStandardModal } from "../../../state/slice/modal/modalSlice";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { Dropdown, Input } from "../../Input";
import { DynamicTextarea } from "../../Textarea/DynamicTextarea";
import { useTranslation } from "react-i18next";
import {
  createNewTask,
  generateMessage,
  getMessageTemplates,
  getSpecificMessageTemplate,
  updateContact,
} from "../../../../features/contacts/state/contactsSlice";
import { IsLoading } from "../../AppState/IsLoading";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { MESSAGETEMPLATES_COMPONENT_ID } from "../../../../pages/Settings/components/messageTemplates";
import { CONTACT_COMPONENT_ID } from "../../../../pages/Dashboard/dashboard";
import { ToggleSwitch } from "../../ToggleSwitch/ToggleSwitch";
import { stringify } from "querystring";

export const GENERATE_COMPONENT_ID = "generateComponent";
export const USELESS_COMPONENT_ID = "uselessComponent";

type EditContactPopupProps = {
  id: string;
  contact: any;
};

const DraftMessagePopup = ({ id, contact }: EditContactPopupProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const modalState = useSelector(
    (state: RootState) => state.modals.showStandardModal
  );

  const [isLinksCollapsed, setIsLinksCollapsed] = useState(false); // New state for collapsed status

  // Toggle collapse state
  const toggleLinksCollapse = () => {
    setIsLinksCollapsed(!isLinksCollapsed);
  };

  const handleClose = () => {
    dispatch(closeStandardModal(id));
    generateMessageReset(); // Reset form state
    taskReset(); // Reset form state
    setGenerationCompleted(false);
  };

  const [messageTemplateOptions, setMessageTemplateOptions] = useState<any[]>(
    []
  ); // [{ value: "1", optionText: "Published" }, { value: "2", optionText: "Draft" }]

  const [generationCompleted, setGenerationCompleted] = useState(false);

  const {
    control: generateMessageControl,
    handleSubmit: generateMessageHandleSubmit,
    register: generateMessageRegister,
    watch: generateMessageWatch,
    reset: generateMessageReset,
    formState: { errors: generateMessageErrors },
  } = useForm();

  const {
    control: taskControl,
    handleSubmit: taskHandleSubmit,
    register: taskRegister,
    watch: taskWatch,
    reset: taskReset,
    formState: { errors: taskErrors },
  } = useForm();

  const onGenerateMessageSubmit = (data: any) => {
    const newGenerateMessageData = {
      ...data,
      contacts_id: contact.id,
      messagetemplates_id: parseInt(data.messagetemplates_id),
      length: parseInt(data.length),
    };

    dispatch(
      generateMessage({
        componentId: GENERATE_COMPONENT_ID,
        data: newGenerateMessageData,
      })
    ).then((res: any) => {
      const message = res.payload.outputmessage.message;
      const debug = res.payload.outputmessage.debug;

      console.log("debug", debug);
      console.log("message", message);

      if (res.payload) {
        setGenerationCompleted(true);

        taskReset({
          description: message,
        });
      }
    });
  };

  const onTaskSubmit = (data: any) => {
    // Get the current date/time
    const currentDate = new Date().getTime();

    // Create the new task data structure
    const newTaskData = {
      name: "Wrote this message",
      description: String(data.description),
      taskqueue_id: parseInt(contact.nexttaskqueue_id),
      duedate: currentDate,
      status: "done",
      contacts_id: contact.id, // Add the contactId to the data if it's available
    };

    // Dispatch the action to create a new task with the modified data

    dispatch(closeStandardModal({ id }));

    console.log("test newTaskData", newTaskData);

    dispatch(
      createNewTask({ componentId: CONTACT_COMPONENT_ID, data: newTaskData })
    ).then((res: any) => {
      setGenerationCompleted(false);
    });
  };

  useEffect(() => {
    setGenerationCompleted(false);
  }, [contact]);

  const messageTemplateId = generateMessageWatch("messagetemplates_id");
  // Watch the state of toggle switch fields
  const usesocialprofiltocustomize = generateMessageWatch(
    "usesocialprofiltocustomize"
  );
  const usemessagehistorytocustomize = generateMessageWatch(
    "usemessagehistorytocustomize"
  );
  const usecontactinfostocustomize = generateMessageWatch(
    "usecontactinfostocustomize"
  );

  useEffect(() => {
    if (messageTemplateId) {
      dispatch(
        getSpecificMessageTemplate({
          messageTemplateId: generateMessageWatch("messagetemplates_id"),
          componentId: USELESS_COMPONENT_ID,
        })
      ).then((res: any) => {
        const messageTemplate = res.payload;

        if (messageTemplate) {
          generateMessageReset({
            ...generateMessageWatch(),
            custommessage: messageTemplate.defaultmessage,
            customprompt: messageTemplate.prompttocustomizethemessage,
            usesocialprofiltocustomize:
              messageTemplate.usesocialprofiltocustomize,
            usemessagehistorytocustomize:
              messageTemplate.usemessagehistorytocustomize,
            usecontactinfostocustomize:
              messageTemplate.usecontactinfostocustomize,
          });
        }
      });
    }
  }, [messageTemplateId]);

  useEffect(() => {
    dispatch(getMessageTemplates({ componentId: USELESS_COMPONENT_ID })).then(
      (res: any) => {
        const options = res.payload.map((item: any) => {
          return {
            value: item.id,
            optionText: item.name,
          };
        });

        setMessageTemplateOptions(options);
      }
    );
  }, []);

  return (
    <>
      {id === modalState.id && (
        <Transition appear show={modalState.show} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 overflow-y-auto"
            onClose={() => dispatch(closeStandardModal({ id }))}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className={"fixed inset-0 bg-black bg-opacity-25"} />
            </Transition.Child>
            <div className={`fixed inset-0`}>
              <div
                className={
                  "flex items-center justify-center text-center h-full"
                }
              >
                <div className={"mt-auto mb-auto w-full"}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="transform overflow-y-auto max-h-[80vh] mx-auto w-full md:w-10/12 max-w-3xl rounded-2xl bg-slate-100 p-6 text-left align-middle shadow-xl transition-all">
                      <div className="relative grow w-full max-w-9xl px-4 xl:px-0 mx-auto">
                        <div className="w-full h-full">
                          <div className="flex">
                            <h3 className="flex-1 text-2xl font-semibold text-start mb-4">
                              Draft Message
                            </h3>
                          </div>
                          <form
                            onSubmit={generateMessageHandleSubmit(
                              onGenerateMessageSubmit
                            )}
                          >
                            <div className="flex flex-col">
                              <div className="flex mb-2">
                                <div className="w-full pr-2">
                                  <Dropdown
                                    options={messageTemplateOptions}
                                    label="Message Template"
                                    className={`w-full font-medium disabled:hide-arrow-select disabled:bg-white disabled:border-white disabled:shadow-none disabled:focus:ring-0 disabled:text-gray-900 text-gray-600 text-center sm:text-left ${
                                      generateMessageErrors.messagetemplates_id
                                        ? "border-red-500"
                                        : ""
                                    } `}
                                    // style={{
                                    //   border: contactErrors.taskqueue_id
                                    //     ? "1.5px solid rgb(239 68 68)"
                                    //     : "",
                                    // }}
                                    registerFct={() =>
                                      generateMessageRegister(
                                        "messagetemplates_id",
                                        {
                                          // required: true,
                                          // validate: (value) => value !== "0",
                                        }
                                      )
                                    }
                                    // asDefaultValue={
                                    //   taskWatch("galaxy_id") !== "0"
                                    //     ? true
                                    //     : false
                                    // }
                                  />
                                </div>
                                <div className="w-full pl-2">
                                  <Input
                                    type="number"
                                    label="Length"
                                    placeholder={"Enter length"}
                                    registerFct={() =>
                                      generateMessageRegister("length")
                                    }
                                    defaultValue={200}
                                  />
                                </div>
                              </div>
                              <div className="flex mt-2">
                                <DynamicTextarea
                                  registerFct={() =>
                                    generateMessageRegister("custommessage")
                                  }
                                  label={"Default Message"}
                                  // style={{ resize: notEditable ? "none" : "vertical" }}
                                  defaultHeight={60}
                                  style={{
                                    resize: "none",
                                    height: "auto", //notEditable ? "auto" :
                                  }}
                                  className={`min-h-[38px] w-full read-only:cursor-default font-medium read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600 text-center sm:text-left ${
                                    generateMessageErrors.custommessage
                                      ? "border-red-500"
                                      : ""
                                  } `}
                                  placeholder={t(
                                    "pages.settings.messageTemplates.newMessageTemplate.inputs.defaultmessagePlaceholder"
                                  )}
                                />
                              </div>
                              <div className="flex mt-2">
                                <DynamicTextarea
                                  registerFct={() =>
                                    generateMessageRegister("customprompt")
                                  }
                                  label={"Prompt to customize the message"}
                                  // style={{ resize: notEditable ? "none" : "vertical" }}
                                  defaultHeight={60}
                                  style={{
                                    resize: "none",
                                    height: "auto", //notEditable ? "auto" :
                                  }}
                                  className={`min-h-[38px] w-full read-only:cursor-default font-medium read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600 text-center sm:text-left ${
                                    generateMessageErrors.customprompt
                                      ? "border-red-500"
                                      : ""
                                  } `}
                                  placeholder={t(
                                    "pages.settings.messageTemplates.newMessageTemplate.inputs.prompttocustomizethemessagePlaceholder"
                                  )}
                                />
                              </div>
                              <div className="flex">
                                <ToggleSwitch
                                  id="usesocialprofiltocustomize"
                                  label={"Use social profile to customize"}
                                  registerFct={() =>
                                    generateMessageRegister(
                                      "usesocialprofiltocustomize"
                                    )
                                  }
                                  watchFct={() =>
                                    generateMessageWatch(
                                      "usesocialprofiltocustomize"
                                    )
                                  }
                                  checked={usesocialprofiltocustomize}
                                  className="mr-4"
                                />
                                <ToggleSwitch
                                  id="usemessagehistorytocustomize"
                                  label={"Use message history to customize"}
                                  registerFct={() =>
                                    generateMessageRegister(
                                      "usemessagehistorytocustomize"
                                    )
                                  }
                                  watchFct={() =>
                                    generateMessageWatch(
                                      "usemessagehistorytocustomize"
                                    )
                                  }
                                  checked={usemessagehistorytocustomize}
                                />
                              </div>
                              <ToggleSwitch
                                id="usecontactinfostocustomize"
                                label={"Use contact info to customize"}
                                registerFct={() =>
                                  generateMessageRegister(
                                    "usecontactinfostocustomize"
                                  )
                                }
                                watchFct={() =>
                                  generateMessageWatch(
                                    "usecontactinfostocustomize"
                                  )
                                }
                                checked={usecontactinfostocustomize}
                              />
                            </div>

                            <div className="flex justify-end m-auto mt-4">
                              <IsLoading
                                componentId={GENERATE_COMPONENT_ID}
                                showSuccess={false}
                              >
                                <button
                                  className="btn-primary-fill"
                                  type="submit"
                                >
                                  {!generationCompleted
                                    ? t("pages.dashboard.draftMessage.button")
                                    : t(
                                        "pages.dashboard.draftMessage.regenerate"
                                      )}
                                </button>
                              </IsLoading>
                            </div>
                          </form>
                          <>
                            {generationCompleted && (
                              <form
                                className="flex flex-col w-full"
                                onSubmit={taskHandleSubmit(onTaskSubmit)}
                              >
                                <div className="flex">
                                  <DynamicTextarea
                                    registerFct={() =>
                                      taskRegister("description")
                                    }
                                    label={"Here is your generated message"}
                                    // style={{ resize: notEditable ? "none" : "vertical" }}
                                    style={{
                                      resize: "none",
                                      height: "auto", //notEditable ? "auto" :
                                    }}
                                    className={`min-h-[38px] w-full read-only:cursor-default font-medium read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600 text-center sm:text-left ${
                                      taskErrors.description
                                        ? "border-red-500"
                                        : ""
                                    } `}
                                    placeholder={"Here is your message"}
                                  />
                                </div>
                                <div className="flex justify-end m-auto mt-4">
                                  <button
                                    className="btn-primary-fill"
                                    type="submit"
                                  >
                                    Create a done task
                                  </button>
                                </div>
                              </form>
                            )}
                          </>
                        </div>
                      </div>

                      <button
                        className="z-50 absolute top-2 right-4"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          handleClose();
                        }}
                      >
                        <XMarkIcon className="w-6 h-6" />
                      </button>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
    </>
  );
};

export default DraftMessagePopup;
