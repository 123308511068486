import { UseFormRegisterReturn } from "react-hook-form";
import "../../../index.css";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    ReactHookFormInput {
  label?: string;
}

export interface ReactHookFormInput {
  registerFct?: () => UseFormRegisterReturn;
}

const Input = ({ label, registerFct, ...props }: InputProps) => {
  return (
    <>
      {label && (
        <div className="w-full">
          <label className="block text-sm font-medium text-gray-700">{label}</label>
          <div className="mt-1">
            <input {...props} {...(registerFct && registerFct())} />
          </div>
        </div>
      )}
      {!label && <input {...props} {...(registerFct && registerFct())} />}
    </>
  );
};

export default Input;
