import { Outlet } from "react-router-dom";
import Sidebar from "../localComponents/Sidebar/Sidebar";
import Topbar from "../localComponents/Topbar/Topbar";
import { PrivatePage } from "./PrivatePage";

const Layout = () => {
  return (
    <PrivatePage>
      <div className="flex flex-col w-full min-h-screen bg-slate-100">
        <Topbar />
        <main className="flex-grow overflow-auto">
          <Outlet />
        </main>
      </div>
    </PrivatePage>
  );
};

export default Layout;
