import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../state/hooks";
import { RootState } from "../../../state/store";
import { closeStandardModal } from "../../../state/slice/modal/modalSlice";
import { XMarkIcon } from "@heroicons/react/24/outline";
import ReactPlayer from "react-player";
import { Spinner } from "../../Loader/Spinner";
import { useForm } from "react-hook-form";
import { Dropdown, Input } from "../../Input";
import { DynamicTextarea } from "../../Textarea/DynamicTextarea";
import { useTranslation } from "react-i18next";
import {
  createNewTask,
  getAllTaskQueues,
  getCurrentGalaxies,
  getCurrentSpecificTask,
  getCurrentTaskQueues,
  getSpecificTask,
  updateGalaxy,
  updateTask,
} from "../../../../features/contacts/state/contactsSlice";
import { IsLoading } from "../../AppState/IsLoading";
import { CONTACT_COMPONENT_ID } from "../../../../pages/Dashboard/dashboard";

export const TASKQUEUES_COMPONENT_ID = "taskQueuesComponent";

type EditGalaxyPopupProps = {
  id: string;
  galaxy: any;
};

const EditGalaxyPopup = ({ id, galaxy }: EditGalaxyPopupProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const modalState = useSelector(
    (state: RootState) => state.modals.showStandardModal
  );

  const [taskQueuesOptions, setTaskQueuesOptions] = useState<any[]>([]); // [{ value: "1", optionText: "Published" }
  const {
    control,
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: galaxy?.name,
      order: galaxy?.order,
      description: galaxy?.description,
    },
  });

  const onSubmit = (data: any) => {
    // Create the new task data structure
    const editGalaxyData = {
      ...galaxy,
      ...data,
    };

    // Dispatch the action to create a new task with the modified data

    dispatch(closeStandardModal({ id }));

    dispatch(
      updateGalaxy({
        data: editGalaxyData,
        galaxyId: galaxy.id,
      })
    );
  };

  const { galaxies, taskQueues } = useSelector((state: RootState) => {
    return {
      galaxies: getCurrentGalaxies(state),
      taskQueues: getCurrentTaskQueues(state),
    };
  });

  useEffect(() => {
    reset({
      name: galaxy?.name,
      order: galaxy?.order,
      description: galaxy?.description,
    });
  }, [galaxy]);

  return (
    <>
      {id === modalState.id && (
        <Transition appear show={modalState.show} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 overflow-y-auto"
            onClose={() => dispatch(closeStandardModal({ id }))}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className={"fixed inset-0 bg-black bg-opacity-25"} />
            </Transition.Child>
            <div className={`fixed inset-0`}>
              <div
                className={
                  "flex items-center justify-center p-4 text-center h-full"
                }
              >
                <div className={"mt-auto mb-auto"}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel
                      className={`h-full transform no-scrollbarw-halfscreen max-w-3xl rounded-2xl pt-auto pb-auto overflow-y-auto bg-slate-100 p-6 text-left align-middle shadow-xl transition-all`}
                    >
                      <div className="relative grow w-full max-w-9xl px-4 xl:px-0 mx-auto">
                        <IsLoading
                          componentId={TASKQUEUES_COMPONENT_ID}
                          showSuccess={false}
                        >
                          <form
                            className="w-full"
                            onSubmit={handleSubmit(onSubmit)}
                          >
                            <h3 className="text-2xl font-semibold text-start mb-4">
                              {t("pages.dashboard.editTask.title")}
                            </h3>
                            <div className="flex">
                              <div className="mr-4 w-72">
                                <Input
                                  registerFct={() =>
                                    register("name", {
                                      required: true,
                                    })
                                  }
                                  type="text"
                                  label={t(
                                    "pages.settings.galaxyConfiguration.newGalaxy.inputs.name"
                                  )}
                                  placeholder={t(
                                    "pages.settings.galaxyConfiguration.newGalaxy.inputs.namePlaceholder"
                                  )}
                                  className={`w-full ${
                                    errors.name ? "border-red-500" : ""
                                  }`}
                                />
                              </div>
                              <div className="w-72">
                                <Input
                                  registerFct={() =>
                                    register("order", {
                                      required: true,
                                    })
                                  }
                                  type="number"
                                  label={t(
                                    "pages.settings.galaxyConfiguration.newGalaxy.inputs.order"
                                  )}
                                  placeholder={t(
                                    "pages.settings.galaxyConfiguration.newGalaxy.inputs.orderPlaceholder"
                                  )}
                                  className={`w-full ${
                                    errors.order ? "border-red-500" : ""
                                  }`}
                                />
                              </div>
                            </div>
                            <div className="flex mt-2">
                              <DynamicTextarea
                                registerFct={() => register("description")}
                                label={"Description"}
                                // style={{ resize: notEditable ? "none" : "vertical" }}
                                defaultHeight={60}
                                style={{
                                  resize: "none",
                                  height: "auto", //notEditable ? "auto" :
                                }}
                                className={`min-h-[38px] w-full read-only:cursor-default font-medium read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600 text-center sm:text-left ${
                                  errors.description ? "border-red-500" : ""
                                } `}
                                placeholder={t(
                                  "pages.settings.galaxyConfiguration.newGalaxy.inputs.descriptionPlaceholder"
                                )}
                              />
                            </div>
                            <div className="flex justify-end m-auto mt-4">
                              <button
                                className="btn-primary-fill"
                                type="submit"
                              >
                                {t("pages.dashboard.editTask.button")}
                              </button>
                            </div>
                          </form>
                        </IsLoading>
                      </div>

                      <button
                        className="z-50 absolute top-2 right-4"
                        type="button"
                        onClick={() => {
                          dispatch(closeStandardModal({ id }));
                          reset();
                        }}
                      >
                        <XMarkIcon className="w-6 h-6" />
                      </button>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
    </>
  );
};

export default EditGalaxyPopup;
