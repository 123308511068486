import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../state/hooks";
import { RootState } from "../../../state/store";
import { closeStandardModal } from "../../../state/slice/modal/modalSlice";
import { XMarkIcon } from "@heroicons/react/24/outline";
import ReactPlayer from "react-player";
import { Spinner } from "../../Loader/Spinner";
import { useForm } from "react-hook-form";
import { Dropdown, Input } from "../../Input";
import { DynamicTextarea } from "../../Textarea/DynamicTextarea";
import { useTranslation } from "react-i18next";
import {
  getAllTaskQueues,
  getCurrentMessageTemplates,
  getCurrentTaskQueues,
  updateMessageTemplate,
} from "../../../../features/contacts/state/contactsSlice";
import { IsLoading } from "../../AppState/IsLoading";
import { DropdownManyChoices } from "../../Input/DropdownManyChoices";
import { MESSAGETEMPLATES_COMPONENT_ID } from "../../../../pages/Settings/components/messageTemplates";
import { ToggleSwitch } from "../../ToggleSwitch/ToggleSwitch";
import { use } from "i18next";

export const TASKQUEUES_COMPONENT_ID = "taskQueuesComponent";

type EditTaskPopupProps = {
  id: string;
  messageTemplate?: any;
};

const EditMessageTemplatePopup = ({
  id,
  messageTemplate,
}: EditTaskPopupProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const modalState = useSelector(
    (state: RootState) => state.modals.showStandardModal
  );

  const {
    control,
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  // Watch the state of toggle switch fields
  const usesocialprofiltocustomize = watch("usesocialprofiltocustomize");
  const usemessagehistorytocustomize = watch("usemessagehistorytocustomize");
  const usecontactinfostocustomize = watch("usecontactinfostocustomize");

  const onSubmit = (data: any) => {
    // Create the new messageTemplate data structure
    const messageTemplateData = {
      ...data,
    };
    console.log("test messageTemplateData", messageTemplateData);
    // Dispatch the action to create a edit task with the modified data

    dispatch(closeStandardModal({ id }));

    dispatch(
      updateMessageTemplate({
        componentId: MESSAGETEMPLATES_COMPONENT_ID,
        data: messageTemplateData,
        messageTemplateId: messageTemplate.id,
      })
    );
  };

  const { messageTemplates, taskQueues } = useSelector((state: RootState) => {
    return {
      taskQueues: getCurrentTaskQueues(state),
      messageTemplates: getCurrentMessageTemplates(state),
    };
  });

  useEffect(() => {
    reset(messageTemplate);
  }, [messageTemplate]);

  return (
    <>
      {id === modalState.id && (
        <Transition appear show={modalState.show} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 overflow-y-auto"
            onClose={() => dispatch(closeStandardModal({ id }))}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className={"fixed inset-0 bg-black bg-opacity-25"} />
            </Transition.Child>
            <div className={`fixed inset-0`}>
              <div
                className={
                  "flex items-center justify-center p-4 text-center h-full"
                }
              >
                <div className={"mt-auto mb-auto"}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel
                      className={`h-full transform no-scrollbarw-halfscreen max-w-3xl rounded-2xl pt-auto pb-auto overflow-y-auto bg-slate-100 p-6 text-left align-middle shadow-xl transition-all`}
                    >
                      <div className="relative grow w-full max-w-9xl px-4 xl:px-0 mx-auto">
                        <IsLoading
                          componentId={TASKQUEUES_COMPONENT_ID}
                          showSuccess={false}
                        >
                          <form
                            className="w-full"
                            onSubmit={handleSubmit(onSubmit)}
                          >
                            <h3 className="text-2xl font-semibold text-start mb-4">
                              {t(
                                "pages.settings.messageTemplates.editMessageTemplate.title"
                              )}
                            </h3>
                            <div className="flex">
                              <Input
                                registerFct={() =>
                                  register("name", {
                                    required: true,
                                  })
                                }
                                type="text"
                                label={t(
                                  "pages.settings.messageTemplates.editMessageTemplate.inputs.name"
                                )}
                                placeholder={t(
                                  "pages.settings.messageTemplates.editMessageTemplate.inputs.namePlaceholder"
                                )}
                                className={`w-full ${
                                  errors.name ? "border-red-500" : ""
                                }`}
                              />
                            </div>
                            <div className="flex mt-2">
                              <DynamicTextarea
                                registerFct={() => register("defaultmessage")}
                                label={"Default Message"}
                                // style={{ resize: notEditable ? "none" : "vertical" }}
                                defaultHeight={60}
                                style={{
                                  resize: "none",
                                  height: "auto", //notEditable ? "auto" :
                                }}
                                className={`min-h-[38px] w-full read-only:cursor-default font-medium read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600 text-center sm:text-left ${
                                  errors.description ? "border-red-500" : ""
                                } `}
                                placeholder={t(
                                  "pages.settings.messageTemplates.editMessageTemplate.inputs.defaultmessagePlaceholder"
                                )}
                              />
                            </div>
                            <div className="flex mt-2">
                              <DynamicTextarea
                                registerFct={() =>
                                  register("prompttocustomizethemessage")
                                }
                                label={"Prompt to customize the message"}
                                // style={{ resize: notEditable ? "none" : "vertical" }}
                                defaultHeight={60}
                                style={{
                                  resize: "none",
                                  height: "auto", //notEditable ? "auto" :
                                }}
                                className={`min-h-[38px] w-full read-only:cursor-default font-medium read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600 text-center sm:text-left ${
                                  errors.description ? "border-red-500" : ""
                                } `}
                                placeholder={t(
                                  "pages.settings.messageTemplates.editMessageTemplate.inputs.prompttocustomizethemessagePlaceholder"
                                )}
                              />
                            </div>
                            <div className="flex">
                              <ToggleSwitch
                                id="usesocialprofiltocustomize"
                                label={"Use social profile to customize"}
                                registerFct={() =>
                                  register("usesocialprofiltocustomize")
                                }
                                watchFct={() =>
                                  watch("usesocialprofiltocustomize")
                                }
                                checked={usesocialprofiltocustomize}
                                className="mr-4"
                              />
                              <ToggleSwitch
                                id="usemessagehistorytocustomize"
                                label={"Use message history to customize"}
                                registerFct={() =>
                                  register("usemessagehistorytocustomize")
                                }
                                watchFct={() =>
                                  watch("usemessagehistorytocustomize")
                                }
                                checked={usemessagehistorytocustomize}
                              />
                            </div>
                            <ToggleSwitch
                              id="usecontactinfostocustomize"
                              label={"Use contact info to customize"}
                              registerFct={() =>
                                register("usecontactinfostocustomize")
                              }
                              watchFct={() =>
                                watch("usecontactinfostocustomize")
                              }
                              checked={usecontactinfostocustomize}
                            />
                            <div className="flex justify-end m-auto mt-4">
                              <button
                                className="btn-primary-fill"
                                type="submit"
                              >
                                {t("general.update")}
                              </button>
                            </div>
                          </form>
                        </IsLoading>
                      </div>

                      <button
                        className="z-50 absolute top-2 right-4"
                        type="button"
                        onClick={() => {
                          dispatch(closeStandardModal({ id }));
                          reset();
                        }}
                      >
                        <XMarkIcon className="w-6 h-6" />
                      </button>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
    </>
  );
};

export default EditMessageTemplatePopup;
