import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../common/state/hooks";
import { DynamicTextarea } from "../../../common/localComponents/Textarea/DynamicTextarea";
import { updateContact } from "../../../features/contacts/state/contactsSlice";
import { CONTACT_COMPONENT_ID } from "../dashboard";

export function MoreInfoTab({ specificContact }: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    const newData = {
      ...specificContact,
      ...data,
    };

    dispatch(
      updateContact({
        componentId: CONTACT_COMPONENT_ID,
        contactId: specificContact.id,
        data: newData,
      })
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col w-full">
        <div className="flex w-full mb-4">
          <DynamicTextarea
            registerFct={() => register("importantpoint")}
            label={"What are the important point for him/her?"}
            style={{
              resize: "none",
              height: "auto",
            }}
            className={`min-h-[38px] w-full read-only:cursor-default font-medium read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600 text-center sm:text-left ${
              errors.importantpoint ? "border-red-500" : ""
            } `}
            placeholder={"Enter your answer"}
            defaultValue={specificContact.importantpoint}
          />
        </div>
        <div className="flex w-full mb-4">
          <DynamicTextarea
            registerFct={() => register("whatproblemtosolve")}
            label={"What problem does he /she tryies to solve?"}
            style={{
              resize: "none",
              height: "auto",
            }}
            className={`min-h-[38px] w-full read-only:cursor-default font-medium read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600 text-center sm:text-left ${
              errors.whatproblemtosolve ? "border-red-500" : ""
            } `}
            placeholder={"Enter your answer"}
            defaultValue={specificContact.whatproblemtosolve}
          />
        </div>
        <div className="flex w-full mb-4">
          <DynamicTextarea
            registerFct={() => register("otherinfo")}
            label={"Other info about him/her"}
            style={{
              resize: "none",
              height: "auto",
            }}
            className={`min-h-[38px] w-full read-only:cursor-default font-medium read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600 text-center sm:text-left ${
              errors.otherinfo ? "border-red-500" : ""
            } `}
            placeholder={"Enter your answer"}
            defaultValue={specificContact.otherinfo}
          />
        </div>
        <div className="flex w-full mb-4">
          <DynamicTextarea
            registerFct={() => register("whatdone")}
            label={"What has been done with this contact?"}
            style={{
              resize: "none",
              height: "auto",
            }}
            className={`min-h-[38px] w-full read-only:cursor-default font-medium read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600 text-center sm:text-left ${
              errors.whatdone ? "border-red-500" : ""
            } `}
            placeholder={"Enter your answer"}
            defaultValue={specificContact.whatdone}
          />
        </div>
        <div className="flex flex-1 justify-end my-auto">
          <button
            className="btn-primary-fill text-white bg-blue-500 hover:bg-blue-600 focus-visible:ring-blue-500"
            type="submit"
          >
            {t("general.update")}
          </button>
        </div>
      </div>
    </form>
  );
}
