import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../state/hooks";
import { RootState } from "../../../state/store";
import { closeStandardModal } from "../../../state/slice/modal/modalSlice";
import { XMarkIcon } from "@heroicons/react/24/outline";
import ReactPlayer from "react-player";
import { Spinner } from "../../Loader/Spinner";
import { useForm } from "react-hook-form";
import { Dropdown, Input } from "../../Input";
import { DynamicTextarea } from "../../Textarea/DynamicTextarea";
import { useTranslation } from "react-i18next";
import {
  createNewTask,
  createNewTaskQueue,
  getAllTaskQueues,
  getCurrentGalaxies,
  getCurrentTaskQueues,
} from "../../../../features/contacts/state/contactsSlice";
import { IsLoading } from "../../AppState/IsLoading";
import { GALAXIES_COMPONENT_ID } from "../../../../pages/Settings/components/galaxyConfiguration";
import { DropdownManyChoices } from "../../Input/DropdownManyChoices";

export const TASKQUEUES_COMPONENT_ID = "taskQueuesComponent";

type NewTaskqueuePopupProps = {
  id: string;
  galaxy?: any;
};

const NewTaskqueuePopup = ({ id, galaxy }: NewTaskqueuePopupProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const modalState = useSelector(
    (state: RootState) => state.modals.showStandardModal
  );

  //{
  //   value: x.id,
  //   label: x.email !== null ? x.email : `ID: ${x.id}`,
  // }
  const [galaxiesOption, setGalaxiesOptions] = useState<any[]>([]);

  const {
    control,
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    // Create the new task data structure

    let newGalaxies_ids: any[] = [];
    if (!data.galaxies_ids || data.galaxies_ids.length === 0) {
      newGalaxies_ids = [galaxy.id];
    } else {
      newGalaxies_ids = [...data.galaxies_ids, galaxy.id];
    }

    const newTaskqueueData = {
      ...data,
      galaxies_ids: newGalaxies_ids,
    };

    // Dispatch the action to create a new task with the modified data

    dispatch(closeStandardModal({ id }));

    dispatch(
      createNewTaskQueue({
        componentId: GALAXIES_COMPONENT_ID,
        data: newTaskqueueData,
      })
    );
  };

  const { galaxies, taskQueues } = useSelector((state: RootState) => {
    return {
      taskQueues: getCurrentTaskQueues(state),
      galaxies: getCurrentGalaxies(state),
    };
  });

  useEffect(() => {
    reset();

    if (galaxies && galaxies?.length !== 0) {
      const filteredGalaxies = galaxies.filter(
        (localGalaxy: any) => localGalaxy.id !== galaxy.id
      );
      setGalaxiesOptions(
        filteredGalaxies.map((localGalaxy: any) => ({
          value: localGalaxy.id,
          label: localGalaxy.name ?? `ID: ${localGalaxy.id}`,
        }))
      );
    }
  }, [galaxies, galaxy]);

  return (
    <>
      {id === modalState.id && (
        <Transition appear show={modalState.show} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 overflow-y-auto"
            onClose={() => dispatch(closeStandardModal({ id }))}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className={"fixed inset-0 bg-black bg-opacity-25"} />
            </Transition.Child>
            <div className={`fixed inset-0`}>
              <div
                className={
                  "flex items-center justify-center p-4 text-center h-full"
                }
              >
                <div className={"mt-auto mb-auto"}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel
                      className={`h-full transform no-scrollbarw-halfscreen max-w-3xl rounded-2xl pt-auto pb-auto overflow-y-auto bg-slate-100 p-6 text-left align-middle shadow-xl transition-all`}
                    >
                      <div className="relative grow w-full max-w-9xl px-4 xl:px-0 mx-auto">
                        <IsLoading
                          componentId={TASKQUEUES_COMPONENT_ID}
                          showSuccess={false}
                        >
                          <form
                            className="w-full"
                            onSubmit={handleSubmit(onSubmit)}
                          >
                            <h3 className="text-2xl font-semibold text-start">
                              {t(
                                "pages.settings.galaxyConfiguration.newTaskqueue.title"
                              )}{" "}
                            </h3>
                            <h2 className="text-lg font-semibold text-start mb-4">
                              {galaxy?.name}
                            </h2>
                            <div className="flex">
                              <div className="mr-4 w-72">
                                <Input
                                  registerFct={() =>
                                    register("name", {
                                      required: true,
                                    })
                                  }
                                  type="text"
                                  label={t(
                                    "pages.settings.galaxyConfiguration.newTaskqueue.inputs.name"
                                  )}
                                  placeholder={t(
                                    "pages.settings.galaxyConfiguration.newTaskqueue.inputs.namePlaceholder"
                                  )}
                                  className={`w-full ${
                                    errors.name ? "border-red-500" : ""
                                  }`}
                                />
                              </div>
                              <div className="w-72">
                                <Input
                                  registerFct={() =>
                                    register("alias", {
                                      required: true,
                                    })
                                  }
                                  type="number"
                                  label={t(
                                    "pages.settings.galaxyConfiguration.newTaskqueue.inputs.alias"
                                  )}
                                  placeholder={t(
                                    "pages.settings.galaxyConfiguration.newTaskqueue.inputs.aliasPlaceholder"
                                  )}
                                  className={`w-full ${
                                    errors.alias ? "border-red-500" : ""
                                  }`}
                                />
                              </div>
                            </div>
                            <div className="mt-2">
                              <DropdownManyChoices
                                label={t(
                                  "pages.settings.galaxyConfiguration.newTaskqueue.inputs.galaxies"
                                )}
                                name="galaxies_ids"
                                control={control}
                                options={galaxiesOption as any[]}
                                style={{
                                  border: errors.galaxies_ids
                                    ? "1.5px solid rgb(239 68 68)"
                                    : "",
                                }}
                              />
                            </div>
                            <div className="flex justify-end m-auto mt-4">
                              <button
                                className="btn-primary-fill mr-3"
                                type="submit"
                              >
                                {t("general.save")}
                              </button>
                              <button
                                className="btn-primary-fill"
                                onClick={(e) => {
                                  e.preventDefault();
                                  dispatch(closeStandardModal({ id }));
                                  reset();
                                }}
                              >
                                {t("general.cancel")}
                              </button>
                            </div>
                          </form>
                        </IsLoading>
                      </div>

                      <button
                        className="z-40 absolute top-2 right-4"
                        type="button"
                        onClick={() => {
                          dispatch(closeStandardModal({ id }));
                          reset();
                        }}
                      >
                        <XMarkIcon className="w-6 h-6" />
                      </button>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
    </>
  );
};

export default NewTaskqueuePopup;
