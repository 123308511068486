import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { TopbarItem, DropdownItem } from "./TopbarItem";
import { IconName } from "../Icon/Icon";
import { logoutAuth } from "../../../features/auth/state/authSlice";
import { clearAllContactsStates } from "../../../features/contacts/state/contactsSlice";
import logo from "../../../assets/logo.png";
import { Link } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";

type TopBarLinkProps = {
  title: string;
  iconName: IconName;
  routePath: string;
};

type TopBarDropdownLinkProps = {
  title: string;
  routePath: string;
};

const Topbar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef: any = useRef(null);

  // Function to handle outside click for closing dropdown
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const topbarLinks: TopBarLinkProps[] = [
    // { title: t("topbar.dashboard"), iconName: "HomeIcon", routePath: "./" },
    // { title: t("topbar.dashboard"), iconName: "HomeIcon", routePath: "./" },
    // { title: t("topbar.dashboard"), iconName: "HomeIcon", routePath: "./" },
  ];

  const topbarDropdownLinks: TopBarDropdownLinkProps[] = [
    // { title: t("topbar.profile"), routePath: "./" },
    { title: t("topbar.settings"), routePath: "./settings" },
  ];

  const handleLogout = () => {
    dispatch(logoutAuth());
    dispatch(clearAllContactsStates());
  };

  return (
    <div className="flex justify-between items-center w-full h-16 shadow bg-white z-10">
      <Link to="./">
        <img
          className="object-scale-down h-12 mx-4 cursor-pointer"
          src={logo}
          alt="crm-logo"
        />
      </Link>
      <div className="flex">
        {topbarLinks.map((link, i) => (
          <TopbarItem
            routeTo={link.routePath}
            key={i}
            title={link.title}
            icon={link.iconName}
          />
        ))}
      </div>
      <div className="relative" ref={dropdownRef}>
        <button
          onClick={() => setDropdownOpen(!isDropdownOpen)}
          className="flex items-center mx-4"
        >
          {/* <img
            src="/path-to-your-avatar-image.jpg"
            alt="Avatar"
            className="w-8 h-8 rounded-full"
          /> */}
          <FaUserCircle className="w-10 h-10 text-gray-600" />
        </button>
        {isDropdownOpen && (
          <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-50">
            {topbarDropdownLinks.map((link, i) => (
              <DropdownItem
                title={link.title}
                key={i}
                routeTo={link.routePath}
              />
            ))}
            <DropdownItem title={t("topbar.logout")} onClick={handleLogout} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Topbar;
